import Main from "../shared/main";
import Table from "../table/table";
import * as ReactTable from "react-table";
import { useCallback, useEffect, useMemo } from "react";
import { IRow } from "../table/render-row";
import usePageControl from "../../hooks/use-page.control";
import { TColumn } from "../../hooks/use-hide-columns";
import SearchWithCategories from "../search-with-categories/search-with-categories";
import { useController, useForm } from "react-hook-form";
import useSearchInTable, { ISearchForm } from "../../hooks/use-search-in-table";
import useSortBy from "../../hooks/use-sort-by";
import styled from "styled-components";
import TableFooter from "../table/table_footer";
import TableTitleBar from "../table/table-title-bar";
import {
  TestingManagerItemEntity,
  TestingManagerSearchType,
  useGetCountTestingManagerItemLazyQuery,
  useGetTestingManagerItemListLazyQuery
} from "../../__generated__/generated";

interface IProps {
  handleSelectedTesting: (
    payload?: ReactTable.Row<TestingManagerItemEntity>
  ) => void;
  setSelectedExamineName: React.Dispatch<React.SetStateAction<string[]>>;
}

const IColumns = {
  EXAMINE_NAME: "시험관리명",
  MNG_NUMBER: "관리번호",
  MANUFACTURER_CODE_NAME: "제조사코드",
  CTT_NAME: "CTT이름",
  RTU_IP: "RTU IP",
  personInChargeName: "관리자",
  personInChargeTel: "연락처",
  DESCR: "비고"
} as const;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 25;
`;

function ManageTestingList({
  handleSelectedTesting,
  setSelectedExamineName
}: IProps) {
  const {
    fieldSort,
    handleFieldSort,
    listOfColumnDisabled,
    handleListOfColumnDisabled
  } = useSortBy();

  const [getCountTestingManagerItem, { data: count }] =
    useGetCountTestingManagerItemLazyQuery({
      onError(error) {
        console.log(error);
      }
    });

  const total: number = useMemo(
    () => count?.getCountTestingManagerItem.count ?? 0,
    [count]
  );

  const [getTestingManagerItemList, { data }] =
    useGetTestingManagerItemListLazyQuery({
      onError(error) {
        console.log(error);
      }
    });

  const {
    searchTypeData,
    searchValueData,
    isSearch,
    handleSearchTypeData,
    handleSearchValueData,
    handleIsSearch
  } = useSearchInTable<TestingManagerSearchType>({
    defaultSearchType: TestingManagerSearchType.AsNo
  });

  const { control, getValues } = useForm<ISearchForm<TestingManagerSearchType>>(
    {
      defaultValues: {
        searchType: searchTypeData,
        searchValue: searchValueData
      }
    }
  );

  const { field: searchType } = useController({
    name: "searchType",
    control
  });

  const { field: searchValue } = useController({
    name: "searchValue",
    control
  });

  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();
  const columns: ReactTable.Column<TestingManagerItemEntity>[] = useMemo(() => {
    const smallWidth = 50;
    const width = 180;
    return [
      { Header: "번호", accessor: "asNo", width: smallWidth },
      { Header: "시험관리명", accessor: "examineName" },
      { Header: "관리번호", accessor: "mngNumber", width },
      { Header: "제조사코드", accessor: "manufacturerCodeName", width },
      { Header: "기물번호", accessor: "propertyNumber" },
      { Header: "모델 구분", accessor: "modelCodeName", width },
      { Header: "연결 CTT명", accessor: "cttName" },
      { Header: "RTU IP", accessor: "rtuIp" },
      { Header: "관리자", accessor: "personInChargeName" },
      { Header: "연락처", accessor: "personInChargeTel" },
      { Header: "비고", accessor: "descr" }
    ];
  }, []);

  const list = useMemo(() => {
    return data?.getTestingManagerItemList.list ?? [];
  }, [data]);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    columns: columnsOfTestingResult,
    toggleHideColumn,
    selectedFlatRows
  } = ReactTable.useTable<TestingManagerItemEntity>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  const handleSelectRow = useCallback(
    (row?: IRow<TestingManagerItemEntity>) => {},
    []
  );

  const selectedRow: ReactTable.Row<TestingManagerItemEntity> | undefined =
    useMemo(() => {
      return selectedFlatRows.at(-1) ?? undefined;
    }, [selectedFlatRows]);

  useEffect(() => {
    handleListOfColumnDisabled([
      "asNo",
      "cttName",
      "cttOnOff",
      "descr",
      "examineName",
      "examinePort",
      "manufacturerCodeName",
      "mngNumber",
      "modelCode",
      "modelCodeName",
      "personInChargeName",
      "personInChargeTel",
      "propertyNumber",
      "propertyVersion",
      "rtuConnectStatus",
      "rtuIp",
      "rtuMeasurementMinCycle"
    ]);
  }, [handleListOfColumnDisabled]);

  useEffect(() => {
    if (isSearch) {
      const { searchType, searchValue } = getValues();
      handleSearchTypeData(searchType);
      handleSearchValueData(searchValue);
      handleCurrentPage(1);
      handleIsSearch(false);
    }
  }, [
    isSearch,
    getValues,
    handleCurrentPage,
    handleSearchTypeData,
    handleSearchValueData,
    handleIsSearch
  ]);

  useEffect(() => {
    getCountTestingManagerItem({
      variables: {
        searchType: searchTypeData,
        searchValue: searchValueData
      }
    });
  }, [getCountTestingManagerItem, searchTypeData, searchValueData]);

  useEffect(() => {
    handleSelectedTesting(selectedRow);
  }, [selectedRow, handleSelectedTesting]);

  useEffect(() => {
    setSelectedExamineName(
      selectedFlatRows.map((item) => item.original.examineName)
    );
  }, [setSelectedExamineName, selectedFlatRows]);

  useEffect(() => {
    getTestingManagerItemList({
      variables: {
        searchType: searchTypeData,
        page: currentPage,
        take
      }
    });
  }, [getTestingManagerItemList, currentPage, take, searchTypeData]);

  return (
    <Main>
      <TableContainer>
        <TableTitleBar
          title="시험관리"
          total={total}
          columns={
            columnsOfTestingResult as TColumn<TestingManagerItemEntity>[]
          }
          toggleHideColumn={toggleHideColumn}
          take={take}
          handleTake={handleTake}
        >
          <SearchWithCategories
            list={Object.keys(IColumns).map((item) => ({
              name: IColumns[item as keyof typeof IColumns],
              value: item
            }))}
            searchType={searchType}
            searchValue={searchValue}
            handleSearch={handleIsSearch}
          />
        </TableTitleBar>
        <Table<TestingManagerItemEntity>
          title="시험관리"
          prepareRow={prepareRow}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          getTableBodyProps={getTableBodyProps}
          selectedRow={selectedRow}
          handleSelectRow={handleSelectRow}
          rows={rows}
          listOfFlexForHeader={["시험관리명", "관리번호", "제조사 모델명"]}
          isLastFlex={false}
          fieldSort={fieldSort}
          handleFieldSort={handleFieldSort}
          listOfColumnDisabled={listOfColumnDisabled}
        />
        <TableFooter
          totalPage={Math.ceil(total / take)}
          currentPage={currentPage}
          handleCurrentPage={handleCurrentPage}
          handleTake={handleTake}
        />
      </TableContainer>
    </Main>
  );
}

export default ManageTestingList;
