import styled from "styled-components";
import StyledButton from "../shared/styled-button";

export interface IButton {
  name: string;
  handleClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  disabled?: boolean;
}

export interface ITableButtons {
  listOfButton: IButton[];
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
  justify-content: flex-end;
`;

function TableButtons({ listOfButton }: ITableButtons) {
  return (
    <Container>
      {listOfButton.map((item, index) => (
        <StyledButton
          minWidth="70px"
          maxWidth="70px"
          backgroundColor={item.backgroundColor}
          hoverBackgroundColor={item.hoverBackgroundColor}
          onClick={item.handleClick}
          key={`${item.name}-${index}`}
          disabled={item.disabled}
        >
          {item.name}
        </StyledButton>
      ))}
    </Container>
  );
}

export default TableButtons;
