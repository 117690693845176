import styled from "styled-components";

interface IProps {
  $minWidth?: string;
  $maxWidth?: string;
}

const LimitedBox = styled.div<IProps>`
  display: flex;
  min-width: ${(props) => props.$minWidth ?? ""};
  max-width: ${(props) => props.$maxWidth ?? ""};
  justify-content: space-between;
`;

export default LimitedBox;
