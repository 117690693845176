import { useForm } from "react-hook-form";
import styled from "styled-components";
import PageTitle from "../components/shared/page-title";
import StyledButton from "../components/shared/styled-button";
import StyledInput from "../components/shared/styled-input";
import useCreateTitle from "../hooks/use-create-title";
// import { Navigate } from "react-router-dom";
import { useLoginLazyQuery } from "../__generated__/generated";
import { makeToken } from "../utils/jwt";
import ToastMessageContext from "../components/common/toast-message/context";
import { useContext } from "react";
import { MessageTypes } from "../components/common/toast-message/toast-message";
import { logUserIn } from "../apollo/apollo";
import routes from "../routes/routes";
import { useNavigate } from "react-router-dom";

interface IUser {
  id: string;
  password: string;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

const LoginContainer = styled.form`
  display: flex;
  border: 1px solid ${props => props.theme.colors.frameGrey};
  justify-content: center;
  padding: 40px;
  border-radius: 4px;
  flex-direction: column;
  gap: 10px;
`;

const LoginTitleBar = styled.div`
  display: flex;
`;

const TitleBar = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const InputContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
`;

function Login() {
  const { title } = useCreateTitle();
  const toastMessageContext = useContext(ToastMessageContext);

  //   const isLoggedInVar = useReactiveVar(listOfApolloVar.isLoggedInVar);
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm<IUser>();
  const [tryToLogin, { loading }] = useLoginLazyQuery({
    onError(error) {
      console.log(error);
      // 에러 처리 로직 추가
      toastMessageContext!.handleToast(
        "로그인에 실패하였습니다.",
        MessageTypes.ERROR
      );
    },
    onCompleted(data) {
      // 로그인 성공 처리 로직 추가
      if (data.login.ok && data.login.token) {
        logUserIn(data.login.token);
        navigate(`/${routes.performTesting}`);
      } else {
        toastMessageContext!.handleToast(
          "로그인에 실패하였습니다.",
          MessageTypes.ERROR
        );
      }
    }
  });

  const onSubmit = handleSubmit(formData => {
    const payload = {
      id: formData.id,
      password: formData.password
    };

    const token = makeToken(payload);
    tryToLogin({
      variables: {
        token
      }
    });
  });

  return (
    <Container>
      <PageTitle title={title} />
      <LoginTitleBar>
        <h1>{`적산열량계 상호운용성 시험`}</h1>
      </LoginTitleBar>
      <LoginContainer onSubmit={onSubmit}>
        <TitleBar>
          <h3>로그인</h3>
        </TitleBar>
        <InputContainer>
          <StyledInput
            placeholder="사용자 아이디"
            {...register("id", { required: true })}
            onInvalid={event => {
              event.currentTarget.setCustomValidity(
                "사용자 아이디를 입력해주세요"
              );
            }}
            onInput={event => event.currentTarget.setCustomValidity("")}
            autoComplete="username"
            required
          />
          <StyledInput
            placeholder="패스워드"
            type="password"
            {...register("password", { required: true })}
            autoComplete="new-password"
            onInvalid={event => {
              event.currentTarget.setCustomValidity(
                "사용자 패스워드를 입력해주세요"
              );
            }}
            onInput={event => event.currentTarget.setCustomValidity("")}
            required
          />
        </InputContainer>
        <ButtonContainer>
          <StyledButton type="submit" disabled={loading}>
            {loading ? "로그인 중..." : "로그인"}
          </StyledButton>
        </ButtonContainer>
      </LoginContainer>
    </Container>
  );
}

export default Login;
