import PageTitle from "../components/shared/page-title";
import Tabs, { ITab } from "../components/tabs/tabs";
import useCreateTitle from "../hooks/use-create-title";
import { useMemo } from "react";
import useTabs from "../hooks/use-tabs";
import RealTimeTesting from "../components/real-time-testing/real-time-testing";
import TestingResult from "../components/testing-result/testing-result";
import PageContainer from "../components/shared/page-container";

function PerformTesting() {
  const { title } = useCreateTitle();
  const tabs: ITab[] = useMemo(
    () => [{ title: "실시간 설정" }, { title: "시험결과" }],
    []
  );
  const { selectedTab, handleTabSelected } = useTabs(tabs[0].title);

  return (
    <PageContainer>
      <PageTitle title={title} />
      <Tabs
        list={tabs}
        selectedTab={selectedTab}
        handleTabSelected={handleTabSelected}
      />
      {selectedTab === tabs[0].title && <RealTimeTesting />}
      {selectedTab === tabs[1].title && <TestingResult />}
    </PageContainer>
  );
}

export default PerformTesting;
