import { useCallback, useState } from "react";
import styled from "styled-components";
import Button from "../components/shared/button";
import ButtonContainer from "../components/shared/button-container";
import Details from "../components/shared/details";
import PageContainer from "../components/shared/page-container";
import PageTitle from "../components/shared/page-title";
import TextField from "../components/shared/text-field";
import useCreateTitle from "../hooks/use-create-title";
import basicProfile from "../images/basic-profile.jpg";
import FileReaderInput from "react-file-reader-input";

const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 10px;
  flex-direction: column;
  gap: 20px;
  button {
    padding: 5px;
  }
`;

const TextFieldAlignDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const PictureContainer = styled.div`
  width: 65px;
  height: 65px;
`;

const SelectContainer = styled.div`
  display: flex;
  flex: 1;
`;

const ProfilePicture = styled.div<{ photo?: string; uploadImg?: string }>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-size: cover;
  background-image: ${(props) =>
    props.photo
      ? `url( data:image/png+jpg;base64,${props.photo})`
      : `url(${props.uploadImg || basicProfile})`};
`;

const FileNameDisplayDiv = styled.div`
  display: flex;
  margin: 8px;
  padding-left: 8px;
  width: 280px;
  height: 32px;
  align-items: center;
  border: 2px dashed #f69c55;
`;

const ControllersDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

function Settings() {
  const { title } = useCreateTitle();
  // const [currentPassword, setCurrentPassword] = useState("");
  // const [newPassword, setNewPassword] = useState("");
  // const [reEnteredNewPassword, setReEnteredNewPassword] = useState("");

  const [selectedFile, setSelectedFile] = useState<any>(undefined);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | undefined>(
    undefined
  );
  // const [photo, setPhoto] = useState("");

  const fileChangedHandler = useCallback((e: any, results: any) => {
    const maxSize = 1024 * 512;
    let userImage = results[0][1];

    if (userImage.size <= maxSize) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setSelectedFile(userImage);
        setImagePreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(userImage);
    }
  }, []);

  const uploadUserPic = useCallback(() => {}, []);

  const handleChangePassword = useCallback(
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (name === "currentPassword") {
        // setCurrentPassword(event.target.value);
      } else if (name === "newPassword") {
        // setNewPassword(event.target.value);
      } else if (name === "reEnteredNewPassword") {
        // setReEnteredNewPassword(event.target.value);
      }
    },
    []
  );

  return (
    <PageContainer>
      <PageTitle title={title} />
      <Container>
        <Details title="현재 패스워드를 새로운 패스워드로 변경합니다">
          <TextFieldAlignDiv>
            <TextField
              label="현재 패스워드"
              placeholder="현재 패스워드를 입력하세요"
              valueChange={handleChangePassword("currentPassword")}
              value={""}
              type="password"
            />
            <TextField
              type="password"
              label="새로운 패스워드"
              placeholder="변경할 패스워드를 입력하세요"
              valueChange={handleChangePassword("newPassword")}
              value={""}
            />
            <TextField
              type="password"
              label="새로운 패스워드 확인"
              placeholder="변경할 패스워드를 한번더 입력하세요"
              valueChange={handleChangePassword("reEnteredNewPassword")}
              value={""}
            />
            <ButtonContainer>
              <Button maxWidth="50px">{`확인`}</Button>
            </ButtonContainer>
          </TextFieldAlignDiv>
        </Details>
        <Details title="사용자 사진을 변경합니다">
          <Container>
            <SelectContainer>
              <PictureContainer>
                <ProfilePicture
                  uploadImg={imagePreviewUrl}
                  photo={imagePreviewUrl ?? ""}
                  // photo={imagePreviewUrl ? "" : photo}
                />
              </PictureContainer>
              <FileNameDisplayDiv>
                {selectedFile
                  ? selectedFile.name
                  : "최대 파일 크기는 500KB를 초과할 수 없습니다."}
              </FileNameDisplayDiv>
            </SelectContainer>
            <ControllersDiv>
              <ButtonContainer>
                <FileReaderInput
                  as="binary"
                  onChange={fileChangedHandler}
                  accept=".png,.jpg"
                >
                  <Button>{"파일 선택"}</Button>
                </FileReaderInput>
                <Button onClick={uploadUserPic} maxWidth="50px">
                  {"저장"}
                </Button>
              </ButtonContainer>
            </ControllersDiv>
          </Container>
        </Details>
      </Container>
    </PageContainer>
  );
}

export default Settings;
