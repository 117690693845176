import styled from "styled-components";
import useCreateTitle from "../../hooks/use-create-title";
import { Icon } from "@iconify/react";
import LogoutIcon from "@iconify/icons-material-symbols/logout";
import { useCallback } from "react";
import { logUserOut } from "../../apollo/apollo";

const Container = styled.div`
  display: flex;
  flex: 1;
  border: 1px solid ${props => props.theme.colors.frameGrey};
  align-items: center;
  min-height: 41px;
  max-height: 41px;
  padding: 10px;
  justify-content: space-between;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
`;

const Title = styled.span`
  font-weight: bold;
  color: ${props => props.theme.colors.darkGrey};
`;

const Logout = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  :hover {
    color: ${props => props.theme.colors.green};
  }
  cursor: pointer;
  :active {
    transform: scale(0.98);
  }
`;

function Header() {
  const { title } = useCreateTitle();
  const handleLogout = useCallback(() => {
    logUserOut();
  }, []);

  return (
    <Container>
      <Title>{title}</Title>
      <Logout onClick={handleLogout}>
        <Icon icon={LogoutIcon} />
        <span>로그아웃</span>
      </Logout>
    </Container>
  );
}

export default Header;
