import styled from "styled-components";

const TableRow = styled.div`
  cursor: pointer;
  border-bottom: 1px solid ${(props) => props.theme.colors.littleLightGrey};
  :hover {
    background-color: ${(props) => props.theme.colors.littleLightGrey};
    color: ${(props) => props.theme.colors.black};
  }
  overflow-x: hidden;
`;

export default TableRow;
