const helmetContext = {
  login: "로그인",
  settings: "환경설정",
  mangeTesting: "시험관리",
  performTesting: "시험수행",
  detailResult: "시험장치",
  addManuFacturer: "제조사 추가",
  addInstallationModelName: "설치 모델명 추가",
  searchManufacturer: "제조사 검색",
  searchModel: "설치 모델명 검색",
  cttList: "CTT 목록",
  saveTestingPreset: "검사 프리셋 저장",
  loadTestingPreset: "검사 프리셋 불러오기"
};

export default helmetContext;
