import { AutoSizer as _AutoSizer, AutoSizerProps } from "react-virtualized";
import { FixedSizeList as List } from "react-window";
import ReactLoading from "react-loading";
import styled from "styled-components";
import { colors } from "../../styles";
import TableColumn, { TMoveColumn } from "./table-column";
import TableContainer from "./table-container";
import TableHead from "./table-head";
import TableHeaderRow from "./table-header-row";
import * as ReactTable from "react-table";
import RenderRow, { IRenderRowProps, IRow } from "./render-row";
import { FC } from "react";

import { FieldSort } from "../../hooks/use-sort-by";
import TableBody from "./table-body";

interface IHeaderGroup<P extends {}>
  extends ReactTable.HeaderGroup<P extends object ? P : {}> {}

export interface IHeaderGroups<P extends {}> extends IHeaderGroup<P> {}

interface IProps<P extends object> extends IRenderRowProps<P> {
  getTableProps: (
    propGetter?:
      | Partial<ReactTable.TableProps>
      | Partial<ReactTable.TableProps>[]
      | ((
          props: Partial<ReactTable.TableProps>,
          meta: ReactTable.MetaBase<any>
        ) => Partial<any> | Partial<any>[])
      | undefined
  ) => ReactTable.TableProps;
  getTableBodyProps: () => ReactTable.TableBodyProps;
  rows: IRow<P>[];
  headerGroups: IHeaderGroups<P>[];
  fieldSort?: FieldSort;
  handleFieldSort?: (payload?: FieldSort) => void;
  listOfColumnDisabled?: string[];
  moveColumn?: TMoveColumn;
  isTitleBar?: boolean;
  isLoading?: boolean;
  itemSize?: number;
  isLastFlex?: boolean;
  title: string;
}

const AutoSizer = _AutoSizer as unknown as FC<AutoSizerProps>;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  flex-grow: 15;
`;

const Content = styled.div<{ isLoading?: boolean }>`
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  justify-content: ${(props) => (props.isLoading ? "center" : "flex-start")};
  align-items: ${(props) => (props.isLoading ? "center" : "normal")};
  flex-direction: column;
`;

function Table<P extends object>({
  headerGroups,
  fieldSort,
  handleFieldSort,
  listOfColumnDisabled,
  moveColumn,
  getTableBodyProps,
  rows,
  isLoading,
  prepareRow,
  visibleColumns,
  handleSelectRow,
  handleContextMenu,
  getTableProps,
  selectedRow,
  isLastFlex,
  flexIndex,
  listOfFlexForHeader,
  title,
  isSameRowSelect,
  itemSize = 30
}: IProps<P>) {
  return (
    <Container>
      <Content isLoading={isLoading}>
        {isLoading ? (
          <ReactLoading
            type="spokes"
            color={colors.green}
            height={200}
            width={170}
          />
        ) : (
          <TableContainer {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => {
                    let isFlex = flexIndex === index;
                    if (listOfFlexForHeader && column?.Header) {
                      isFlex = listOfFlexForHeader.includes(
                        column.Header as string
                      );
                    }
                    return (
                      <TableColumn
                        key={column.id}
                        column={column}
                        index={index}
                        fieldSort={fieldSort}
                        handleFieldSort={handleFieldSort}
                        listOfColumnDisabled={listOfColumnDisabled}
                        moveColumn={moveColumn}
                        title={title}
                        isFlex={isFlex}
                        isLastFlex={isLastFlex}
                      />
                    );
                  })}
                </TableHeaderRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              <AutoSizer>
                {({ height, width }) => {
                  return (
                    <List
                      height={height}
                      itemCount={rows.length}
                      itemData={rows}
                      itemSize={itemSize}
                      width={width}
                    >
                      {RenderRow({
                        prepareRow: prepareRow,
                        handleSelectRow: handleSelectRow,
                        handleContextMenu: handleContextMenu,
                        selectedRow: selectedRow,
                        isLastFlex: isLastFlex,
                        listOfFlexForHeader,
                        isSameRowSelect,
                        visibleColumns
                      })}
                    </List>
                  );
                }}
              </AutoSizer>
            </TableBody>
          </TableContainer>
        )}
      </Content>
    </Container>
  );
}

export default Table;
