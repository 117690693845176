import { redirect, LoaderFunction } from "react-router-dom";
import routes from "./routes";
import { TOKEN } from "../utils/list_of_apollo_var";
// import routes from "../routes/routes";

const loader: LoaderFunction = async ({ request, params }) => {
  const token = localStorage.getItem(TOKEN);
  const { pathname } = new URL(request.url);
  console.log(pathname);
  console.log(token);
  if (token) {
    if (pathname !== `/${routes.performTesting}`) {
      console.log("redirect to perform");
      return redirect(`${routes.performTesting}`);
    }
    return null;
  }
  if (pathname !== `/${routes.login}`) {
    console.log("redirect to login");
    return redirect(`${routes.login}`);
  }
  return null;
};

export default loader;
