import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import helmetContext from "../helmet/helmet.context";
import routes from "../routes/routes";

function useCreateTitle() {
  const location = useLocation();
  const title = useMemo(() => {
    let title = "";
    const routesKeys = Object.keys(routes);
    const routesValues = Object.values(routes);
    routesValues.forEach((item, index) => {
      if (location.pathname.split("/").at(-1) === item.split("/").at(-1)) {
        const key = routesKeys[index];
        title = helmetContext[key as keyof typeof helmetContext];
      }
    });
    return title;
  }, [location]);
  return { title };
}

export default useCreateTitle;
