import Main from "../shared/main";
import ButtonContainer from "../shared/button-container";
import Button from "../shared/button";
import Dialog from "../dialog/dialog";
import { createContext, useCallback, useRef } from "react";
import RequestTesting from "./request-testing/request-testing";
import CheckStats from "./check-status/check-status";
import DeviceInfo from "./device_info/device_info";
import TestingInfo from "./testing_info/testing_info";
import TestingExtraFrom from "./testing_extra_form/testing_extra_form";
import RtuTextExtraForm from "./rtu_testing_extra_form/rtu_testing_extra_form";
import { useRecoilValue } from "recoil";
import {
  Examine_Method,
  Examine_Target,
  TestRequestTransmissionMutationVariables,
  useTestRequestTransmissionMutation
} from "../../__generated__/generated";
import { testDeviceAtoms } from "../../recoil/test_device_atoms/test_device_atoms";
import SToastContainer from "../shared/s_toast_container";
import { toast } from "react-toastify";
import { freeSetForSaveAtoms } from "../../recoil/free_set_for_save_atoms/free_set_for_save_atoms";

interface IRealTimeTestingStore {}

export const RealTimeTestingStore = createContext<IRealTimeTestingStore>(
  {} as IRealTimeTestingStore
);

function RealTimeTesting() {
  const dialogEl = useRef<HTMLDialogElement | undefined>();
  const dialogOnlineEl = useRef<HTMLDialogElement | undefined>();
  const freeSetStateForSave = useRecoilValue(freeSetForSaveAtoms);
  const testDeviceState = useRecoilValue(testDeviceAtoms);

  const [testRequestTransmission] = useTestRequestTransmissionMutation({
    fetchPolicy: "network-only",
    onError(error) {
      console.log(error);
      toast("시험 요청 전송을 할 수 없습니다.", {
        type: toast.TYPE.WARNING,
        theme: "colored"
      });
    },
    update(_, { data }) {
      if (data?.testRequestTransmission.ok) {
        dialogEl?.current?.showModal();
      } else if (data?.testRequestTransmission.error) {
        toast(data.testRequestTransmission.error, {
          type: toast.TYPE.WARNING,
          theme: "colored"
        });
      }
    }
  });

  const handleTestRequest = useCallback(() => {
    if (!testDeviceState.isOnOff) {
      toast("연결 CTT가 오프라인 상태이므로 시험을 시작할 수 없습니다.", {
        type: toast.TYPE.WARNING,
        theme: "colored"
      });
    }
    if (
      freeSetStateForSave?.freeSetItemName &&
      testDeviceState.examineName &&
      testDeviceState.isOnOff
    ) {
      let examineCount =
        freeSetStateForSave.testingType === Examine_Method.DurabilityTest
          ? freeSetStateForSave.examineCount
          : 1;
      if (freeSetStateForSave.testingType === Examine_Method.EnvironmentTest) {
        examineCount = 0;
      }
      const payload: TestRequestTransmissionMutationVariables = {
        examineName: testDeviceState.examineName,
        examineFreeSetName: freeSetStateForSave.freeSetItemName,
        examineTarget: freeSetStateForSave.testingTargetType,
        examineMethod: freeSetStateForSave.testingType,
        examineCount,
        endpointId: testDeviceState.endpointId,
        rtuMeasurementMinCycle: testDeviceState.rtuMeasurementMinCycle
      };
      testRequestTransmission({ variables: payload });
    }
  }, [testRequestTransmission, freeSetStateForSave, testDeviceState]);

  return (
    <RealTimeTestingStore.Provider value={{}}>
      <Main>
        <DeviceInfo />
        <TestingInfo />
        {freeSetStateForSave.testingTargetType ===
          Examine_Target.Calorimeter && <TestingExtraFrom />}
        {freeSetStateForSave.testingTargetType === Examine_Target.Rtu && (
          <RtuTextExtraForm />
        )}
        <ButtonContainer>
          <Button
            maxHeight="25px"
            maxWidth="110px"
            onClick={handleTestRequest}
          >{`시험 요청 전송`}</Button>
        </ButtonContainer>
        <Dialog ref={dialogEl} title="시험요청">
          <RequestTesting />
          <ButtonContainer>
            <Button
              maxWidth="50px"
              onClick={() => {
                dialogEl.current?.close();
              }}
            >{`닫기`}</Button>
          </ButtonContainer>
        </Dialog>
        <Dialog ref={dialogOnlineEl} title="상태확인">
          <CheckStats />
          <ButtonContainer>
            <Button
              maxWidth="50px"
              onClick={() => {
                dialogOnlineEl.current?.close();
              }}
            >{`닫기`}</Button>
          </ButtonContainer>
        </Dialog>
      </Main>
      <SToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={1}
      />
    </RealTimeTestingStore.Provider>
  );
}

export default RealTimeTesting;
