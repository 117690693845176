/* eslint-disable @typescript-eslint/no-redeclare */
import moment from "moment";
import { atom } from "recoil";
import { Examine_Method, Examine_Target } from "../../__generated__/generated";

export const DATA_METHOD_TYPE = {
  IS_FIXED_VALUE: "IS_FIXED_VALUE",
  ADD_VALUE_BY_TIMES: "ADD_VALUE_BY_TIMES",
  EXTERNAL_INPUT: "EXTERNAL_INPUT",
  SIMULATION_MODE: "SIMULATION_MODE",
  UN_USED: "UN_USED"
} as const;

export type DATA_METHOD_TYPE =
  (typeof DATA_METHOD_TYPE)[keyof typeof DATA_METHOD_TYPE];

export const ACTION_ON_ERROR_DETECTION_TYPE = {
  STOP: 0,
  CONTINUE: 1
} as const;

export type ACTION_ON_ERROR_DETECTION_TYPE =
  (typeof ACTION_ON_ERROR_DETECTION_TYPE)[keyof typeof ACTION_ON_ERROR_DETECTION_TYPE];

// 데이터 생성방식 타입
export const DATA_GENERATOR_METHOD = {
  AUTO_GENERATION: 0,
  INSPECTOR_ASSIGNMENT: 1
} as const;

export type DATA_GENERATOR_METHOD =
  (typeof DATA_GENERATOR_METHOD)[keyof typeof DATA_GENERATOR_METHOD];

// 프리셋 설정 값 타입
export interface FREE_SET_MESSAGE_DATA {
  freeSetItemName?: string; // 프리셋명
  testingTargetType: Examine_Target; // 시험대상 타입
  testingType: Examine_Method; // 시험방식 타입
  timeOutSetting: number; // 타임아웃 설정
  timeOutBuffer: number; // 타임아웃 버퍼
  startDateOfInquiry: string; // 조회 시작 날짜
  startHourOfInquiry: string; // 조회 시작 일시 시간
  startMinOfInquiry: string; // 조회 시작 일시 분
  calorieValueGenerationMethod: DATA_METHOD_TYPE; // 열량값 생성방식
  calorificValueSetting: string; // 열량값 설정
  calorificValueIncrement: string; // 열량값 증가값
  temperatureValueGenerationMethod: DATA_METHOD_TYPE; // 온도값 생성방식
  temperatureSetting: string; // 온도값 설정
  temperatureIncrement: string; // 온도값 증가값
  examineCount: number; // 시험회차
  actionOnErrorDetection: ACTION_ON_ERROR_DETECTION_TYPE; // 에러 검출시 동작
  dataGeneratorMethodInCalorimeter: DATA_GENERATOR_METHOD; // 열량계 시뮬레이터 데이터 생성방식
  cumulativeHeat: number; // 누적열량
  cumulativeFlowRate: number; // 누적유량
  instantaneousHeat: number; // 순간열량
  instantaneousFlowRate: number; // 순간유량
  outletTemperature: number; // 송류온도
  returnTemperature: number; // 환류온도
  outletPressure: number; // 송류압력
  returnPressure: number; // 환류압력
  dataGeneratorMethodInSensorSimulation: DATA_GENERATOR_METHOD; // 센서 시뮬레이터 데이터 생성방식
  secondarySensorQuantity: number; // 2차센서 수량
  secondaryOutletTemperature: number; // 2차측 송류온도
  minSecondaryOutletTemperature: number; // 최소 2차측 송류온도
  maxSecondaryOutletTemperature: number; // 최대 2차측 송류온도
  secondaryReturnTemperature: number; // 2차 환류 온도
  minSecondaryReturnTemperature: number; // 최소 2차 환류 온도
  maxSecondaryReturnTemperature: number; // 최대 2차 환류 온도
  secondaryOutletPressure: number; // 2차측 송류 압력
  minSecondaryOutletPressure: number; // 최소 2차측 송류 압력
  maxSecondaryOutletPressure: number; // 최대 2차측 송류 압력
  secondaryReturnPressure: number; // 2차측 환류 압력
  minSecondaryReturnPressure: number; // 최소 2차측 환류 압력
  maxSecondaryReturnPressure: number; // 최대 2차측 환류 압력
  userFacilityTemperature: number; // 사용자시설 온도
  minUserFacilityTemperature: number; // 최소 사용자시설 온도
  maxUserFacilityTemperature: number; // 최대 사용자시설 온도
  userFacilityHumidity: number; // 사용자시설 습도
  minUserFacilityHumidity: number; // 최소 사용자시설 습도
  maxUserFacilityHumidity: number; // 최대 사용자시설 습도
  minCumulativeHeat: number; // 최소 누적열량
  maxCumulativeHeat: number; // 최대 누적열량
  minInstantaneousHeat: number; // 최소 순간열량
  maxInstantaneousHeat: number; // 최소 순간열량
  minCumulativeFlowRate: number; // 최소 누적유량
  maxCumulativeFlowRate: number; // 최대 누적유량
  minInstantaneousFlowRate: number; // 최소 순간유량
  maxInstantaneousFlowRate: number; // 최대 순간유량
  minOutletTemperature: number; // 최소 송류온도
  maxOutletTemperature: number; // 최대 송류온도
  minReturnTemperature: number; // 최소 환류온도
  maxReturnTemperature: number; // 최대 환류온도
  minOutletPressure: number; // 최소 송류압력
  maxOutletPressure: number; // 최대 송류압력
  minReturnPressure: number; // 최소 환류압력
  maxReturnPressure: number; // 최대 환류압력
}

export const freeSetAtoms = atom<FREE_SET_MESSAGE_DATA>({
  key: "freeSetState", // unique ID (with respect to other atoms/selectors)
  default: {
    testingTargetType: Examine_Target.Calorimeter,
    testingType: Examine_Method.EnvironmentTest,
    timeOutSetting: 0,
    timeOutBuffer: 0,
    startDateOfInquiry: moment().format("YYYY-MM-DD"),
    startHourOfInquiry: "00",
    startMinOfInquiry: "00",
    calorieValueGenerationMethod: DATA_METHOD_TYPE.EXTERNAL_INPUT,
    temperatureValueGenerationMethod: DATA_METHOD_TYPE.EXTERNAL_INPUT,
    calorificValueSetting: "",
    calorificValueIncrement: "",
    temperatureSetting: "",
    temperatureIncrement: "",
    examineCount: 1,
    actionOnErrorDetection: ACTION_ON_ERROR_DETECTION_TYPE.STOP,
    dataGeneratorMethodInCalorimeter: DATA_GENERATOR_METHOD.AUTO_GENERATION,
    cumulativeHeat: 0,
    cumulativeFlowRate: 0,
    instantaneousHeat: 0,
    instantaneousFlowRate: 0,
    outletTemperature: 0,
    returnTemperature: 0,
    outletPressure: 0,
    returnPressure: 0,
    dataGeneratorMethodInSensorSimulation:
      DATA_GENERATOR_METHOD.AUTO_GENERATION,
    secondarySensorQuantity: 1,
    secondaryOutletTemperature: 0,
    minSecondaryOutletTemperature: 0,
    maxSecondaryOutletTemperature: 0,
    secondaryReturnTemperature: 0,
    minSecondaryReturnTemperature: 0,
    maxSecondaryReturnTemperature: 0,
    secondaryOutletPressure: 0,
    minSecondaryOutletPressure: 0,
    maxSecondaryOutletPressure: 0,
    secondaryReturnPressure: 0,
    minSecondaryReturnPressure: 0,
    maxSecondaryReturnPressure: 0,
    userFacilityTemperature: 0,
    minUserFacilityTemperature: 0,
    maxUserFacilityTemperature: 0,
    userFacilityHumidity: 0,
    minUserFacilityHumidity: 0,
    maxUserFacilityHumidity: 0,
    minCumulativeHeat: 0,
    maxCumulativeHeat: 0,
    minInstantaneousHeat: 0,
    maxInstantaneousHeat: 0,
    minCumulativeFlowRate: 0,
    maxCumulativeFlowRate: 0,
    minInstantaneousFlowRate: 0,
    maxInstantaneousFlowRate: 0,
    minOutletTemperature: 0,
    maxOutletTemperature: 0,
    minReturnTemperature: 0,
    maxReturnTemperature: 0,
    minOutletPressure: 0,
    maxOutletPressure: 0,
    minReturnPressure: 0,
    maxReturnPressure: 0
  } // default value (aka initial value)
});
