import styled from "styled-components";
import TableData from "./table-data";

const TableHeaderRow = styled.div`
  display: flex !important;
  flex: 1;
  width: auto !important;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
  :hover {
    ${TableData} {
      color: ${(props) => props.theme.colors.black};
    }
  }
`;

export default TableHeaderRow;
