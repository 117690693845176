import styled from "styled-components";

const ReloadButton = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.colors.frameGrey};
  border-radius: 7px;
  padding: 2px;
  font-size: 20px;
  border: 1px solid ${(props) => props.theme.colors.darkBlue};
  color: ${(props) => props.theme.colors.darkBlue};
  cursor: pointer;
  :hover {
    border-color: ${(props) => props.theme.colors.skyBlue};
    background-color: ${(props) => props.theme.colors.white};
    svg {
      color: ${(props) => props.theme.colors.skyBlue};
    }
  }
  :active {
    transform: scale(0.98);
  }
  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  margin-left: 10px;
`;

export default ReloadButton;
