import { Outlet, createBrowserRouter } from "react-router-dom";
import App from "./App";
import AddInstallationModelName from "./components/add_installation_model_name/add_installation_model_name";
import AddManuFacturer from "./components/add_manufacturer/add_manufacturer";
import CttList from "./components/ctt_list/ctt_list";
import SearchManuFacturer from "./components/search_manufacturer/search_manufacturer";
import SearchModel from "./components/search_model/search_model";
import DetailResult from "./components/testing-result/detail_result/detail_result";
import loader from "./routes/loader";
import routes from "./routes/routes";
import Login from "./screens/login";
import ManageTesting from "./screens/manage-testing";
import PerformTesting from "./screens/perform-testing";
import Settings from "./screens/settings";
import LoadTestingPreset from "./components/real-time-testing/load_testing_preset_dialog/load_testing_preset_dialog";
import SaveTestingPresetDialog from "./components/real-time-testing/save_testing_preset_dialog/save_testing_preset_dialog";
import useOpenToastMessage from "./components/common/toast-message/hook";
import ToastMessageContext from "./components/common/toast-message/context";
import ToastMessage from "./components/common/toast-message/toast-message";

const Root = () => {
  const toastMessageState = useOpenToastMessage();

  return (
    <ToastMessageContext.Provider value={toastMessageState}>
      <Outlet />
      <ToastMessage
        message={toastMessageState.message}
        isOpen={toastMessageState.isOpen}
        handleIsOpen={toastMessageState.handleIsOpen}
        messagetypes={toastMessageState.toastMessageType}
      />
    </ToastMessageContext.Provider>
  );
};

const Router = createBrowserRouter([
  {
    element: <Root />,
    children: [
      {
        path: routes.home,
        element: <App />,
        loader,
        children: [
          {
            path: routes.performTesting,
            element: <PerformTesting />
          },
          {
            path: routes.mangeTesting,
            element: <ManageTesting />
          },
          {
            path: routes.settings,
            element: <Settings />
          }
        ]
      },
      { path: routes.login, element: <Login /> },
      {
        path: `${routes.performTesting}/${routes.detailResult}/:id`,
        element: <DetailResult />
      },
      {
        path: `${routes.mangeTesting}/${routes.addManuFacturer}`,
        element: <AddManuFacturer />
      },
      {
        path: `${routes.mangeTesting}/${routes.addInstallationModelName}`,
        element: <AddInstallationModelName />
      },
      {
        path: `${routes.mangeTesting}/${routes.searchManufacturer}`,
        element: <SearchManuFacturer />
      },
      {
        path: `${routes.mangeTesting}/${routes.searchModel}`,
        element: <SearchModel />
      },
      {
        path: `${routes.mangeTesting}/${routes.cttList}`,
        element: <CttList />
      },
      {
        path: `${routes.mangeTesting}/${routes.saveTestingPreset}`,
        element: <SaveTestingPresetDialog />
      },
      {
        path: `${routes.mangeTesting}/${routes.loadTestingPreset}`,
        element: <LoadTestingPreset />
      }
    ]
  }
]);

export default Router;
