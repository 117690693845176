const HOME = "/",
  LOGIN = "/login",
  PERFORM_TESTING = "perform-testing",
  MANAGE_TESTING = "manage-testing",
  SETTINGS = "settings",
  DETAIL_RESULT = "detail-result",
  ADD_MANUFACTURER = "add-manufacturer",
  ADD_INSTALLATION_MODEL_NAME = "add-installation-model-name",
  SEARCH_MANUFACTURE = "search-manufacturer",
  SEARCH_MODEL = "search-model",
  CTT_LIST = "ctt-list",
  SAVE_TESTING_PRESET = "save-testing-preset",
  LOAD_TESTING_PRESET = "load-testing-preset";

const routes = {
  home: HOME,
  login: LOGIN,
  performTesting: PERFORM_TESTING,
  mangeTesting: MANAGE_TESTING,
  settings: SETTINGS,
  detailResult: DETAIL_RESULT,
  addManuFacturer: ADD_MANUFACTURER,
  addInstallationModelName: ADD_INSTALLATION_MODEL_NAME,
  searchManufacturer: SEARCH_MANUFACTURE,
  searchModel: SEARCH_MODEL,
  cttList: CTT_LIST,
  saveTestingPreset: SAVE_TESTING_PRESET,
  loadTestingPreset: LOAD_TESTING_PRESET
};

export default routes;
