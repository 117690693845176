import styled from "styled-components";
import Subject from "../shared/subject";
import Button from "../shared/button";
import ButtonContainer from "../shared/button-container";
import { colors } from "../../styles";
import EditorContainer from "../shared/editor-container";
import Editor from "../editor/editor";
import SSpan from "../shared/s-span";
import * as ReactTable from "react-table";
import { useController, useForm } from "react-hook-form";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import Input from "../shared/input";
import Dialog from "../dialog/dialog";
import DeleteTestingResult from "../testing-result/delete-testing-result/delete-testing-result";
import ContentContainer from "../shared/content_container";
import {
  TestingManagerItemEntity,
  useAddOrEditTestingManagerItemMutation,
  useDeleteTestingManagerItemMutation
} from "../../__generated__/generated";
import { toast } from "react-toastify";
import SToastContainer from "../shared/s_toast_container";
import { Icon } from "@iconify/react";
import SearchIcon from "@iconify/icons-mdi/magnify";
import routes from "../../routes/routes";
import { CTT_LIST_CHANNEL, OPEN_CTT_CHANNEL } from "../ctt_list/ctt_list";

interface IProps {
  selectedTesting: ReactTable.Row<TestingManagerItemEntity> | undefined;
  selectedExamineName: string[];
}

const EDITOR_MODE = {
  DEFAULT: "DEFAULT",
  ADD: "ADD",
  EDIT: "EDIT"
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
type EDITOR_MODE = (typeof EDITOR_MODE)[keyof typeof EDITOR_MODE];

const Container = styled.div`
  display: flex;
  flex: 7;
  padding: 10px;
  border-top: 1px solid ${(props) => props.theme.colors.frameGrey};
  flex-direction: column;
  gap: 10px;
`;

const SearchContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const IconContainer = styled.div`
  font-size: 25px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.green};
  :hover {
    color: ${(props) => props.theme.colors.lightGreen};
  }
  cursor: pointer;
  :active {
    transform: scale(0.98);
  }
`;

function FacilityEditor({ selectedTesting, selectedExamineName }: IProps) {
  const dialogEl = useRef<HTMLDialogElement | undefined>();
  const channel = useMemo(() => {
    return new BroadcastChannel(CTT_LIST_CHANNEL);
  }, []);

  const [editorType, setEditorType] = useState<EDITOR_MODE>(
    EDITOR_MODE.DEFAULT
  );
  const [endpointId, setEndpointId] = useState<number | undefined>();

  const comPortList = useMemo(() => {
    const list = [];
    for (let i = 1; i <= 256; i++) {
      list.push(`${i}`);
    }
    return list;
  }, []);

  const [addOrEditTestingManagerItem, { client }] =
    useAddOrEditTestingManagerItemMutation({
      onError(error) {
        toast("추가 또는 수정을 할 수 없습니다.", {
          type: toast.TYPE.ERROR,
          theme: "colored"
        });
        console.log(error);
      },
      update(_, { data }) {
        if (data?.addOrEditTestingManagerItem.ok) {
          client.resetStore();
          toast("추가 또는 수정을 하였습니다.", {
            type: toast.TYPE.SUCCESS,
            theme: "colored"
          });
        }
      }
    });

  const [deleteTestingManagerItem] = useDeleteTestingManagerItemMutation({
    onError(error) {
      toast("삭제를 할 수 없습니다.", {
        type: toast.TYPE.ERROR,
        theme: "colored"
      });
      console.log(error);
    },
    update(_, { data }) {
      if (data?.deleteTestingManagerItem.ok) {
        client.resetStore();
        toast("성공적으로 삭제하였습니다.", {
          type: toast.TYPE.SUCCESS,
          theme: "colored"
        });
      }
    }
  });

  const { control, setValue } = useForm<any>({
    mode: "onChange"
  });

  const { field: examinePort } = useController({
    name: "examinePort",
    control,
    defaultValue: ""
  });

  const { field: examineName } = useController({
    name: "examineName",
    control,
    defaultValue: ""
  });
  const { field: manufacturerCodeName } = useController({
    name: "manufacturerCodeName",
    control,
    defaultValue: "",
    rules: {
      pattern: /^[0-9a-fA-F]*$/
    }
  });
  const { field: mngNumber } = useController({
    name: "mngNumber",
    control,
    defaultValue: ""
  });
  const { field: propertyNumber } = useController({
    name: "propertyNumber",
    control,
    defaultValue: ""
  });

  const { field: propertyVersion } = useController({
    name: "propertyVersion",
    control,
    defaultValue: ""
  });

  const { field: cttName } = useController({
    name: "cttName",
    control,
    defaultValue: ""
  });

  const { field: rtuIp } = useController({
    name: "rtuIp",
    control,
    defaultValue: ""
  });

  const { field: personInChargeName } = useController({
    name: "personInChargeName",
    control,
    defaultValue: ""
  });

  const { field: personInChargeTel } = useController({
    name: "personInChargeTel",
    control,
    defaultValue: ""
  });

  const { field: descr } = useController({
    name: "descr",
    control,
    defaultValue: ""
  });

  const { field: modelCode } = useController({
    name: "modelCode",
    control,
    defaultValue: ""
  });

  const { field: rtuMeasurementMinCycle } = useController({
    name: "rtuMeasurementMinCycle",
    control,
    defaultValue: ""
  });

  const handleHexInput = useCallback((value?: string): boolean => {
    if ((value && /^[0-9a-fA-F]*$/.test(value)) || !value) {
      return true;
    }
    return false;
  }, []);

  useEffect(() => {
    if (selectedTesting) {
      setValue("examineName", selectedTesting.original.examineName ?? "");
      setValue(
        "manufacturerCodeName",
        selectedTesting.original.manufacturerCodeName ?? ""
      );
      setValue("mngNumber", selectedTesting.original.mngNumber ?? "");
      setValue("propertyNumber", selectedTesting.original.propertyNumber ?? "");
      setValue("cttName", selectedTesting.original.cttName ?? "");
      setValue("rtuIp", selectedTesting.original.rtuIp ?? "");
      setValue(
        "personInChargeName",
        selectedTesting.original.personInChargeName ?? ""
      );
      setValue(
        "personInChargeTel",
        selectedTesting.original.personInChargeTel ?? ""
      );
      setValue("descr", selectedTesting.original.descr ?? "");
      setValue(
        "propertyVersion",
        selectedTesting.original.propertyVersion ?? ""
      );
      setValue(
        "rtuMeasurementMinCycle",
        selectedTesting.original.rtuMeasurementMinCycle ?? ""
      );
      setValue("modelCode", selectedTesting.original.modelCode ?? "");
      setEndpointId(selectedTesting.original.endpointId ?? undefined);
      setValue("examinePort", selectedTesting.original.examinePort ?? "");
    }
  }, [selectedTesting, setValue]);

  useEffect(() => {
    if (channel) {
      channel.onmessage = (event) => {
        if (event.data === OPEN_CTT_CHANNEL) {
          // 채널 오픈
        } else {
          toast(`${event.data?.cttName} CTT가 추가 되었습니다.`, {
            type: toast.TYPE.INFO,
            theme: "colored"
          });
          toast.clearWaitingQueue();
          setEndpointId(event.data.endpointId);
          setValue("cttName", event.data?.cttName ?? "");
        }
      };
    }
  }, [channel, setValue]);

  return (
    <Container>
      <Subject subject="시험관리 정보" />
      <EditorContainer>
        <Editor
          filedName="시험관리명"
          minWidthForTitle="172px"
          maxWidthForTitle="172px"
          required
        >
          {editorType === EDITOR_MODE.DEFAULT ? (
            <SSpan>{`${examineName.value}`}</SSpan>
          ) : (
            <Input
              {...examineName}
              disabled={editorType === EDITOR_MODE.EDIT}
              required
            />
          )}
        </Editor>
        <Editor
          filedName="관리번호"
          minWidthForTitle="172px"
          maxWidthForTitle="172px"
          required
        >
          {editorType === EDITOR_MODE.DEFAULT ? (
            <SSpan>{`${mngNumber.value}`}</SSpan>
          ) : (
            <Input {...mngNumber} required />
          )}
        </Editor>
        <Editor
          filedName="제조사코드(hex)"
          minWidthForTitle="172px"
          maxWidthForTitle="172px"
          required
        >
          <SearchContainer>
            {editorType === EDITOR_MODE.DEFAULT ? (
              <SSpan>{`${manufacturerCodeName.value}`}</SSpan>
            ) : (
              <Input
                {...manufacturerCodeName}
                onChange={(event) => {
                  const isHex = handleHexInput(event.currentTarget.value);
                  if (isHex) {
                    manufacturerCodeName.onChange(event);
                  }
                }}
                required
              />
            )}
          </SearchContainer>
        </Editor>
        <Editor
          filedName="기물번호(hex)"
          minWidthForTitle="172px"
          maxWidthForTitle="172px"
          required
        >
          {editorType === EDITOR_MODE.DEFAULT ? (
            <SSpan>{`${propertyNumber.value}`}</SSpan>
          ) : (
            <Input
              {...propertyNumber}
              onChange={(event) => {
                const isHex = handleHexInput(event.currentTarget.value);
                if (isHex) {
                  propertyNumber.onChange(event);
                }
              }}
              required
            />
          )}
        </Editor>
        <Editor
          filedName="버전(hex)"
          minWidthForTitle="172px"
          maxWidthForTitle="172px"
          required
        >
          {editorType === EDITOR_MODE.DEFAULT ? (
            <SSpan>{`${propertyVersion.value}`}</SSpan>
          ) : (
            <Input
              {...propertyVersion}
              onChange={(event) => {
                const isHex = handleHexInput(event.currentTarget.value);
                if (isHex) {
                  propertyVersion.onChange(event);
                }
              }}
              required
            />
          )}
        </Editor>
        <Editor
          filedName="장치타입(hex)"
          minWidthForTitle="172px"
          maxWidthForTitle="172px"
          required
        >
          {editorType === EDITOR_MODE.DEFAULT ? (
            <SSpan>{`${modelCode.value}`}</SSpan>
          ) : (
            <Input
              {...modelCode}
              onChange={(event) => {
                const isHex = handleHexInput(event.currentTarget.value);
                if (isHex) {
                  modelCode.onChange(event);
                }
              }}
            />
          )}
        </Editor>
        <Editor
          filedName="RTU IP"
          minWidthForTitle="172px"
          maxWidthForTitle="172px"
        >
          {editorType === EDITOR_MODE.DEFAULT ? (
            <SSpan>{`${rtuIp.value}`}</SSpan>
          ) : (
            <Input {...rtuIp} />
          )}
        </Editor>
        <Editor
          filedName="시험 주기"
          minWidthForTitle="172px"
          maxWidthForTitle="172px"
        >
          {editorType === EDITOR_MODE.DEFAULT ? (
            <Fragment>
              <SSpan>{`${rtuMeasurementMinCycle.value}`}</SSpan>
              {rtuMeasurementMinCycle.value && <SSpan>{`분`}</SSpan>}
            </Fragment>
          ) : (
            <ContentContainer>
              <Input type="number" {...rtuMeasurementMinCycle} />
              <SSpan>{`분`}</SSpan>
            </ContentContainer>
          )}
        </Editor>
        <Editor
          filedName="연결 CTT명"
          minWidthForTitle="172px"
          maxWidthForTitle="172px"
          required
        >
          {editorType === EDITOR_MODE.DEFAULT ? (
            <SSpan>{cttName.value}</SSpan>
          ) : (
            <>
              <Input {...cttName} />
              <IconContainer
                onClick={() => {
                  window.open(
                    `${routes.mangeTesting}/${routes.cttList}`,
                    "",
                    "width=1200, height=500, scrollbars=yes"
                  );
                }}
              >
                <Icon icon={SearchIcon} />
              </IconContainer>
            </>
          )}
        </Editor>
        <Editor
          filedName="시험장치 연결포트"
          minWidthForTitle="172px"
          maxWidthForTitle="172px"
          required
        >
          <SearchContainer>
            <SSpan>{`COM`}</SSpan>
            <select {...examinePort}>
              {comPortList.map((item, index) => (
                <option value={item} key={index}>
                  {item}
                </option>
              ))}
            </select>
          </SearchContainer>
        </Editor>
        <Editor
          filedName="담당자"
          minWidthForTitle="172px"
          maxWidthForTitle="172px"
        >
          {editorType === EDITOR_MODE.DEFAULT ? (
            <SSpan>{`${personInChargeName.value}`}</SSpan>
          ) : (
            <>
              <Input {...personInChargeName} />
            </>
          )}
        </Editor>
        <Editor
          filedName="담당자 연락처"
          minWidthForTitle="172px"
          maxWidthForTitle="172px"
        >
          {editorType === EDITOR_MODE.DEFAULT ? (
            <SSpan>{`${personInChargeTel.value}`}</SSpan>
          ) : (
            <Input {...personInChargeTel} />
          )}
        </Editor>
        <Editor
          filedName="비고"
          minWidthForTitle="172px"
          maxWidthForTitle="172px"
        >
          {editorType === EDITOR_MODE.DEFAULT ? (
            <SSpan>{`${descr.value}`}</SSpan>
          ) : (
            <Input {...descr} />
          )}
        </Editor>
      </EditorContainer>
      <ButtonContainer>
        <Button
          maxHeight="25px"
          maxWidth="50px"
          disabled={editorType !== EDITOR_MODE.DEFAULT}
          onClick={() => {
            setEditorType(EDITOR_MODE.ADD);
          }}
        >{`추가`}</Button>
        {editorType === EDITOR_MODE.DEFAULT && (
          <Button
            maxHeight="25px"
            maxWidth="50px"
            disabled={!selectedTesting}
            onClick={() => {
              setEditorType(EDITOR_MODE.EDIT);
            }}
          >{`수정`}</Button>
        )}
        {editorType !== EDITOR_MODE.DEFAULT && (
          <Button
            maxHeight="25px"
            maxWidth="50px"
            onClick={() => {
              setEditorType(EDITOR_MODE.DEFAULT);
              addOrEditTestingManagerItem({
                variables: {
                  examineName: examineName.value,
                  mngNumber: mngNumber.value,
                  manufacturerCodeName: manufacturerCodeName.value,
                  propertyNumber: propertyNumber.value,
                  propertyVersion: propertyVersion.value,
                  modelCode: modelCode.value,
                  rtuIp: rtuIp.value,
                  examinePort: examinePort.value,
                  rtuMeasurementMinCycle: rtuMeasurementMinCycle.value,
                  cttName: cttName.value,
                  endpointId,
                  personInChargeName: personInChargeName.value,
                  personInChargeTel: personInChargeTel.value,
                  descr: descr.value
                }
              });
            }}
          >{`저장`}</Button>
        )}
        <Button
          maxHeight="25px"
          maxWidth="50px"
          backgroundColor={colors.darkBlue}
          hoverBackgroundColor={colors.skyBlue}
          disabled={!selectedTesting || editorType !== EDITOR_MODE.DEFAULT}
          onClick={() => {
            dialogEl?.current?.showModal();
          }}
        >{`삭제`}</Button>
      </ButtonContainer>
      <Dialog ref={dialogEl} title="삭제" isRed>
        <DeleteTestingResult />
        <ButtonContainer>
          <Button
            value="confirm"
            maxWidth="50px"
            backgroundColor={colors.errorRed}
            hoverBackgroundColor={colors.lightRed}
            onClick={() => {
              deleteTestingManagerItem({
                variables: {
                  examineName: selectedExamineName
                }
              });
              dialogEl.current?.close();
            }}
          >{`예`}</Button>
          <Button
            value="close"
            maxWidth="50px"
            backgroundColor={colors.black}
            hoverBackgroundColor={colors.darkGrey}
            onClick={() => {
              dialogEl.current?.close();
            }}
          >{`아니오`}</Button>
        </ButtonContainer>
      </Dialog>
      <SToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={1}
      />
    </Container>
  );
}

export default FacilityEditor;
