import { useCallback, useState } from "react";

enum SortColumType {
  Asc = "ASC",
  Desc = "DESC"
}

export interface FieldSort {
  name: string;
  sort: SortColumType;
}

function useSortBy() {
  const [fieldSort, setFieldSort] = useState<FieldSort | undefined>();
  const [listOfColumnDisabled, setListOfColumnDisabled] = useState<string[]>(
    []
  );

  const handleListOfColumnDisabled = useCallback((payload: string[]) => {
    setListOfColumnDisabled(payload);
  }, []);

  const handleFieldSort = useCallback((payload?: FieldSort) => {
    setFieldSort(payload);
  }, []);

  return {
    fieldSort,
    handleFieldSort,
    listOfColumnDisabled,
    handleListOfColumnDisabled
  };
}

export default useSortBy;
