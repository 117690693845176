import { useRecoilValue } from "recoil";
import { Examine_Method } from "../../../__generated__/generated";
import { freeSetForSaveAtoms } from "../../../recoil/free_set_for_save_atoms/free_set_for_save_atoms";
import LongTermExamination from "../long_term_examination/long_term_examination";
import RealTimeRead from "../real_time_read/real_time_read";
import SaveRead from "../save_read/save_read";
import TestingSetting from "../testing_setting/testing_setting";

function TestingExtraFrom() {
  const freeSetStateForSave = useRecoilValue(freeSetForSaveAtoms);
  switch (freeSetStateForSave.testingType) {
    case Examine_Method.EnvironmentTest:
      return <TestingSetting />;
    case Examine_Method.RealTimeInspection:
      return <RealTimeRead />;
    case Examine_Method.DurabilityTest:
      return <LongTermExamination />;
    case Examine_Method.StorageInspection:
      return <SaveRead />;
    default:
      return <TestingSetting />;
  }
}

export default TestingExtraFrom;
