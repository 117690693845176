import styled from "styled-components";

interface IProps {
  subject: string;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
`;

const Title = styled.span`
  display: flex;
  flex: 1;
  font-weight: bold;
  align-items: center;
`;

const Point = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.green};
  text-align: center;
`;

function Subject({ subject }: IProps) {
  return (
    <Container>
      <Point>●</Point>
      <Title>{subject}</Title>
    </Container>
  );
}

export default Subject;
