import styled from "styled-components";

type TValue = string | number;

interface IProps {
  label: string;
  value: TValue;
  valueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type: string;
  placeholder: string;
}

const TextFieldContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
  width: calc(100% - 32px);
  margin: 20px 0px 20px 0px;
`;

const TextFieldTitle = styled.label<{ value: TValue }>`
  position: absolute;
  top: ${(props) => (props.value ? "-14px" : "0px")};
  left: ${(props) => (props.value ? "0px" : "10px")};
  opacity: ${(props) => (props.value ? "1" : "0")};
  color: ${(props) => (props.value ? "black" : "rgba(0, 0, 0, 0.54)")};
  visibility: ${(props) => (props.value ? "visible" : "hidden")};
  transition: all 0.1s ease-in-out;
`;

const STextField = styled.input`
  border: none;
  outline: none;
  padding: 5px;
  transition: all 0.1s ease-in-out;
  :focus + ${TextFieldTitle} {
    top: -14px;
    left: 0px;
    opacity: 1;
    visibility: visible;
    color: black;
  }
`;

const TextField = ({
  label,
  placeholder,
  value,
  valueChange,
  type
}: IProps) => {
  return (
    <TextFieldContainer>
      <STextField
        type={type}
        id={label}
        onChange={valueChange}
        placeholder={placeholder}
        value={value}
      />
      <TextFieldTitle value={value} htmlFor={label}>
        {label}
      </TextFieldTitle>
    </TextFieldContainer>
  );
};

export default TextField;
