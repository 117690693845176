import styled from "styled-components";

const StyledButton = styled.button<{
  minWidth?: string;
  maxWidth?: string;
  maxHeight?: string;
  backgroundColor?: string;
  hoverBackgroundColor?: string;
}>`
  display: flex;
  flex: 1;
  padding: ${(props) => (props.maxHeight ? "" : "6px")};
  background-color: ${(props) => {
    if (props.disabled) {
      return props.theme.colors.grey;
    }
    return props.backgroundColor
      ? props.backgroundColor
      : props.theme.colors.green;
  }};
  border-radius: 4px;
  color: ${(props) => props.theme.colors.white};
  justify-content: center;
  align-items: center;
  font-weight: bold;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  :hover {
    background-color: ${(props) => {
      if (props.disabled) {
        return props.theme.colors.grey;
      }
      return props.hoverBackgroundColor
        ? props.hoverBackgroundColor
        : props.theme.colors.lightGreen;
    }};
  }
  :active {
    transform: ${(props) => (props.disabled ? "" : "scale(0.98)")};
  }
  min-width: ${(props) => props.minWidth ?? "max-content"};
  max-width: ${(props) => props.maxWidth ?? ""};
  height: ${(props) => props.maxHeight ?? ""};
  max-height: ${(props) => props.maxHeight ?? ""};
`;

export default StyledButton;
