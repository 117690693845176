import styled from "styled-components";
import Editor from "../../editor/editor";
import ContentContainer from "../../shared/content_container";
import EditorContainer from "../../shared/editor-container";
import Section from "../../shared/section";
import Subject from "../../shared/subject";
import { Icon } from "@iconify/react";
import SSpan from "../../shared/s-span";
import ChipIcon from "@iconify/icons-heroicons/cpu-chip-solid";
import GaugeIcon from "@iconify/icons-mdi/gauge";
import ServerIcon from "@iconify/icons-icon-park-solid/connection-box";
import FactCheckIcon from "@iconify/icons-ic/baseline-fact-check";
import BookTimeIcon from "@iconify/icons-mdi/book-time";
import ListClock from "@iconify-icons/fluent/text-bullet-list-square-clock-20-filled";
import {
  Examine_Method,
  Examine_Target,
  TestingFreeSetNameEntity,
  useGetFreeSetItemLazyQuery,
  useGetTestingFreeSetListLazyQuery
} from "../../../__generated__/generated";
import { useEffect, useMemo } from "react";
import FreeSetController from "./free_set_controller/free_set_controller";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  FREE_SET_MESSAGE_DATA,
  freeSetAtoms
} from "../../../recoil/free_set_atoms/free_set_atoms";
import { isLogicStateAtoms } from "../../../recoil/is_logic_state_atoms/is_logic_state_atoms";
import { freeSetForSaveAtoms } from "../../../recoil/free_set_for_save_atoms/free_set_for_save_atoms";

const MenuContainer = styled(ContentContainer)`
  padding-left: 20px;
`;

const Menu = styled.div<{ isSelected?: boolean; $isEdit?: boolean }>`
  min-width: 128px;
  max-width: 128px;
  user-select: none;
  cursor: ${(props) => (props.$isEdit ? "pointer" : "")};
  display: flex;
  border: 5px solid
    ${(props) =>
      props.isSelected ? props.theme.colors.navy : props.theme.colors.grey};
  border-radius: 14px;
  color: ${(props) =>
    props.isSelected ? props.theme.colors.navy : props.theme.colors.grey};
  justify-content: center;
  align-items: center;
  font-size: 70px;
  padding: 0px 10px;
  flex-direction: column;
  span {
    font-weight: bold;
    font-size: 14px;
    color: ${(props) =>
      props.isSelected ? props.theme.colors.navy : props.theme.colors.grey};
  }
  :hover {
    color: ${(props) => {
      if (props.$isEdit) {
        return props.theme.colors.darkGreen;
      }
    }};
    border: 5px solid
      ${(props) => {
        if (props.$isEdit) {
          return props.theme.colors.darkGreen;
        }
      }};
    span {
      color: ${(props) => {
        if (props.$isEdit) {
          return props.theme.colors.darkGreen;
        }
      }};
    }
  }
  :active {
    color: ${(props) => {
      if (props.$isEdit) {
        return props.theme.colors.darkBlue;
      }
    }};
    border: 5px solid
      ${(props) => {
        if (props.$isEdit) {
          return props.theme.colors.darkBlue;
        }
      }};
    span {
      color: ${(props) => {
        if (props.$isEdit) {
          return props.theme.colors.darkBlue;
        }
      }};
    }
  }
`;

function TestingInfo() {
  const [freeSetState, setFreeSetState] = useRecoilState(freeSetAtoms);
  const [freeSetStateForSave, setFreeSetStateForSave] =
    useRecoilState(freeSetForSaveAtoms);
  const { isEditMode, isSave } = useRecoilValue(isLogicStateAtoms);

  const [getTestingFreeSetList, { data }] = useGetTestingFreeSetListLazyQuery({
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      if (data.getTestingFreeSetList.ok && data.getTestingFreeSetList.list) {
        setFreeSetState((pre) => {
          return {
            ...pre,
            freeSetItemName:
              data.getTestingFreeSetList.list[0].examineFreeSetName
          };
        });
      } else if (data.getTestingFreeSetList.error) {
        console.log(data.getTestingFreeSetList.error);
      }
    }
  });

  const [getFreeSetItem] = useGetFreeSetItemLazyQuery({
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      if (!data.getFreeSetItem.ok && data.getFreeSetItem.error) {
        console.log(data.getFreeSetItem.error);
      } else if (
        data.getFreeSetItem.item &&
        data.getFreeSetItem.item?.examineSetting
      ) {
        const testingType = data.getFreeSetItem.item?.examineMethod;
        const testingTargetType = data.getFreeSetItem.item?.examineTarget;
        const freeSetting: FREE_SET_MESSAGE_DATA = JSON.parse(
          data.getFreeSetItem.item?.examineSetting
        );

        if (testingType && testingTargetType) {
          const payload: FREE_SET_MESSAGE_DATA = {
            ...freeSetting,
            testingTargetType,
            testingType
          };
          setFreeSetState(payload);
        }
      }
    }
  });

  const list: TestingFreeSetNameEntity[] = useMemo(() => {
    return data?.getTestingFreeSetList.list ?? [];
  }, [data?.getTestingFreeSetList.list]);

  const isActive: boolean = useMemo(() => {
    return !!isEditMode || !!isSave;
  }, [isEditMode, isSave]);

  useEffect(() => {
    getTestingFreeSetList();
  }, [getTestingFreeSetList]);

  useEffect(() => {
    if (freeSetState.freeSetItemName) {
      getFreeSetItem({
        variables: {
          examineFreeSetName: freeSetState.freeSetItemName
        }
      });
    }
  }, [getFreeSetItem, freeSetState.freeSetItemName]);

  useEffect(() => {
    if (!isEditMode && !isSave) {
      setFreeSetStateForSave((pre) => {
        if (pre.freeSetItemName) {
          return { ...freeSetState };
        } else {
          return freeSetState;
        }
      });
    }
  }, [setFreeSetStateForSave, freeSetState, isEditMode, isSave]);

  return (
    <Section flex={1}>
      <Subject subject="시험 대상 및 시험 방식" />
      <EditorContainer flex={0}>
        <FreeSetController list={list} />
        <Editor filedName="" isTitleDisable>
          <span></span>
        </Editor>
        <Editor filedName="시험 대상" minHeight="auto">
          <MenuContainer>
            <Menu
              $isEdit={isActive}
              onClick={() => {
                if (isActive) {
                  setFreeSetStateForSave((pre) => {
                    return {
                      ...pre,
                      testingTargetType: Examine_Target.Calorimeter
                    };
                  });
                }
              }}
              isSelected={
                isActive
                  ? freeSetStateForSave.testingTargetType ===
                    Examine_Target.Calorimeter
                  : freeSetState.testingTargetType ===
                    Examine_Target.Calorimeter
              }
            >
              <Icon icon={GaugeIcon} />
              <SSpan>{`열량계`}</SSpan>
            </Menu>
            <Menu
              $isEdit={isActive}
              onClick={() => {
                if (isActive) {
                  setFreeSetStateForSave((pre) => {
                    return { ...pre, testingTargetType: Examine_Target.Rtu };
                  });
                }
              }}
              isSelected={
                isActive
                  ? freeSetStateForSave.testingTargetType === Examine_Target.Rtu
                  : freeSetState.testingTargetType === Examine_Target.Rtu
              }
            >
              <Icon icon={ChipIcon} />
              <SSpan>{`RTU`}</SSpan>
            </Menu>
          </MenuContainer>
        </Editor>
        <Editor filedName="시험 방식" minHeight="auto">
          <MenuContainer>
            <Menu
              $isEdit={isActive}
              onClick={() => {
                if (isActive) {
                  setFreeSetStateForSave((pre) => {
                    return {
                      ...pre,
                      testingType: Examine_Method.EnvironmentTest
                    };
                  });
                }
              }}
              isSelected={
                isActive
                  ? freeSetStateForSave.testingType ===
                    Examine_Method.EnvironmentTest
                  : freeSetState.testingType === Examine_Method.EnvironmentTest
              }
            >
              <Icon icon={ServerIcon} />
              <SSpan>{`환경 테스트`}</SSpan>
            </Menu>
            <Menu
              $isEdit={isActive}
              onClick={() => {
                if (isActive) {
                  setFreeSetStateForSave((pre) => {
                    return {
                      ...pre,
                      testingType: Examine_Method.RealTimeInspection
                    };
                  });
                }
              }}
              isSelected={
                isActive
                  ? freeSetStateForSave.testingType ===
                    Examine_Method.RealTimeInspection
                  : freeSetState.testingType ===
                    Examine_Method.RealTimeInspection
              }
            >
              <Icon icon={FactCheckIcon} />
              <SSpan>{`실시간 검침`}</SSpan>
            </Menu>
            <Menu
              $isEdit={isActive}
              onClick={() => {
                if (isActive) {
                  setFreeSetStateForSave((pre) => {
                    return {
                      ...pre,
                      testingType: Examine_Method.DurabilityTest
                    };
                  });
                }
              }}
              isSelected={
                isActive
                  ? freeSetStateForSave.testingType ===
                    Examine_Method.DurabilityTest
                  : freeSetState.testingType === Examine_Method.DurabilityTest
              }
            >
              <Icon icon={BookTimeIcon} />
              <SSpan>{`내구성 시험`}</SSpan>
            </Menu>
            {freeSetState.testingTargetType === Examine_Target.Calorimeter && (
              <Menu
                $isEdit={isActive}
                onClick={() => {
                  if (isActive) {
                    setFreeSetStateForSave((pre) => {
                      return {
                        ...pre,
                        testingType: Examine_Method.StorageInspection
                      };
                    });
                  }
                }}
                isSelected={
                  isActive
                    ? freeSetStateForSave.testingType ===
                      Examine_Method.StorageInspection
                    : freeSetState.testingType ===
                      Examine_Method.StorageInspection
                }
              >
                <Icon icon={ListClock} />
                <SSpan>{`저장 검침`}</SSpan>
              </Menu>
            )}
          </MenuContainer>
        </Editor>
      </EditorContainer>
    </Section>
  );
}

export default TestingInfo;
