import styled from "styled-components";

interface IProps {
  title: string;
  handleTabSelected: (tab: string) => void;
  selectedTab: string;
}

const Container = styled.div<{ isSelected: boolean }>`
  display: flex;
  border-top: 1px solid ${(props) => props.theme.colors.green};
  border-left: 1px solid ${(props) => props.theme.colors.green};
  border-right: 1px solid ${(props) => props.theme.colors.green};
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.isSelected ? props.theme.colors.green : props.theme.colors.white};
  cursor: pointer;
  color: ${(props) =>
    props.isSelected ? props.theme.colors.white : props.theme.colors.darkGrey};
  :hover {
    background-color: ${(props) => props.theme.colors.lightGreen};
    color: ${(props) => props.theme.colors.white};
  }
  padding: 5px 10px 5px 10px;
`;

function Tab({ title, selectedTab, handleTabSelected }: IProps) {
  return (
    <Container
      isSelected={title === selectedTab}
      onClick={() => {
        handleTabSelected(title);
      }}
    >
      {title}
    </Container>
  );
}

export default Tab;
