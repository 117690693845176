import { createGlobalStyle, DefaultTheme } from "styled-components";

export const colors = {
  white: "#ffffff",
  darkWhite: "#f8f8f8",
  lightGrey: "#F9F9F9",
  littleLightGrey: "#F1F2F4",
  frameGrey: "#E9EBEF",
  grey: "#C9C9C9",
  darkGrey: "#9BA1AB",
  black: "#263238",
  veryDarkBlue: "#0066B3",
  lightBlue: "#445F76",
  darkBlue: "#0984e3",
  skyBlue: "#74b9ff",
  purpleBlue: "#c5d0fc",
  navy: "#354254",
  tomato: "#ff7675",
  errorRed: "#BB2E1B",
  darkRed: "#eb3b5a",
  lightRed: "#f5bfc9",
  green: "#21B5A2",
  lightGreen: "#24CEBA",
  darkGreen: "#354254",
  yellow: "#FFFCDE",
  darkYellow: "#ff9f43",
  orange: "#F24E1E",
  lemon: "lemonchiffon"
};

export const lightTheme: DefaultTheme = {
  colors,
  bgColor: colors.darkWhite,
  fontColor: colors.black,
  titleBarColor: colors.veryDarkBlue,
  borderColor: colors.darkGrey
};

export const darkTheme: DefaultTheme = {
  colors,
  bgColor: colors.black,
  fontColor: colors.darkWhite,
  titleBarColor: colors.veryDarkBlue,
  borderColor: colors.darkGrey
};

export const GlobalStyles = createGlobalStyle`
      button {
        all:unset;
      }
      * {
        font-family: --apple-system, BlinkMacSystemFont, 'Nanum Gothic', 'Noto Sans KR', 'Open Sans', 'Roboto', sans-serif;
        box-sizing:border-box;
        padding: 0px;
        margin: 0;
      }
      body {          
          text-align: center;
          font-size:13px;
          color:${(props) => props.theme.fontColor};
      }
      input[type="date"] {
        padding: 6px;
      }
      input[type="radio"] {
        border: none;
      }
      input {
        outline: unset;
        border: 1px solid #D7DAE1;
        padding: 5px;
        border-radius: 4px;
      }
      select {
        padding-top:5px;
        padding-bottom: 5px;
        outline: unset;
        border: 1px solid #D7DAE1;
        border-radius: 7px;
      }
      a {
        all: unset;
        text-decoration: none;
        color: inherit;
      }
  `;
