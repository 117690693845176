import styled from "styled-components";

interface IProps {
  color?: string;
  $minWidth?: string;
  $isBold?: boolean;
}

const SSpan = styled.span<IProps>`
  padding: 5px;
  text-align: center;
  min-width: ${(props) => props.$minWidth ?? "max-content"};
  max-width: ${(props) => props.$minWidth ?? ""};
  min-height: 27px;
  color: ${(props) => props.color ?? props.theme.colors.black};
  white-space: pre-wrap;
  font-weight: ${(props) => (props.$isBold ? "bold" : "")};
`;

export default SSpan;
