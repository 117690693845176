import { useCallback, useState } from "react";
import * as ReactTable from "react-table";
import ManageTestingList from "../manage-testing-list/manage-testing-list";
import FacilityEditor from "../facility-editor/facility-editor";
import Main from "../shared/main";

function TestingManagementManager() {
  const [selectedExamineName, setSelectedExamineName] = useState<string[]>([]);
  const [selectedTesting, setSelectedTesting] = useState<
    ReactTable.Row<any> | undefined
  >();

  const handleSelectedTesting = useCallback((payload?: ReactTable.Row<any>) => {
    setSelectedTesting(payload);
  }, []);

  return (
    <Main>
      <ManageTestingList
        handleSelectedTesting={handleSelectedTesting}
        setSelectedExamineName={setSelectedExamineName}
      />
      <FacilityEditor
        selectedTesting={selectedTesting}
        selectedExamineName={selectedExamineName}
      />
    </Main>
  );
}

export default TestingManagementManager;
