import styled from "styled-components";

const Button = styled.button<{
  maxWidth?: string;
  maxHeight?: string;
  backgroundColor?: string;
  hoverBackgroundColor?: string;
}>`
  display: flex;
  flex: 1;
  border-radius: 4px;
  background-color: ${(props) =>
    props.backgroundColor ?? props.theme.colors.green};
  color: white;
  justify-content: center;
  align-items: center;
  max-width: ${(props) => props.maxWidth ?? ""};
  max-height: ${(props) => props.maxHeight ?? ""};
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.hoverBackgroundColor ?? props.theme.colors.lightGreen};
  }
  :active {
    transform: scale(0.98);
  }
  min-height: max-content;
  padding: 3px;
  :disabled {
    background-color: ${(props) => props.theme.colors.grey};
    transform: none;
  }
`;

export default Button;
