import PopUpContainer from "../shared/pop_up_container";
import useCreateTitle from "../../hooks/use-create-title";
import PageTitle from "../shared/page-title";
import SearchContainer from "../shared/search-container";
import StyledInput from "../shared/styled-input";
import { Icon } from "@iconify/react";
import SearchIcon from "@iconify/icons-mdi/magnify";
import CompactTableContainer from "../shared/compact-table-container";
import Table from "../table/table";
import * as ReactTable from "react-table";
import { useEffect, useMemo } from "react";
import useSortBy from "../../hooks/use-sort-by";
import Button from "../shared/button";
import TableTitleBar from "../table/table-title-bar";

function SearchManuFacturer() {
  const { title } = useCreateTitle();
  const {
    fieldSort,
    handleFieldSort,
    listOfColumnDisabled,
    handleListOfColumnDisabled
  } = useSortBy();

  const list: any[] = useMemo(() => {
    const newList = [];
    for (let i = 1; i <= 10; i++) {
      newList.push({
        noNum: i,
        manufacturer: "HSCMT"
      });
    }
    return newList;
  }, []);

  const columns: ReactTable.Column<any>[] = useMemo(() => {
    return [
      { Header: "번호", accessor: "noNum" },
      { Header: "제조사명", accessor: "manufacturer" },
      {
        Header: "",
        accessor: "button",
        Cell(cell) {
          return <Button maxWidth="50px">{`선택`}</Button>;
        }
      }
    ];
  }, []);

  const { prepareRow, getTableProps, headerGroups, getTableBodyProps, rows } =
    ReactTable.useTable<any>(
      {
        columns,
        data: list
      },
      ReactTable.useBlockLayout,
      ReactTable.useRowSelect,
      ReactTable.useColumnOrder
    );

  useEffect(() => {
    handleListOfColumnDisabled(["noNum", "button"]);
  }, [handleListOfColumnDisabled]);

  return (
    <PopUpContainer>
      <PageTitle title={title} />
      <CompactTableContainer>
        <TableTitleBar title="제조사 검색">
          <SearchContainer>
            <StyledInput placeholder="제조사명" />
            <Icon icon={SearchIcon} />
          </SearchContainer>
        </TableTitleBar>
        <Table<any>
          title="시험관리 목록"
          prepareRow={prepareRow}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          fieldSort={fieldSort}
          handleFieldSort={handleFieldSort}
          listOfColumnDisabled={listOfColumnDisabled}
          listOfFlexForHeader={["번호", "제조사명", ""]}
        />
      </CompactTableContainer>
    </PopUpContainer>
  );
}

export default SearchManuFacturer;
