import Editor from "../../editor/editor";
import EditorContainer from "../../shared/editor-container";
import SSpan from "../../shared/s-span";
import Section from "../../shared/section";
import Subject from "../../shared/subject";
import Input from "../../shared/input";
import RadioBox from "../../shared/radio_box";
import {
  ACTION_ON_ERROR_DETECTION_TYPE,
  DATA_METHOD_TYPE
} from "../../../recoil/free_set_atoms/free_set_atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import { isActiveSelector } from "../../../recoil/is_logic_state_atoms/is_logic_state_atoms";
import { useMemo } from "react";
import { freeSetForSaveAtoms } from "../../../recoil/free_set_for_save_atoms/free_set_for_save_atoms";

function LongTermExamination() {
  const [freeSetStateForSave, setFreeSetStateForSave] =
    useRecoilState(freeSetForSaveAtoms);
  const isActive = useRecoilValue(isActiveSelector);
  const cVGMethod = useMemo((): string => {
    switch (freeSetStateForSave.calorieValueGenerationMethod) {
      case DATA_METHOD_TYPE.IS_FIXED_VALUE:
        return "고정값 상시 적용";
      case DATA_METHOD_TYPE.ADD_VALUE_BY_TIMES:
        return "회차마다 증가값 사용";
      case DATA_METHOD_TYPE.UN_USED:
        return "사용안함";
    }
    return "";
  }, [freeSetStateForSave.calorieValueGenerationMethod]);

  const tVGMethod = useMemo((): string => {
    switch (freeSetStateForSave.temperatureValueGenerationMethod) {
      case DATA_METHOD_TYPE.IS_FIXED_VALUE:
        return "고정값 상시 적용";
      case DATA_METHOD_TYPE.ADD_VALUE_BY_TIMES:
        return "회차마다 증가값 사용";
      case DATA_METHOD_TYPE.UN_USED:
        return "사용안함";
    }
    return "";
  }, [freeSetStateForSave.temperatureValueGenerationMethod]);
  return (
    <Section flex={2}>
      <Subject subject="시험 설정" />
      <EditorContainer>
        <Editor filedName="타임아웃 설정">
          {isActive ? (
            <Input
              type="number"
              min={0}
              step={1}
              value={freeSetStateForSave.timeOutSetting}
              onChange={event => {
                if (event.currentTarget.value) {
                  setFreeSetStateForSave(pre => {
                    return {
                      ...pre,
                      timeOutSetting: parseInt(event.currentTarget.value)
                    };
                  });
                }
              }}
            />
          ) : (
            <SSpan>{freeSetStateForSave.timeOutSetting}</SSpan>
          )}
          <SSpan>{`초`}</SSpan>
        </Editor>
        <Editor filedName="타임아웃 버퍼">
          <SSpan>{`± 최대`}</SSpan>
          {isActive ? (
            <Input
              type="number"
              min={0}
              step={1}
              value={freeSetStateForSave.timeOutBuffer}
              onChange={event => {
                if (event.currentTarget.value) {
                  setFreeSetStateForSave(pre => {
                    return {
                      ...pre,
                      timeOutBuffer: parseInt(event.currentTarget.value)
                    };
                  });
                }
              }}
            />
          ) : (
            <SSpan>{freeSetStateForSave.timeOutBuffer}</SSpan>
          )}
          <SSpan $minWidth="40px">{`초`}</SSpan>
        </Editor>
        <Editor filedName="시험회차">
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.examineCount}
              min={0}
              step={1}
              onChange={event =>
                setFreeSetStateForSave(pre => ({
                  ...pre,
                  examineCount: parseInt(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.examineCount}</SSpan>
          )}
          <SSpan>{`회`}</SSpan>
        </Editor>
        <Editor filedName="에러 검출시 동작">
          {isActive ? (
            <RadioBox>
              <Input
                type="radio"
                id="stop"
                name="testing"
                value={ACTION_ON_ERROR_DETECTION_TYPE.STOP}
                checked={
                  freeSetStateForSave.actionOnErrorDetection ===
                  ACTION_ON_ERROR_DETECTION_TYPE.STOP
                }
                onChange={() =>
                  setFreeSetStateForSave(pre => ({
                    ...pre,
                    actionOnErrorDetection: ACTION_ON_ERROR_DETECTION_TYPE.STOP
                  }))
                }
              />
              <label htmlFor="stop">{`즉시 중단`}</label>
              <Input
                type="radio"
                id="continue"
                name="testing"
                value={ACTION_ON_ERROR_DETECTION_TYPE.CONTINUE}
                checked={
                  freeSetStateForSave.actionOnErrorDetection ===
                  ACTION_ON_ERROR_DETECTION_TYPE.CONTINUE
                }
                onChange={() =>
                  setFreeSetStateForSave(pre => ({
                    ...pre,
                    actionOnErrorDetection:
                      ACTION_ON_ERROR_DETECTION_TYPE.CONTINUE
                  }))
                }
              />
              <label htmlFor="continue">{`검사 계속`}</label>
            </RadioBox>
          ) : (
            <SSpan>
              {freeSetStateForSave.actionOnErrorDetection ===
              ACTION_ON_ERROR_DETECTION_TYPE.STOP
                ? "즉시 중단"
                : "검사 계속"}
            </SSpan>
          )}
        </Editor>
      </EditorContainer>
      <Subject subject="피시험 열량계 사전 설정내역" />
      <EditorContainer>
        <Editor filedName="열량값 생성방식">
          {isActive ? (
            <RadioBox>
              <Input
                type="radio"
                id="isFixedValue"
                name="calorie_value_generation_method"
                checked={
                  freeSetStateForSave.calorieValueGenerationMethod ===
                  DATA_METHOD_TYPE.IS_FIXED_VALUE
                }
                onChange={() => {
                  setFreeSetStateForSave(pre => {
                    return {
                      ...pre,
                      calorieValueGenerationMethod:
                        DATA_METHOD_TYPE.IS_FIXED_VALUE
                    };
                  });
                }}
              />
              <label htmlFor="isFixedValue">{`고정값 상시 적용`}</label>
              <Input
                type="radio"
                id="addValueByTimes"
                name="calorie_value_generation_method"
                checked={
                  freeSetStateForSave.calorieValueGenerationMethod ===
                  DATA_METHOD_TYPE.ADD_VALUE_BY_TIMES
                }
                onChange={() => {
                  setFreeSetStateForSave(pre => {
                    return {
                      ...pre,
                      calorieValueGenerationMethod:
                        DATA_METHOD_TYPE.ADD_VALUE_BY_TIMES
                    };
                  });
                }}
              />
              <label htmlFor="addValueByTimes">{`회차마다 증가값 사용`}</label>
              <Input
                type="radio"
                id="empty"
                name="calorie_value_generation_method"
                checked={
                  freeSetStateForSave.calorieValueGenerationMethod ===
                  DATA_METHOD_TYPE.UN_USED
                }
                onChange={() => {
                  setFreeSetStateForSave(pre => {
                    return {
                      ...pre,
                      calorieValueGenerationMethod: DATA_METHOD_TYPE.UN_USED
                    };
                  });
                }}
              />
              <label htmlFor="empty">{`사용안함`}</label>
            </RadioBox>
          ) : (
            <SSpan>{cVGMethod}</SSpan>
          )}
        </Editor>
        {freeSetStateForSave.calorieValueGenerationMethod ===
          DATA_METHOD_TYPE.IS_FIXED_VALUE && (
          <Editor filedName="열량값 설정">
            {isActive ? (
              <Input
                value={freeSetStateForSave.calorificValueSetting}
                onChange={event =>
                  setFreeSetStateForSave(pre => ({
                    ...pre,
                    calorificValueSetting: event.currentTarget.value
                  }))
                }
              />
            ) : (
              <SSpan>{freeSetStateForSave.calorificValueSetting}</SSpan>
            )}
            <SSpan $minWidth="40px">{`kWh`}</SSpan>
          </Editor>
        )}
        {freeSetStateForSave.calorieValueGenerationMethod ===
          DATA_METHOD_TYPE.ADD_VALUE_BY_TIMES && (
          <Editor filedName="열량값 증가값">
            {isActive ? (
              <Input
                value={freeSetStateForSave.calorificValueIncrement}
                onChange={event =>
                  setFreeSetStateForSave(pre => ({
                    ...pre,
                    calorificValueIncrement: event.currentTarget.value
                  }))
                }
              />
            ) : (
              <SSpan>{freeSetStateForSave.calorificValueIncrement}</SSpan>
            )}
            <SSpan $minWidth="40px">{`kWh`}</SSpan>
          </Editor>
        )}
        {freeSetStateForSave.calorieValueGenerationMethod ===
          DATA_METHOD_TYPE.UN_USED && (
          <Editor filedName="">
            <SSpan></SSpan>
          </Editor>
        )}
        <Editor filedName="온도값 생성방식">
          {isActive ? (
            <RadioBox>
              <Input
                type="radio"
                id="temperature_value_generation_method_is_fixed_value"
                name="temperature_value_generation_method"
                checked={
                  freeSetStateForSave.temperatureValueGenerationMethod ===
                  DATA_METHOD_TYPE.IS_FIXED_VALUE
                }
                onChange={() => {
                  setFreeSetStateForSave(pre => ({
                    ...pre,
                    temperatureValueGenerationMethod:
                      DATA_METHOD_TYPE.IS_FIXED_VALUE
                  }));
                }}
              />
              <label htmlFor="temperature_value_generation_method_is_fixed_value">{`고정값 상시 적용`}</label>
              <Input
                type="radio"
                id="temperature_value_generation_method_add_value_by_times"
                name="temperature_value_generation_method"
                checked={
                  freeSetStateForSave.temperatureValueGenerationMethod ===
                  DATA_METHOD_TYPE.ADD_VALUE_BY_TIMES
                }
                onChange={() => {
                  setFreeSetStateForSave(pre => ({
                    ...pre,
                    temperatureValueGenerationMethod:
                      DATA_METHOD_TYPE.ADD_VALUE_BY_TIMES
                  }));
                }}
              />
              <label htmlFor="temperature_value_generation_method_add_value_by_times">{`회차마다 증가값 사용`}</label>
              <Input
                type="radio"
                id="temperature_value_generation_method_un_used"
                name="temperature_value_generation_method"
                checked={
                  freeSetStateForSave.temperatureValueGenerationMethod ===
                  DATA_METHOD_TYPE.UN_USED
                }
                onChange={() => {
                  setFreeSetStateForSave(pre => ({
                    ...pre,
                    temperatureValueGenerationMethod: DATA_METHOD_TYPE.UN_USED
                  }));
                }}
              />
              <label htmlFor="temperature_value_generation_method_un_used">{`사용안함`}</label>
            </RadioBox>
          ) : (
            <SSpan>{tVGMethod}</SSpan>
          )}
        </Editor>
        {freeSetStateForSave.temperatureValueGenerationMethod ===
          DATA_METHOD_TYPE.IS_FIXED_VALUE && (
          <Editor filedName="온도 설정값">
            {isActive ? (
              <Input
                value={freeSetStateForSave.temperatureSetting}
                onChange={event =>
                  setFreeSetStateForSave(pre => ({
                    ...pre,
                    temperatureSetting: event.currentTarget.value ?? ""
                  }))
                }
              />
            ) : (
              <SSpan>{freeSetStateForSave.temperatureSetting}</SSpan>
            )}
            <SSpan $minWidth="40px">{`℃`}</SSpan>
          </Editor>
        )}
        {freeSetStateForSave.temperatureValueGenerationMethod ===
          DATA_METHOD_TYPE.ADD_VALUE_BY_TIMES && (
          <Editor filedName="온도차">
            {isActive ? (
              <Input
                value={freeSetStateForSave.temperatureIncrement}
                onChange={event =>
                  setFreeSetStateForSave(pre => ({
                    ...pre,
                    temperatureIncrement: event.currentTarget.value ?? ""
                  }))
                }
              />
            ) : (
              <SSpan>{freeSetStateForSave.temperatureIncrement}</SSpan>
            )}
            <SSpan $minWidth="40px">{`℃`}</SSpan>
          </Editor>
        )}
        {freeSetStateForSave.temperatureValueGenerationMethod ===
          DATA_METHOD_TYPE.UN_USED && (
          <Editor filedName="">
            <SSpan $minWidth="40px">{``}</SSpan>
          </Editor>
        )}
      </EditorContainer>
    </Section>
  );
}

export default LongTermExamination;
