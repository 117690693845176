import PageTitle from "../components/shared/page-title";
import PageContainer from "../components/shared/page-container";
import useCreateTitle from "../hooks/use-create-title";
import { useMemo } from "react";
import Tabs, { ITab } from "../components/tabs/tabs";
import useTabs from "../hooks/use-tabs";
import TestingManagementManager from "../components/testing_management_manager/testing_management_manager";
import TestingInfoManager from "../components/testing_info_manager/testing_info_manager";

function ManageTesting() {
  const { title } = useCreateTitle();
  const tabs: ITab[] = useMemo(
    () => [
      { title: "시험관리" }
      //  { title: "정보관리" }
    ],
    []
  );
  const { selectedTab, handleTabSelected } = useTabs(tabs[0].title);

  return (
    <PageContainer>
      <PageTitle title={title} />
      <Tabs
        list={tabs}
        selectedTab={selectedTab}
        handleTabSelected={handleTabSelected}
      />
      {selectedTab === tabs[0].title && <TestingManagementManager />}
      {/* {selectedTab === tabs[1].title && <TestingInfoManager />} */}
    </PageContainer>
  );
}

export default ManageTesting;
