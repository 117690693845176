import PopUpContainer from "../shared/pop_up_container";
import useCreateTitle from "../../hooks/use-create-title";
import PageTitle from "../shared/page-title";
import CompactTableContainer from "../shared/compact-table-container";
import Table from "../table/table";
import * as ReactTable from "react-table";
import { useCallback, useEffect, useMemo } from "react";
import useSortBy from "../../hooks/use-sort-by";
import Button from "../shared/button";
import SearchWithCategories from "../search-with-categories/search-with-categories";
import usePageControl from "../../hooks/use-page.control";
import { useController, useForm } from "react-hook-form";
import useSearchInTable, { ISearchForm } from "../../hooks/use-search-in-table";
import { TColumn } from "../../hooks/use-hide-columns";
import ButtonContainer from "../shared/button-container";
import { IRow } from "../table/render-row";
import TableFooter from "../table/table_footer";
import TableTitleBar from "../table/table-title-bar";
import {
  CttItemEntity,
  CttItemSearchType,
  useGetCountCttItemLazyQuery,
  useGetCttItemListLazyQuery
} from "../../__generated__/generated";

const IColumns = {
  STATUS: "상태",
  ID: "아이디",
  NAME: "이름",
  VERSION: "버전",
  IP: "아이피",
  MAC_ADDRESS: "맥어드레스",
  COMPUTER_NAME: "컴퓨터이름",
  MEMO: "메모"
} as const;

export const CTT_LIST_CHANNEL = "CTT_LIST_CHANNEL";
export const OPEN_CTT_CHANNEL = "OPEN_CTT_CHANNEL";

function CttList() {
  const { title } = useCreateTitle();

  const channel = useMemo(() => {
    return new BroadcastChannel(CTT_LIST_CHANNEL);
  }, []);

  const {
    fieldSort,
    handleFieldSort,
    listOfColumnDisabled,
    handleListOfColumnDisabled
  } = useSortBy();

  const {
    searchTypeData,
    searchValueData,
    isSearch,
    handleSearchTypeData,
    handleSearchValueData,
    handleIsSearch
  } = useSearchInTable<CttItemSearchType>({
    defaultSearchType: CttItemSearchType.AsNo
  });

  const { control, getValues } = useForm<ISearchForm<CttItemSearchType>>({
    defaultValues: {
      searchType: searchTypeData,
      searchValue: searchValueData
    }
  });

  const { field: searchType } = useController({
    name: "searchType",
    control
  });

  const { field: searchValue } = useController({
    name: "searchValue",
    control
  });

  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();

  const [getCountCTTItem, { data: count }] = useGetCountCttItemLazyQuery({
    onError(error) {
      console.log(error);
    }
  });

  const total: number = useMemo(
    () => count?.getCountCTTItem.count ?? 0,
    [count]
  );

  const [getCttItemList, { data }] = useGetCttItemListLazyQuery({
    onError(error) {
      console.log(error);
    }
  });

  const list = useMemo(() => {
    return data?.getCttItemList.list ?? [];
  }, [data]);

  const columns: ReactTable.Column<CttItemEntity>[] = useMemo(() => {
    const width = 60;
    return [
      { Header: "번호", accessor: "asNo", width },
      { Header: "상태", accessor: "cttonoff", width },
      { Header: "이름", accessor: "cttName" },
      { Header: "버전", accessor: "cttVersion" },
      { Header: "아이피", cttIp: "cttIp" },
      { Header: "맥어드레스", accessor: "cttMac" },
      { Header: "컴퓨터이름", accessor: "cttComName" },
      { Header: "메모", accessor: "cttmemo" }
    ];
  }, []);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    columns: cttColumns,
    toggleHideColumn,
    selectedFlatRows
  } = ReactTable.useTable<CttItemEntity>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  const handleSelectRow = useCallback((row?: IRow<CttItemEntity>) => {}, []);

  const selectedRow: ReactTable.Row<CttItemEntity> | undefined = useMemo(() => {
    return selectedFlatRows.at(-1) ?? undefined;
  }, [selectedFlatRows]);

  useEffect(() => {
    handleListOfColumnDisabled(["noNum", "name", "computerName", "memo"]);
  }, [handleListOfColumnDisabled]);

  useEffect(() => {
    if (isSearch) {
      const { searchType, searchValue } = getValues();
      handleSearchTypeData(searchType);
      handleSearchValueData(searchValue);
      handleCurrentPage(1);
      handleIsSearch(false);
    }
  }, [
    isSearch,
    getValues,
    handleCurrentPage,
    handleSearchTypeData,
    handleSearchValueData,
    handleIsSearch
  ]);

  useEffect(() => {
    getCountCTTItem({
      variables: {
        searchType: searchTypeData,
        searchValue: searchValueData
      }
    });
  }, [getCountCTTItem, searchTypeData, searchValueData]);

  useEffect(() => {
    getCttItemList({
      variables: {
        searchType: searchTypeData,
        page: currentPage,
        take
      }
    });
  }, [getCttItemList, currentPage, take, searchTypeData]);

  useEffect(() => {
    if (channel) {
      channel.postMessage(OPEN_CTT_CHANNEL);
    }
  }, [channel]);

  return (
    <PopUpContainer>
      <PageTitle title={title} />
      <CompactTableContainer>
        <TableTitleBar
          title="CTT 목록"
          total={total}
          columns={cttColumns as TColumn<CttItemEntity>[]}
          toggleHideColumn={toggleHideColumn}
          take={take}
          handleTake={handleTake}
        >
          <SearchWithCategories
            list={Object.keys(IColumns).map((item) => ({
              name: IColumns[item as keyof typeof IColumns],
              value: item
            }))}
            searchType={searchType}
            searchValue={searchValue}
            handleSearch={handleIsSearch}
          />
        </TableTitleBar>
        <Table<CttItemEntity>
          title="CTT 목록"
          prepareRow={prepareRow}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          fieldSort={fieldSort}
          handleFieldSort={handleFieldSort}
          listOfColumnDisabled={listOfColumnDisabled}
          listOfFlexForHeader={[
            "이름",
            "아이디",
            "아이피",
            "맥어드레스",
            "컴퓨터이름"
          ]}
          handleSelectRow={handleSelectRow}
          selectedRow={selectedRow}
        />
        <TableFooter
          totalPage={Math.ceil(total / take)}
          currentPage={currentPage}
          handleCurrentPage={handleCurrentPage}
          handleTake={handleTake}
        />
      </CompactTableContainer>
      <ButtonContainer>
        <Button
          disabled={!selectedRow}
          onClick={() => {
            channel.postMessage(selectedRow?.original);
          }}
        >
          {"선택"}
        </Button>
      </ButtonContainer>
    </PopUpContainer>
  );
}

export default CttList;
