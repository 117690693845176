import { useCallback, useEffect, useMemo, useRef } from "react";
import CompactTableContainer from "../../shared/compact-table-container";
import TableTitleBar from "../../table/table-title-bar";
import SearchContainer from "../../shared/search-container";
import StyledInput from "../../shared/styled-input";
import { Icon } from "@iconify/react";
import SearchIcon from "@iconify/icons-mdi/magnify";
import Table from "../../table/table";
import * as ReactTable from "react-table";
import useSortBy from "../../../hooks/use-sort-by";
import Input from "../../shared/input";
import TableFooter from "../../table/table_footer";
import usePageControl from "../../../hooks/use-page.control";
import ButtonContainer from "../../shared/button-container";
import Button from "../../shared/button";
import { colors } from "../../../styles";
import useCreateTitle from "../../../hooks/use-create-title";
import PopUpContainer from "../../shared/pop_up_container";
import PageTitle from "../../shared/page-title";
import Dialog from "../../dialog/dialog";
import DeleteTestingPreset from "./delete_testing_preset";
import {
  Examine_Method,
  Examine_Target,
  TestingFreeSetNameEntity,
  useDeleteFreeSetItemMutation,
  useGetTestingFreeSetListLazyQuery
} from "../../../__generated__/generated";
import { Cell } from "../../../types/react-table.d";
import SToastContainer from "../../shared/s_toast_container";
import { toast } from "react-toastify";
interface TestingFreeSetNameEntityType extends TestingFreeSetNameEntity {
  check?: boolean;
  noNum?: number;
}

function LoadTestingPresetDialog() {
  const deleteDialogEl = useRef<HTMLDialogElement | undefined>();
  const { title } = useCreateTitle();
  const {
    fieldSort,
    handleFieldSort,
    listOfColumnDisabled,
    handleListOfColumnDisabled
  } = useSortBy();

  const [getTestingFreeSetList, { data }] = useGetTestingFreeSetListLazyQuery({
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      if (data.getTestingFreeSetList.ok && data.getTestingFreeSetList.list) {
      } else if (data.getTestingFreeSetList.error) {
        console.log(data.getTestingFreeSetList.error);
      }
    }
  });

  const [deleteFreeSetting, { client }] = useDeleteFreeSetItemMutation({
    onError(error) {
      console.log(error);
    },
    update(_, { data }) {
      if (data?.deleteFreeSetItem.ok) {
        client.resetStore();

        toast(data?.deleteFreeSetItem.error, {
          type: toast.TYPE.SUCCESS,
          theme: "colored"
        });
        deleteDialogEl.current?.close();
      } else if (data?.deleteFreeSetItem.error) {
        toast(data?.deleteFreeSetItem.error, {
          type: toast.TYPE.SUCCESS,
          theme: "colored"
        });
      }
    }
  });

  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();

  const list: TestingFreeSetNameEntityType[] = useMemo(() => {
    return data?.getTestingFreeSetList.list ?? [];
  }, [data?.getTestingFreeSetList.list]);

  const columns: ReactTable.Column<TestingFreeSetNameEntityType>[] =
    useMemo(() => {
      return [
        {
          Header(header: any) {
            return (
              <Input
                type="checkbox"
                checked={header.isAllRowsSelected}
                onChange={() => {
                  header?.toggleAllRowsSelected(!header.isAllRowsSelected);
                }}
              />
            );
          },
          accessor: "check",
          Cell(cell: Cell<TestingFreeSetNameEntityType>) {
            return (
              <Input
                type="checkbox"
                checked={cell.row.isSelected}
                onChange={() => {
                  cell.row.toggleRowSelected(!cell.row.isSelected);
                }}
              />
            );
          },
          width: 40
        },
        { Header: "번호", accessor: "noNum", width: 40 },
        { Header: "이름", accessor: "examineFreeSetName" },
        {
          Header: "시험 대상",
          accessor: "examineTarget",
          width: 200,
          Cell(cell: Cell<TestingFreeSetNameEntityType>) {
            if (cell.value) {
              const examineTarget =
                (cell.value as any) === Examine_Target.Calorimeter
                  ? "열량계"
                  : "RTU";
              return examineTarget;
            }
            return "";
          }
        },
        {
          Header: "시험 방식",
          accessor: "examineMethod",
          width: 200,
          Cell(cell: Cell<TestingFreeSetNameEntityType>) {
            if (cell.value) {
              let examineMethod = "환경테스트";
              switch (cell.value as any) {
                case Examine_Method.RealTimeInspection:
                  examineMethod = "실시간검침";
                  break;
                case Examine_Method.DurabilityTest:
                  examineMethod = "내구성 시험";
                  break;
                case Examine_Method.StorageInspection:
                  examineMethod = "저장검침";
                  break;
              }

              return examineMethod;
            }
            return "";
          }
        }
      ];
    }, []);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    selectedFlatRows
  } = ReactTable.useTable<TestingFreeSetNameEntity>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  const handleDelete = useCallback(() => {
    if (selectedFlatRows.length > 0) {
      deleteFreeSetting({
        variables: {
          examineFreeSetName: selectedFlatRows.map(
            (item) => item.original.examineFreeSetName
          )
        }
      });
    }
  }, [deleteFreeSetting, selectedFlatRows]);

  useEffect(() => {
    getTestingFreeSetList();
  }, [getTestingFreeSetList]);

  useEffect(() => {
    handleListOfColumnDisabled(["check"]);
  }, [handleListOfColumnDisabled]);

  return (
    <PopUpContainer>
      <PageTitle title={title} />
      <CompactTableContainer>
        <TableTitleBar title="시험 프리셋" total={list.length ?? 0}>
          <SearchContainer>
            <StyledInput placeholder="시험 프리셋" />
            <Icon icon={SearchIcon} />
          </SearchContainer>
        </TableTitleBar>
        <Table<TestingFreeSetNameEntity>
          title="시험프리셋"
          prepareRow={prepareRow}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          fieldSort={fieldSort}
          handleFieldSort={handleFieldSort}
          listOfColumnDisabled={listOfColumnDisabled}
          listOfFlexForHeader={["이름"]}
        />
        <TableFooter
          totalPage={Math.ceil(list.length / take)}
          currentPage={currentPage}
          handleCurrentPage={handleCurrentPage}
          handleTake={handleTake}
        />
      </CompactTableContainer>
      <ButtonContainer>
        <Button
          backgroundColor={colors.darkBlue}
          hoverBackgroundColor={colors.skyBlue}
          maxWidth="70px"
          maxHeight="25px"
          onClick={() => {
            deleteDialogEl.current?.showModal();
          }}
        >{`선택삭제`}</Button>
      </ButtonContainer>
      <Dialog ref={deleteDialogEl} title="삭제" isRed>
        <DeleteTestingPreset />
        <ButtonContainer>
          <Button
            backgroundColor={colors.errorRed}
            hoverBackgroundColor={colors.lightRed}
            maxWidth="50px"
            onClick={() => {
              handleDelete();
            }}
          >{`예`}</Button>
          <Button
            backgroundColor={colors.black}
            hoverBackgroundColor={colors.lightRed}
            maxWidth="50px"
            onClick={() => {
              deleteDialogEl.current?.close();
            }}
          >{`아니오`}</Button>
        </ButtonContainer>
        <SToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Dialog>
    </PopUpContainer>
  );
}

export default LoadTestingPresetDialog;
