import styled from "styled-components";
import PageController from "../shared/page-controller";
import { IPageControllerProps } from "../shared/page-controller";
import TableButtons from "./table-buttons";
import { ITableButtons } from "./table-buttons";

interface IProps extends IPageControllerProps, Partial<ITableButtons> {}

const Footer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 10px;
`;

function TableFooter({
  totalPage,
  currentPage,
  handleCurrentPage,
  handleTake,
  listOfButton
}: IProps) {
  return (
    <Footer>
      {totalPage !== undefined &&
        currentPage &&
        handleCurrentPage &&
        handleTake && (
          <PageController
            totalPage={totalPage}
            currentPage={currentPage}
            handleCurrentPage={handleCurrentPage}
            handleTake={handleTake}
          />
        )}
      {listOfButton && <TableButtons listOfButton={listOfButton} />}
    </Footer>
  );
}

export default TableFooter;
