import { useRecoilState, useRecoilValue } from "recoil";
import useTime from "../../../hooks/use_time";
import Editor from "../../editor/editor";
import EditorBox from "../../editor/editor_box/editor_box";
import EditorContainer from "../../shared/editor-container";
import Input from "../../shared/input";
import Section from "../../shared/section";
import Subject from "../../shared/subject";
import { freeSetForSaveAtoms } from "../../../recoil/free_set_for_save_atoms/free_set_for_save_atoms";
import { isActiveSelector } from "../../../recoil/is_logic_state_atoms/is_logic_state_atoms";
import SSpan from "../../shared/s-span";

function SaveRead() {
  const [freeSetStateForSave, setFreeSetStateForSave] =
    useRecoilState(freeSetForSaveAtoms);
  const isActive = useRecoilValue(isActiveSelector);
  const { hourList, minutesList } = useTime();
  return (
    <Section flex={1}>
      <Subject subject="시험 설정" />
      <EditorContainer>
        <Editor filedName="조회 시작 일시" gridNumber={1}>
          {isActive ? (
            <EditorBox>
              <Input
                type="date"
                minWidth="120px"
                value={freeSetStateForSave.startDateOfInquiry}
                onChange={(event) => {
                  setFreeSetStateForSave((pre) => {
                    return {
                      ...pre,
                      startDateOfInquiry: event.currentTarget.value
                    };
                  });
                }}
              />
              <select
                name="inquiryStartHour"
                value={freeSetStateForSave.startHourOfInquiry}
                onChange={(event) => {
                  setFreeSetStateForSave((pre) => {
                    return {
                      ...pre,
                      startHourOfInquiry: event.currentTarget.value
                    };
                  });
                }}
              >
                {hourList.map((item) => (
                  <option value={item} key={`${item}_inquiryStartHour`}>
                    {item}
                  </option>
                ))}
              </select>
              <label htmlFor="">{`시`}</label>
              <select
                name="inquiryStartMinutes"
                id={"inquiryStartMinutes"}
                value={freeSetStateForSave.startMinOfInquiry}
                onChange={(event) => {
                  setFreeSetStateForSave((pre) => {
                    return {
                      ...pre,
                      startMinOfInquiry: event.currentTarget.value
                    };
                  });
                }}
              >
                {minutesList.map((item) => (
                  <option value={item} key={`${item}_inquiryStartMinutes`}>
                    {item}
                  </option>
                ))}
              </select>
              <label htmlFor="inquiryStartMinutes">{`분`}</label>
            </EditorBox>
          ) : (
            <SSpan>{`${freeSetStateForSave.startDateOfInquiry} ${freeSetStateForSave.startHourOfInquiry}시 ${freeSetStateForSave.startMinOfInquiry}분`}</SSpan>
          )}
        </Editor>
      </EditorContainer>
    </Section>
  );
}

export default SaveRead;
