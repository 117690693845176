import { useCallback, useEffect, useMemo } from "react";
import Section from "../../../shared/section";
import Table from "../../../table/table";
import TableContainer from "../../../table/table-container";
import * as ReactTable from "react-table";
import { TColumn } from "../../../../hooks/use-hide-columns";
import { Icon } from "@iconify/react";
import PlusIcon from "@iconify/icons-ic/baseline-add-box";
import MinusIcon from "@iconify/icons-mdi/minus-box";
import styled from "styled-components";
import SSpan from "../../../shared/s-span";
import Button from "../../../shared/button";
import { colors } from "../../../../styles";
import ButtonContainer from "../../../shared/button-container";
import TableTitleBar from "../../../table/table-title-bar";
import {
  Is_Pass,
  Is_Testing,
  useGetTestingProcessInfoLazyQuery
} from "../../../../__generated__/generated";

interface IProps {
  examineStartDateTime: string;
  isDevice: boolean;
  testingType: string;
  isOnOff: boolean;
}

const IconContainer = styled.div`
  display: flex;
  flex: 1;
  svg {
    color: ${props => props.theme.colors.grey};
    font-size: 24px;
    :active {
      transform: scale(0.9);
    }
    :hover {
      color: ${props => props.theme.colors.skyBlue};
    }
  }
  align-items: center;
`;

const ExpandContainer = styled.div<{ isSubRow: boolean }>`
  display: flex;
  flex: 1;
  background-color: ${props => props.theme.colors.lightGrey};
  font-weight: ${props => (props.isSubRow ? "bold" : "")};
  span {
    font-size: ${props => (props.isSubRow ? "14px" : "")};
  }
  ${SSpan} {
    margin-left: 20px;
    color: ${props => props.theme.colors.black};
  }
  border: 1px solid ${props => props.theme.colors.lightGrey};
`;

const ContentBox = styled(SSpan)<{ isPass?: boolean }>`
  font-weight: bold;
  :hover {
    color: ${props => {
      if (props.isPass) {
        return props.theme.colors.skyBlue;
      } else {
        return props.theme.colors.lightRed;
      }
    }};
  }
`;

const BtnContainer = styled(ButtonContainer)`
  justify-content: space-between;
  align-items: center;
  ${SSpan} {
    font-weight: bold;
  }
`;

function TestingStatusInfo({
  examineStartDateTime,
  isDevice,
  testingType,
  isOnOff
}: IProps) {
  const [getTestingProcessInfo, { data }] = useGetTestingProcessInfoLazyQuery({
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      if (!data.getTestingProcessInfo.ok && data.getTestingProcessInfo.error) {
        console.log(data.getTestingProcessInfo.error);
      }
    }
  });
  const columns: ReactTable.Column<any>[] = useMemo(() => {
    const width = 250;
    return [
      {
        Header: "시험 항목",
        id: "expander",
        accessor: "testingItem",
        width,
        Cell: ({ row }) => {
          const isSubRow = row.subRows.length > 0;
          return (
            <ExpandContainer isSubRow={isSubRow}>
              {isSubRow ? (
                <IconContainer
                  onClick={event => {
                    event.stopPropagation();
                    if (row.isExpanded) {
                      row.toggleRowExpanded(false);
                    } else {
                      row.toggleRowExpanded(true);
                    }
                  }}
                >
                  <Icon icon={row.isExpanded ? MinusIcon : PlusIcon} />
                  <span>{row.original.testingItem}</span>
                </IconContainer>
              ) : (
                <SSpan>{row.original.testingItem}</SSpan>
              )}
            </ExpandContainer>
          );
        }
      },
      {
        Header: "시험 내역",
        accessor: "testingDetail"
      },
      {
        Header: "시험상태",
        accessor: "status",
        Cell: ({ row }) => (
          <ContentBox
            color={
              row.original.status === "시험완료"
                ? colors.darkBlue
                : colors.darkRed
            }
            isPass={row.original.status === "시험완료" ? true : false}
          >
            {row.original.status}
          </ContentBox>
        )
      },
      {
        Header: "시험결과",
        accessor: "testingResult",
        Cell: ({ row }) => (
          <ContentBox
            color={
              row.original.testingResult === "합격"
                ? colors.darkBlue
                : colors.darkRed
            }
            isPass={row.original.testingResult === "합격" ? true : false}
          >
            {row.original.testingResult}
          </ContentBox>
        )
      }
    ];
  }, []);

  const checkResult = useCallback(
    (isTesting?: Is_Testing, status?: Is_Pass | null) => {
      if (isTesting === Is_Testing.Complete) {
        if (status !== undefined && status !== null) {
          switch (status) {
            case Is_Pass.Fail:
              return "불합격";
            case Is_Pass.Success:
              return "합격";
          }
        } else {
          return "불합격";
        }
      } else if (isTesting === Is_Testing.Ing) {
        return "진행중";
      }
      return "-";
    },
    []
  );

  const checkStatus = useCallback(
    (isTesting?: Is_Testing, status?: Is_Pass | null) => {
      if (isTesting === Is_Testing.Complete) {
        return "시험완료";
      } else if (isTesting === Is_Testing.Ing) {
        return "진행중";
      }
      if (status !== undefined || status !== null) {
        switch (status) {
          case Is_Pass.Processing:
            return "진행중";
          case Is_Pass.Fail:
            return "시험완료";
          case Is_Pass.Success:
            return "시험완료";
        }
      }
      return "진행중";
    },
    []
  );

  const list: any[] = useMemo(() => {
    const mainInfo = data?.getTestingProcessInfo.mainInfo;
    const subInfo = data?.getTestingProcessInfo.subInfo;
    const isTesting = data?.getTestingProcessInfo.isTesting;
    const main = [
      {
        testingItem: "1. 시험 설정 검사",
        testingDetail: "",
        status: "",
        testingResult: "",
        subRows: [
          {
            testingItem: "1-1 CTT서버 상태 정보 조회",
            testingDetail: "선택된 피시험 장치의 정보 및 제조사 정보 조회",
            status: checkStatus(isTesting, mainInfo?.cttServerStatus),
            testingResult: isDevice ? "합격" : "불합격"
          },
          {
            testingItem: "1-2 피시험 장치 정보 조회",
            testingDetail:
              "선택된 피시험 장치가 요청된 검사 항목을 수행 가능 여부 확인",
            status: checkStatus(isTesting, mainInfo?.pcTestDeviceInfoQuery),
            testingResult: isDevice ? "합격" : "불합격"
          },
          {
            testingItem: "1-3 검사 부합성 판단",
            testingDetail: "선택된 CTT 서버가 현재 진행중인 검사가 없는지 조회",
            status: checkStatus(
              isTesting,
              mainInfo?.inspectionComplianceAssessment
            ),
            testingResult: isDevice ? "합격" : "불합격"
          }
        ]
      },
      {
        testingItem: "2. CTT 서버 접속 상태 확인",
        testingDetail: "",
        status: "",
        testingResult: "",
        subRows: [
          {
            testingItem: "2-1 접속시험",
            testingDetail: "CTT 서버의 중앙서버에 접속 상태 확인",
            status: checkStatus(isTesting, mainInfo?.connectivityTest),
            testingResult: isOnOff ? "합격" : "불합격"
          },
          {
            testingItem: "2-2 상태시험",
            testingDetail: "CTT 서버의 실시간 상태 확인",
            status: checkStatus(isTesting, mainInfo?.statusTestResult),
            testingResult: isOnOff ? "합격" : "불합격"
          },
          {
            testingItem: "2-3 응답시험",
            testingDetail: "CTT 서버의 실시간 응답 여부 확인",
            status: checkStatus(isTesting, mainInfo?.responseTestResult),
            testingResult: isOnOff ? "합격" : "불합격"
          }
        ]
      },
      {
        testingItem: "3. 시험장치 초기화",
        testingDetail: "",
        status: "",
        testingResult: "",
        subRows: [
          {
            testingItem: "3-1 시험장치 초기화 요청",
            testingDetail: "시험장치 초기화 패킷 전송",
            status: checkStatus(
              isTesting,
              mainInfo?.testDeviceInitializationRequest
            ),
            testingResult: checkResult(
              isTesting,
              mainInfo?.testDeviceInitializationRequest
            )
          },
          {
            testingItem: "3-2 시험장치 초기화 응답",
            testingDetail: "시험장치 초기화 응답 확인",
            status: checkStatus(
              isTesting,
              mainInfo?.testDeviceInitializationResponse
            ),
            testingResult: checkResult(
              isTesting,
              mainInfo?.testDeviceInitializationResponse
            )
          },
          {
            testingItem: "3-3 시험 설정값 확인",
            testingDetail: "피시험 열량계 동작 모드 및 설정값 검사",
            status: checkStatus(
              isTesting,
              mainInfo?.testConfigurationValidation
            ),
            testingResult: checkResult(
              isTesting,
              mainInfo?.testConfigurationValidation
            )
          }
        ]
      }
    ];

    let sub: any[] = [];
    let sub2: any[] = [];

    if (subInfo && testingType !== "환경테스트") {
      const total = subInfo.length;
      sub = subInfo.map((item, index) => {
        const order = index + 1;
        const subRows = [
          {
            testingItem: `4-${order}-1 데이터 요청 전송`,
            testingDetail: "CTT서버에서 시험장치로 열량계 데이터 요청",
            status: checkStatus(isTesting, item?.dataRequestTransmission),
            testingResult: checkResult(isTesting, item?.dataRequestTransmission)
          },
          {
            testingItem: `4-${order}-2 RTU시뮬레이터 데이터 요청`,
            testingDetail: "피시험 열량계로 데이터 요청 진행",
            status: checkStatus(isTesting, item?.rtuSimulatorDataRequest),
            testingResult: checkResult(isTesting, item?.rtuSimulatorDataRequest)
          },
          {
            testingItem: `4-${order}-3 피시함 열량계 데이터 응답 확인`,
            testingDetail:
              "열량계 시뮬레이터에서 RTU시뮬레이터로 데이터 전송 여부 확인",
            status: checkStatus(
              isTesting,
              item?.calorimeterDataResponseConfirmation
            ),
            testingResult: checkResult(
              isTesting,
              item?.calorimeterDataResponseConfirmation
            )
          },
          {
            testingItem: `4-${order}-4 시험장치 RTU의 데이터 수신 확인`,
            testingDetail: "시험장치 RTU의 측정 결과 수신 여부",
            status: checkStatus(isTesting, item?.dataResponseTransmission),
            testingResult: checkResult(
              isTesting,
              item?.dataResponseTransmission
            )
          },
          {
            testingItem: `4-${order}-5 파싱 시험`,
            testingDetail: "응답 패킷 파싱 시험",
            status: checkStatus(isTesting, item?.parsingTest),
            testingResult: checkResult(isTesting, item?.parsingTest)
          },
          {
            testingItem: `4-${order}-6 패킷 시험`,
            testingDetail: "수신 패킷과 시뮬레이터 패킷 부합성 시험",
            status: checkStatus(isTesting, item?.frameTypeTest),
            testingResult: checkResult(isTesting, item?.frameTypeTest)
          },
          {
            testingItem: `4-${order}-7 제어 필드 시험`,
            testingDetail: "C-Field 시험",
            status: checkStatus(isTesting, item?.controlFieldTest),
            testingResult: checkResult(isTesting, item?.controlFieldTest)
          },
          {
            testingItem: `4-${order}-8 주소 필드 시험`,
            testingDetail: "A-Field 시험",
            status: checkStatus(isTesting, item?.addressFieldTest),
            testingResult: checkResult(isTesting, item?.addressFieldTest)
          },
          {
            testingItem: `4-${order}-9 제어 정보 필드 시험`,
            testingDetail: "CI-Field 시험",
            status: checkStatus(isTesting, item?.controlInfoFieldTest),
            testingResult: checkResult(isTesting, item?.controlInfoFieldTest)
          },
          {
            testingItem: `4-${order}-10 체크섬 시험`,
            testingDetail: "CRC 체크섬 시험",
            status: checkStatus(isTesting, item?.checksumTest),
            testingResult: checkResult(isTesting, item?.checksumTest)
          },
          {
            testingItem: `4-${order}-11 유효성 시험`,
            testingDetail: "측정 결과 및 시뮬레이터 데이터 비교 및 부합성 시험",
            status: checkStatus(isTesting, item?.validationTest),
            testingResult: checkResult(isTesting, item?.validationTest)
          },
          {
            testingItem: `4-${order}-12 결과 저장 및 업데이트`,
            testingDetail: "결과 저장 및 데이터 베이스 업데이트",
            status: checkStatus(isTesting, item?.saveResultAndUpdate),
            testingResult: checkResult(isTesting, item?.saveResultAndUpdate)
          }
        ];
        return {
          testingItem: `4-${order}. 데이터 전송 및 수신(${index + 1}/${total})`,
          testingDetail: "",
          status: "",
          testingResult: "",
          subRows
        };
      });
    }

    if (testingType !== "환경테스트") {
      sub2 = [
        {
          testingItem: "5. 시험 종료",
          testingDetail: "",
          status: "",
          testingResult: "",
          subRows: [
            {
              testingItem: "5-1 수신 데이터 확인",
              testingDetail: "수신 데이터 회차 확인 및 통계 정보 업데이트",
              status: checkStatus(
                isTesting,
                mainInfo?.receivedDataVerification
              ),
              testingResult: checkResult(
                isTesting,
                mainInfo?.receivedDataVerification
              )
            },
            {
              testingItem: "5-2 시험장치 초기화",
              testingDetail: "시험장치 초기화 패킷 전송",
              status: checkStatus(isTesting, mainInfo?.initializeTestDevice),
              testingResult: checkResult(
                isTesting,
                mainInfo?.initializeTestDevice
              )
            },
            {
              testingItem: "5-3 시험장치 초기화 상태 확인",
              testingDetail: "시험장치 초기화 응답 확인",
              status: checkStatus(
                isTesting,
                mainInfo?.testDeviceInitializationStatus
              ),
              testingResult: checkResult(
                isTesting,
                mainInfo?.testDeviceInitializationStatus
              )
            },
            {
              testingItem: "5-4 CTT 서버 정보 업데이트",
              testingDetail: "CTT 서버 상태 정보 업데이트",
              status: checkStatus(
                isTesting,
                mainInfo?.updateCTTServerInformation
              ),
              testingResult: checkResult(
                isTesting,
                mainInfo?.updateCTTServerInformation
              )
            },
            {
              testingItem: "5-5 검사 결과 작성",
              testingDetail: "검사 결과지 작성",
              status: checkStatus(isTesting, mainInfo?.writeInspectionResults),
              testingResult: isTesting === Is_Testing.Complete ? "합격" : "-"
            },
            {
              testingItem: "5-6 데이터 베이스 업데이트",
              testingDetail: "최종 업데이트 및 저장 상태 확인",
              status: checkStatus(isTesting, mainInfo?.updateDatabase),
              testingResult: isTesting === Is_Testing.Complete ? "합격" : "-"
            }
          ]
        }
      ];
    }

    const newList = [...main, ...sub, ...sub2];

    return newList;
  }, [data, checkStatus, checkResult, isDevice, testingType, isOnOff]);

  const {
    prepareRow,
    visibleColumns,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    columns: columnsOfStatusInfo
    // selectedFlatRows,
    // toggleAllRowsExpanded
  } = ReactTable.useTable<any>(
    {
      columns,
      data: list
    },
    ReactTable.useExpanded,
    ReactTable.useRowSelect,
    ReactTable.useBlockLayout,
    ReactTable.useColumnOrder
  );

  // const handleSelectRow = useCallback((row?: IRow<any>) => {}, []);

  // const selectedRow: ReactTable.Row<any> | undefined = useMemo(() => {
  //   return selectedFlatRows.at(-1);
  // }, [selectedFlatRows]);

  useEffect(() => {
    getTestingProcessInfo({
      variables: {
        examineStartDateTime
      }
    });
  }, [getTestingProcessInfo, examineStartDateTime]);

  return (
    <Section>
      <TableContainer>
        <TableTitleBar
          title="열량계 시험 진행 정보"
          columns={columnsOfStatusInfo as TColumn<any>[]}
        />
        <Table<any>
          title="열량계 시험 진행 정보"
          prepareRow={prepareRow}
          visibleColumns={visibleColumns}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          listOfFlexForHeader={["시험 내역"]}
          isLastFlex={false}
        />
      </TableContainer>
      <BtnContainer>
        <SSpan color={colors.darkBlue}>{`${
          data?.getTestingProcessInfo.isTesting === Is_Testing.Complete
            ? "검사완료"
            : "검사 진행중"
        }`}</SSpan>
        <Button
          maxWidth="50px"
          backgroundColor={colors.darkBlue}
          hoverBackgroundColor={colors.skyBlue}
          onClick={() => {
            window.close();
          }}
        >{`닫기`}</Button>
      </BtnContainer>
    </Section>
  );
}

export default TestingStatusInfo;
