import { useRecoilValue } from "recoil";
import { Examine_Method } from "../../../__generated__/generated";
import RtuLongTermExamination from "../rtu_long_term_examination/rtu_long_term_examination";
import RtuRealTimeRead from "../rtu_real_time_read/rtu_real_time_read";
import TestingSetting from "../testing_setting/testing_setting";
import { freeSetForSaveAtoms } from "../../../recoil/free_set_for_save_atoms/free_set_for_save_atoms";

function RtuTextExtraForm() {
  const freeSetStateForSave = useRecoilValue(freeSetForSaveAtoms);
  switch (freeSetStateForSave.testingType) {
    case Examine_Method.EnvironmentTest:
      return <TestingSetting />;
    case Examine_Method.RealTimeInspection:
      return <RtuRealTimeRead />;
    case Examine_Method.DurabilityTest:
      return <RtuLongTermExamination />;
    default:
      return <TestingSetting />;
  }
}

export default RtuTextExtraForm;
