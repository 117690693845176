import { Helmet } from "react-helmet-async";

interface IProps {
  title: string;
}

function PageTitle({ title }: IProps) {
  return (
    <Helmet>
      <title>적산열량계 | {title}</title>
    </Helmet>
  );
}

export default PageTitle;
