import { forwardRef } from "react";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CheckboxTitle = styled.label`
  margin-right: 5px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
  margin-left: 5px;
`;
export const CheckMark = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #eee;
  border-radius: 4px;
  ::after {
    content: "";
    position: absolute;
    display: none;
    left: 35%;
    top: 20%;
    width: 3px;
    height: 8px;
    border: ${(props) =>
      props.color ? "1px solid white" : "1px solid #369ef4"};
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const CheckBoxContainer = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
`;

export const CheckBox = styled.input`
  position: absolute;
  margin: 0;
  opacity: 0;
  left: 0;
  top: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 999999999999;
  :disabled ~ ${CheckMark} {
    background-color: #636e72;
  }
  :checked ~ ${CheckMark} {
    ::after {
      display: block;
    }
    background-color: ${(props) => (props.color ? props.color : "white")};
  }
`;

interface IProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked?: boolean;
  htmlFor?: string;
  title?: string;
  color?: string;
  name?: string;
  checked?: boolean;
}

const CheckBoxUi = (props: IProps, ref: any) => {
  const { htmlFor, title, color, name } = props;
  return (
    <Container>
      <CheckBoxContainer>
        <CheckBox
          type="checkbox"
          id={htmlFor}
          color={color}
          {...props}
          ref={ref}
        />
        <CheckMark color={color} />
      </CheckBoxContainer>
      <CheckboxTitle htmlFor={htmlFor}>{title || name}</CheckboxTitle>
    </Container>
  );
};

export default forwardRef(CheckBoxUi);
