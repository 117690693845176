import styled from "styled-components";

const TableHeader = styled.div<{
  isOver?: boolean;
  isFlex?: boolean;
  isLastFlex?: boolean;
}>`
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0.4rem 0px;
  cursor: grab;
  background-color: ${(props) => {
    if (props.isOver === false) {
      return props.theme.colors.tomato;
    }
  }};
  flex: ${(props) => (props.isFlex ? "1" : "none")};
`;

export default TableHeader;
