import styled from "styled-components";
import Tab from "../shared/tab";

export interface ITab {
  title: string;
}

interface IProps {
  list: ITab[];
  selectedTab: string;
  handleTabSelected: (tab: string) => void;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  background-color: ${(props) => props.theme.colors.lightGrey};
  padding: 0px 0px 0px 8px;
  min-height: 52px;
  max-height: 52px;
`;

const Section = styled.section`
  display: flex;
  flex: 1;
  gap: 10px;
  align-items: flex-end;
`;

function Tabs({ list, selectedTab, handleTabSelected }: IProps) {
  return (
    <Container>
      <Section>
        {list.map((item) => (
          <Tab
            title={item.title}
            key={`${item.title}`}
            selectedTab={selectedTab}
            handleTabSelected={handleTabSelected}
          />
        ))}
      </Section>
    </Container>
  );
}

export default Tabs;
