import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

/** 시험관리 아이템 추가 및 수정 요청 결과 */
export type AddOrEditTestingManagerItemOutput = {
  __typename?: 'AddOrEditTestingManagerItemOutput';
  error?: Maybe<Scalars['String']['output']>;
  ok: Scalars['Boolean']['output'];
};

/** 연결 CTT명 검색 타입 */
export enum CttItemSearchType {
  AsNo = 'AS_NO',
  CttComName = 'CTT_COM_NAME',
  CttComPort = 'CTT_COM_PORT',
  CttId = 'CTT_ID',
  CttIp = 'CTT_IP',
  CttMac = 'CTT_MAC',
  CttMemo = 'CTT_MEMO',
  CttName = 'CTT_NAME',
  CttOnOff = 'CTT_ON_OFF',
  CttVersion = 'CTT_VERSION',
  Descr = 'DESCR'
}

/** 연결 CTT 타입 */
export type CttItemEntity = {
  __typename?: 'CttItemEntity';
  /** 번호 */
  asNo: Scalars['Float']['output'];
  /** CTT computer name */
  cttComName: Scalars['String']['output'];
  /** CTT computer port */
  cttComPort: Scalars['String']['output'];
  /** CTT IP */
  cttIp: Scalars['String']['output'];
  /** CTT Mac */
  cttMac: Scalars['String']['output'];
  /** CTT name */
  cttName?: Maybe<Scalars['String']['output']>;
  /** CTT Version */
  cttVersion: Scalars['String']['output'];
  /** CTT memo */
  cttmemo: Scalars['String']['output'];
  /** CTT ON OFF */
  cttonoff: Scalars['String']['output'];
  /** Endpoint ID */
  endpointId: Scalars['Float']['output'];
};

/** 시험프리셋 정보 삭제 요청 결과 */
export type DeleteFreeSetItemOutput = {
  __typename?: 'DeleteFreeSetItemOutput';
  error?: Maybe<Scalars['String']['output']>;
  ok: Scalars['Boolean']['output'];
};

/** 시험이력 삭제 요청 결과 */
export type DeleteResultHistoryOutput = {
  __typename?: 'DeleteResultHistoryOutput';
  error?: Maybe<Scalars['String']['output']>;
  ok: Scalars['Boolean']['output'];
};

/** 시험관리 아이템 삭제 요청 결과 */
export type DeleteTestingManagerItemOutput = {
  __typename?: 'DeleteTestingManagerItemOutput';
  error?: Maybe<Scalars['String']['output']>;
  ok: Scalars['Boolean']['output'];
};

/** 상세 시험결과 검색 타입 */
export enum DetailTestingResultSearchType {
  ChecksumInspectionResults = 'CHECKSUM_INSPECTION_RESULTS',
  ExamineNumber = 'EXAMINE_NUMBER',
  IntegrityTestResults = 'INTEGRITY_TEST_RESULTS',
  InSDateTime = 'IN_S_DATE_TIME'
}

/**
 * 시험방식 타입: {
 *     1 : 환경테스트,
 *     2 : 실시간검침,
 *     3 : 내구성 시험,
 *     4 : 저장검침
 *   }
 */
export enum Examine_Method {
  DurabilityTest = 'DURABILITY_TEST',
  EnvironmentTest = 'ENVIRONMENT_TEST',
  RealTimeInspection = 'REAL_TIME_INSPECTION',
  StorageInspection = 'STORAGE_INSPECTION'
}

/**
 * 시험대상 타입: {
 *     CALORIMETER: 1,
 *     RTU: 2
 *   }
 */
export enum Examine_Target {
  Calorimeter = 'CALORIMETER',
  Rtu = 'RTU'
}

/** 상세 시험결과 Entity */
export type EachTestingResultEntity = {
  __typename?: 'EachTestingResultEntity';
  /** 번호 */
  examineNumber: Scalars['Float']['output'];
  /** 시험시 프리셋 값 JSON */
  examineSetting?: Maybe<Scalars['String']['output']>;
  /** 측정값 JSON */
  resultValueExamineResults?: Maybe<Scalars['String']['output']>;
};

export type FieldSort = {
  name: Scalars['String']['input'];
  sort: SortColumType;
};

/** 연결 CTT명 목록 요청 결과 */
export type GetCttItemOutput = {
  __typename?: 'GetCTTItemOutput';
  error?: Maybe<Scalars['String']['output']>;
  list: Array<CttItemEntity>;
  ok: Scalars['Boolean']['output'];
};

/** 연결 CTT명 목록 카운트 요청 결과 */
export type GetCountCttItemOutput = {
  __typename?: 'GetCountCTTItemOutput';
  /** 연결 CTT명 목록 카운트 */
  count?: Maybe<Scalars['Float']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  ok: Scalars['Boolean']['output'];
};

/** 시험관리 리스트 카운트 요청 결과 */
export type GetCountTestingManagerItemOutput = {
  __typename?: 'GetCountTestingManagerItemOutput';
  /** 검정상세정보 카운트 */
  count?: Maybe<Scalars['Float']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  ok: Scalars['Boolean']['output'];
};

/** 상세 시험결과 목록 요청 결과 */
export type GetDetailTestingResultOutput = {
  __typename?: 'GetDetailTestingResultOutput';
  error?: Maybe<Scalars['String']['output']>;
  /** 상세 시험 결과 목록 */
  list?: Maybe<Array<TestingDetailResultEntity>>;
  ok: Scalars['Boolean']['output'];
};

/** 무결성 또는 체크섬 또는 유효성검사 결과 요청 */
export type GetEachTestingResultOutput = {
  __typename?: 'GetEachTestingResultOutput';
  error?: Maybe<Scalars['String']['output']>;
  /** 무결성, 체크섬 그리고 유효성 검사 결과 */
  info?: Maybe<Array<EachTestingResultEntity>>;
  ok: Scalars['Boolean']['output'];
};

/** 시험프리셋 아이템 요청 결과 */
export type GetTestingFreeSetItemOutput = {
  __typename?: 'GetTestingFreeSetItemOutput';
  error?: Maybe<Scalars['String']['output']>;
  item?: Maybe<TestingFreeSetEntity>;
  ok: Scalars['Boolean']['output'];
};

/** 시험프리셋 목록 요청 결과 */
export type GetTestingFreeSetListOutput = {
  __typename?: 'GetTestingFreeSetListOutput';
  error?: Maybe<Scalars['String']['output']>;
  list: Array<TestingFreeSetNameEntity>;
  ok: Scalars['Boolean']['output'];
};

/** 시험정보 요청 결과 */
export type GetTestingInfoOutput = {
  __typename?: 'GetTestingInfoOutput';
  error?: Maybe<Scalars['String']['output']>;
  /** 시험정보 */
  info?: Maybe<TestingInfoEntity>;
  ok: Scalars['Boolean']['output'];
};

/** 전체시험 관리명 목록 요청 결과 */
export type GetTestingManagementNameListOutput = {
  __typename?: 'GetTestingManagementNameListOutput';
  error?: Maybe<Scalars['String']['output']>;
  /** 전체시험 관리명 목록 */
  list?: Maybe<Array<TestingManagementNameEntity>>;
  ok: Scalars['Boolean']['output'];
};

/** 시험관리 리스트 요청 결과 */
export type GetTestingManagerItemOutput = {
  __typename?: 'GetTestingManagerItemOutput';
  error?: Maybe<Scalars['String']['output']>;
  /** 시험관리 리스트 */
  list?: Maybe<Array<TestingManagerItemEntity>>;
  ok: Scalars['Boolean']['output'];
};

/** 시험진행 정보 요청 결과 */
export type GetTestingProcessInfoOutput = {
  __typename?: 'GetTestingProcessInfoOutput';
  error?: Maybe<Scalars['String']['output']>;
  /** 시험진행 정보 상태 */
  isTesting: Is_Testing;
  /** Main 시험진행 정보 */
  mainInfo?: Maybe<MainTestingProcessEntity>;
  ok: Scalars['Boolean']['output'];
  /** Sub 시험진행 정보 */
  subInfo?: Maybe<Array<SubTestingProcessEntity>>;
};

/** 시험결과 목록 요청 결과 */
export type GetTestingResultCountOutput = {
  __typename?: 'GetTestingResultCountOutput';
  /** 시험결과 카운트 */
  count?: Maybe<Scalars['Float']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  ok: Scalars['Boolean']['output'];
};

/** 시험결과 목록 요청 결과 */
export type GetTestingResultOutput = {
  __typename?: 'GetTestingResultOutput';
  error?: Maybe<Scalars['String']['output']>;
  /** 시험결과 목록 */
  list?: Maybe<Array<TestingResultEntity>>;
  ok: Scalars['Boolean']['output'];
};

export type IEachTestingResult = {
  /** 선택번호 */
  examineNumber: Scalars['Float']['input'];
  /** 수행일자 */
  examineStartDateTime?: InputMaybe<Scalars['String']['input']>;
  /** 무결성, 체크섬 그리고 유효성 검사 타입 */
  testingType: Testing_Type;
};

/** 시험진행 정보 통과 타입 */
export enum Is_Pass {
  Fail = 'FAIL',
  Processing = 'PROCESSING',
  Success = 'SUCCESS'
}

/** 시험진행 정보 상태 타입 */
export enum Is_Testing {
  Complete = 'COMPLETE',
  Ing = 'ING'
}

/** 사용자 로그인 요청 결과 */
export type LoginOutput = {
  __typename?: 'LoginOutput';
  error?: Maybe<Scalars['String']['output']>;
  ok: Scalars['Boolean']['output'];
  /** token */
  token?: Maybe<Scalars['String']['output']>;
};

/** Main 시험수행 정보 Entity */
export type MainTestingProcessEntity = {
  __typename?: 'MainTestingProcessEntity';
  /** 2-1 접속시험 - CTT 서버의 중앙서버에 접속 상태 확인 */
  connectivityTest?: Maybe<Is_Pass>;
  /** 1-1 CTT서버 상태 정보 조회 - 선택된 피시험 장치의 정보 및 제조사 정보 조회 */
  cttServerStatus?: Maybe<Is_Pass>;
  /** 5-2 시험장치 초기화 - 시험장치 초기화 패킷 전송 */
  initializeTestDevice?: Maybe<Is_Pass>;
  /** 1-3 검사 부합성 판단 - 선택된 CTT 서버가 현재 진행중인 검사가 없는지 조회 */
  inspectionComplianceAssessment?: Maybe<Is_Pass>;
  /** 1-2 피시험 장치 정보 조회 - 선택된 피시험 장치가 요청된 검사 항목을 수행 가능 여부 확인 */
  pcTestDeviceInfoQuery?: Maybe<Is_Pass>;
  /** 5-1 수신 데이터 확인 - 수신 데이터 회차 확인 및 통계 정보 업데이트 */
  receivedDataVerification?: Maybe<Is_Pass>;
  /** 2-3 응답시험 - CTT 서버의 실시간 응답 여부 확인 */
  responseTestResult?: Maybe<Is_Pass>;
  /** 2-2 상태시험 - CTT 서버의 실시간 상태 확인 */
  statusTestResult?: Maybe<Is_Pass>;
  /** 3-3 시험 설정값 확인 - 피시험 열량계 동작 모드 및 설정값 검사 */
  testConfigurationValidation?: Maybe<Is_Pass>;
  /** 3-1 시험장치 초기화 요청 - 시험장치 초기화 패킷 전송 */
  testDeviceInitializationRequest?: Maybe<Is_Pass>;
  /** 3-2 시험장치 초기화 응답 - 시험장치 초기화 응답 확인 */
  testDeviceInitializationResponse?: Maybe<Is_Pass>;
  /** 5-3 시험장치 초기화 상태 확인 - 시험장치 초기화 응답 확인 */
  testDeviceInitializationStatus?: Maybe<Is_Pass>;
  /** 5-4 CTT 서버 정보 업데이트 - CTT 서버 상태 정보 업데이트 */
  updateCTTServerInformation?: Maybe<Is_Pass>;
  /** 5-6 데이터 베이스 업데이트 - 최종 업데이트 및 저장 상태 확인 */
  updateDatabase?: Maybe<Is_Pass>;
  /** 5-5 검사 결과 작성 - 검사 결과지 작성 */
  writeInspectionResults?: Maybe<Is_Pass>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addOrEditTestingManagerItem: AddOrEditTestingManagerItemOutput;
  deleteFreeSetItem: DeleteFreeSetItemOutput;
  deleteResultHistory: DeleteResultHistoryOutput;
  deleteTestingManagerItem: DeleteTestingManagerItemOutput;
  saveFreeSetItem: SaveFreeSetItemOutput;
  testRequestTransmission: TestRequestTransmissionOutput;
};


export type MutationAddOrEditTestingManagerItemArgs = {
  cttName?: InputMaybe<Scalars['String']['input']>;
  descr?: InputMaybe<Scalars['String']['input']>;
  endpointId?: InputMaybe<Scalars['Float']['input']>;
  examineName: Scalars['String']['input'];
  examinePort: Scalars['String']['input'];
  manufacturerCodeName: Scalars['String']['input'];
  mngNumber: Scalars['String']['input'];
  modelCode: Scalars['String']['input'];
  personInChargeName?: InputMaybe<Scalars['String']['input']>;
  personInChargeTel?: InputMaybe<Scalars['String']['input']>;
  propertyNumber: Scalars['String']['input'];
  propertyVersion: Scalars['String']['input'];
  rtuIp: Scalars['String']['input'];
  rtuMeasurementMinCycle?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteFreeSetItemArgs = {
  examineFreeSetName: Array<Scalars['String']['input']>;
};


export type MutationDeleteResultHistoryArgs = {
  examineStartDateTimeList: Array<Scalars['String']['input']>;
};


export type MutationDeleteTestingManagerItemArgs = {
  examineName: Array<Scalars['String']['input']>;
};


export type MutationSaveFreeSetItemArgs = {
  examineFreeSetName: Scalars['String']['input'];
  examineMethod: Examine_Method;
  examineSetting: Scalars['String']['input'];
  examineTarget: Examine_Target;
};


export type MutationTestRequestTransmissionArgs = {
  endpointId?: InputMaybe<Scalars['Float']['input']>;
  examineCount?: InputMaybe<Scalars['Float']['input']>;
  examineFreeSetName: Scalars['String']['input'];
  examineMethod: Examine_Method;
  examineName: Scalars['String']['input'];
  examineTarget: Examine_Target;
  rtuMeasurementMinCycle?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
  __typename?: 'Query';
  getCountCTTItem: GetCountCttItemOutput;
  getCountTestingManagerItem: GetCountTestingManagerItemOutput;
  getCttItemList: GetCttItemOutput;
  getDetailTestingResult: GetDetailTestingResultOutput;
  getDetailTestingResultCount: GetDetailTestingResultCountOutput;
  getEachTestingResult: GetEachTestingResultOutput;
  getFreeSetItem: GetTestingFreeSetItemOutput;
  getTestingFreeSetList: GetTestingFreeSetListOutput;
  getTestingInfo: GetTestingInfoOutput;
  getTestingManagementNameList: GetTestingManagementNameListOutput;
  getTestingManagerItemList: GetTestingManagerItemOutput;
  getTestingProcessInfo: GetTestingProcessInfoOutput;
  getTestingResult: GetTestingResultOutput;
  getTestingResultCount: GetTestingResultCountOutput;
  login: LoginOutput;
};


export type QueryGetCountCttItemArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']['input']>;
  searchType: CttItemSearchType;
  searchValue?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryGetCountTestingManagerItemArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']['input']>;
  searchType: TestingManagerSearchType;
  searchValue?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryGetCttItemListArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']['input']>;
  searchType: CttItemSearchType;
  searchValue?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryGetDetailTestingResultArgs = {
  examineStartDateTime?: InputMaybe<Scalars['String']['input']>;
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']['input']>;
  searchType: DetailTestingResultSearchType;
  searchValue?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryGetDetailTestingResultCountArgs = {
  examineStartDateTime?: InputMaybe<Scalars['String']['input']>;
  searchType: DetailTestingResultSearchType;
  searchValue?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetEachTestingResultArgs = {
  eachTestingResult: Array<IEachTestingResult>;
};


export type QueryGetFreeSetItemArgs = {
  examineFreeSetName: Scalars['String']['input'];
};


export type QueryGetTestingInfoArgs = {
  examineStartDateTime?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetTestingManagerItemListArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']['input']>;
  searchType: TestingManagerSearchType;
  searchValue?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryGetTestingProcessInfoArgs = {
  examineStartDateTime?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetTestingResultArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']['input']>;
  searchType: TestingResultSearchType;
  searchValue?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryGetTestingResultCountArgs = {
  searchType: TestingResultSearchType;
  searchValue?: InputMaybe<Scalars['String']['input']>;
};


export type QueryLoginArgs = {
  token: Scalars['String']['input'];
};

/** 시험프리셋 정보 저장 요청 결과 */
export type SaveFreeSetItemOutput = {
  __typename?: 'SaveFreeSetItemOutput';
  error?: Maybe<Scalars['String']['output']>;
  ok: Scalars['Boolean']['output'];
};

/** 컬럼 정렬 타입 */
export enum SortColumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Main 시험수행 정보 Entity */
export type SubTestingProcessEntity = {
  __typename?: 'SubTestingProcessEntity';
  /** 4-1-8 주소 필드 시험 - A-Field 시험 */
  addressFieldTest?: Maybe<Is_Pass>;
  /** 4-1-3 피시함 열량계 데이터 응답 확인 - 열량계 시뮬레이터에서 RTU시뮬레이터로 데이터 전송 여부 확인 */
  calorimeterDataResponseConfirmation?: Maybe<Is_Pass>;
  /** 4-1-10 체크섬 시험 - CRC 체크섬 시험 */
  checksumTest?: Maybe<Is_Pass>;
  /** 4-1-7 제어 필드 시험 - C-Field 시험 */
  controlFieldTest?: Maybe<Is_Pass>;
  /** 4-1-9 제어 정보 필드 시험 - CI-Field 시험 */
  controlInfoFieldTest?: Maybe<Is_Pass>;
  /** 4-1-1 데이터 요청 전송 - CTT서버에서 시험장치로 열량계 데이터 요청 */
  dataRequestTransmission?: Maybe<Is_Pass>;
  /** 4-1-4 피시험RTU의 데이터 수신 확인 - 피시험RTU 측정 결과 수신 여부 */
  dataResponseTransmission?: Maybe<Is_Pass>;
  /** 4-1-6 패킷 시험 - 수신 패킷과 시뮬레이터 패킷 부합성 시험 */
  frameTypeTest?: Maybe<Is_Pass>;
  /** 4-1-5 파싱 시험 - 응답 패킷 파싱 시험 */
  parsingTest?: Maybe<Is_Pass>;
  /** 4-1-2 RTU시뮬레이터 데이터 요청 - 피시험 열량계로 데이터 요청 진행 */
  rtuSimulatorDataRequest?: Maybe<Is_Pass>;
  /** 4-1-12 결과 저장 및 업데이트 - 결과 저장 및 데이터 베이스 업데이트 */
  saveResultAndUpdate?: Maybe<Is_Pass>;
  /** 4-1-11 유효성 시험 - 측정 결과 및 시뮬레이터 데이터 비교 및 부합성 시험 */
  validationTest?: Maybe<Is_Pass>;
};

/** 무결성, 체크섬 그리고 유효성 검사 타입 */
export enum Testing_Type {
  CheckSum = 'CHECK_SUM',
  Effectiveness = 'EFFECTIVENESS',
  Integrity = 'INTEGRITY'
}

/** 시험 요청 전송 결과 */
export type TestRequestTransmissionOutput = {
  __typename?: 'TestRequestTransmissionOutput';
  error?: Maybe<Scalars['String']['output']>;
  ok: Scalars['Boolean']['output'];
};

/** 상세 시험결과 Entity */
export type TestingDetailResultEntity = {
  __typename?: 'TestingDetailResultEntity';
  /** 체크섬검사 */
  checksumInspectionResults?: Maybe<Scalars['String']['output']>;
  /** 구분(입력값, 측정값) 열량계는 측정값만 존재 */
  dataType: Scalars['String']['output'];
  /** 번호 */
  examineNumber: Scalars['Float']['output'];
  /** 서버변경 JSON값 */
  examineResults?: Maybe<Scalars['String']['output']>;
  /** 수신시간 */
  inSDateTime?: Maybe<Scalars['String']['output']>;
  /** 입력값 JSON값 */
  inputResults?: Maybe<Scalars['String']['output']>;
  /** 무결성검사 */
  integrityTestResults?: Maybe<Scalars['String']['output']>;
  /** 보기 */
  none: Scalars['String']['output'];
  /** 바이너리 원본값 */
  resultValuePackets?: Maybe<Array<Scalars['String']['output']>>;
  /** 보기 */
  view: Scalars['String']['output'];
};

/** 선택된 프리셋 Entity */
export type TestingFreeSetEntity = {
  __typename?: 'TestingFreeSetEntity';
  /** 시험 방식 */
  examineMethod?: Maybe<Examine_Method>;
  /** 시험 설정 */
  examineSetting?: Maybe<Scalars['String']['output']>;
  /** 시험대상 */
  examineTarget?: Maybe<Examine_Target>;
};

/** 전체시험 관리명 Entity */
export type TestingFreeSetNameEntity = {
  __typename?: 'TestingFreeSetNameEntity';
  /** 시험 프리셋 이름 */
  examineFreeSetName: Scalars['String']['output'];
  /** 시험방식 */
  examineMethod?: Maybe<Examine_Method>;
  /** 시험 설정 */
  examineSetting?: Maybe<Scalars['String']['output']>;
  /** 시험대상 */
  examineTarget?: Maybe<Examine_Target>;
};

/** 시험 정보 Entity */
export type TestingInfoEntity = {
  __typename?: 'TestingInfoEntity';
  /** 비고 */
  descr: Scalars['String']['output'];
  /** 시험 관리명 */
  examineName?: Maybe<Scalars['String']['output']>;
  /** 시험결과JSON(수신일시, 결과수신, 종료구분, 에러코드) */
  examineResultJson?: Maybe<Scalars['String']['output']>;
  /** 시험정보 */
  examineSetting?: Maybe<Scalars['String']['output']>;
  /** 수행일자 */
  examineStartDateTime?: Maybe<Scalars['String']['output']>;
  /** 관리번호 */
  manufacturerCodeName?: Maybe<Scalars['String']['output']>;
  /** 제조사코드 */
  mngNumber?: Maybe<Scalars['String']['output']>;
  /** 구분코드 */
  modelCode?: Maybe<Scalars['Float']['output']>;
  /** 담당자 */
  personInChargeName: Scalars['String']['output'];
  /** 담당자연락처 */
  personInChargeTel: Scalars['String']['output'];
  /** 기물번호 */
  propertyNumber?: Maybe<Scalars['Float']['output']>;
  /** 버전 */
  propertyVersion?: Maybe<Scalars['Float']['output']>;
};

/** 전체시험 관리명 Entity */
export type TestingManagementNameEntity = {
  __typename?: 'TestingManagementNameEntity';
  /** 시험관리명 */
  examineName: Scalars['String']['output'];
};

/** 시험 관리 정보 타입 */
export type TestingManagerItemEntity = {
  __typename?: 'TestingManagerItemEntity';
  /** 번호 */
  asNo: Scalars['Float']['output'];
  /** 연결 CTT명 */
  cttName?: Maybe<Scalars['String']['output']>;
  /** CTT서버 상태 */
  cttOnOff: Scalars['String']['output'];
  /** 비고 */
  descr: Scalars['String']['output'];
  /** endpoint id */
  endpointId?: Maybe<Scalars['Float']['output']>;
  /** 시험관리명 */
  examineName: Scalars['String']['output'];
  /** 시험장치 연결포트 */
  examinePort: Scalars['String']['output'];
  /** 제조사코드 */
  manufacturerCodeName: Scalars['String']['output'];
  /** 관리번호 */
  mngNumber: Scalars['String']['output'];
  /** 구분코드 */
  modelCode: Scalars['String']['output'];
  /** 모델구분 */
  modelCodeName: Scalars['String']['output'];
  /** 담당자 */
  personInChargeName: Scalars['String']['output'];
  /** 담당자연락처 */
  personInChargeTel: Scalars['String']['output'];
  /** 기물번호 */
  propertyNumber: Scalars['String']['output'];
  /** 버전 */
  propertyVersion: Scalars['String']['output'];
  /** RTU연결 상태 */
  rtuConnectStatus: Scalars['String']['output'];
  /** RTU IP */
  rtuIp: Scalars['String']['output'];
  /** RTU검사주기 */
  rtuMeasurementMinCycle: Scalars['String']['output'];
};

/** 시험관리 목록 검색 타입 */
export enum TestingManagerSearchType {
  AsNo = 'AS_NO',
  CttName = 'CTT_NAME',
  CttOnOff = 'CTT_ON_OFF',
  Descr = 'DESCR',
  ExamineName = 'EXAMINE_NAME',
  ExaminePort = 'EXAMINE_PORT',
  ManufacturerCodeName = 'MANUFACTURER_CODE_NAME',
  MngNumber = 'MNG_NUMBER',
  ModelCode = 'MODEL_CODE',
  ModelCodeName = 'MODEL_CODE_NAME',
  PersonInChangeName = 'PERSON_IN_CHANGE_NAME',
  PersonInChangeTel = 'PERSON_IN_CHANGE_TEL',
  PropertyNumber = 'PROPERTY_NUMBER',
  PropertyVersion = 'PROPERTY_VERSION',
  RtuConnectStatus = 'RTU_CONNECT_STATUS',
  RtuIp = 'RTU_IP',
  RtuMeasurementMinCycle = 'RTU_MEASUREMENT_MIN_CYCLE'
}

/** 시험 결과 Entity */
export type TestingResultEntity = {
  __typename?: 'TestingResultEntity';
  /** 번호 */
  asno?: Maybe<Scalars['Float']['output']>;
  /** 시험방식 */
  examineMethod?: Maybe<Examine_Method>;
  /** 시험 관리명 */
  examineName?: Maybe<Scalars['String']['output']>;
  /** 수행일자 */
  examineStartDateTime?: Maybe<Scalars['String']['output']>;
  /** 상태 */
  examineStatus?: Maybe<Scalars['String']['output']>;
  /** 시험대상 */
  examineTarget?: Maybe<Examine_Target>;
  /** 시험회자/조회기간 */
  examineTestSession?: Maybe<Scalars['String']['output']>;
  /** 관리번호 */
  manufacturerCodeName?: Maybe<Scalars['String']['output']>;
  /** 제조사코드 */
  mngNumber?: Maybe<Scalars['String']['output']>;
  /** 구분코드 */
  modelCode?: Maybe<Scalars['String']['output']>;
  /** 모델구분 */
  modelCodeName?: Maybe<Scalars['String']['output']>;
  /** 기물번호 */
  propertyNumber?: Maybe<Scalars['String']['output']>;
  /** 버전 */
  propertyVersion?: Maybe<Scalars['String']['output']>;
};

/** 시험관리 목록 검색 타입 */
export enum TestingResultSearchType {
  AsNo = 'AS_NO',
  ExamineMethod = 'EXAMINE_METHOD',
  ExamineName = 'EXAMINE_NAME',
  ExamineStartedDateTime = 'EXAMINE_STARTED_DATE_TIME',
  ExamineStatus = 'EXAMINE_STATUS',
  ExamineTarget = 'EXAMINE_TARGET',
  ExamineTestSession = 'EXAMINE_TEST_SESSION',
  ManufacturerCodeName = 'MANUFACTURER_CODE_NAME',
  MngNumber = 'MNG_NUMBER',
  ModelCode = 'MODEL_CODE',
  ModelCodeName = 'MODEL_CODE_NAME',
  PropertyNumber = 'PROPERTY_NUMBER',
  PropertyVersion = 'PROPERTY_VERSION'
}

/** 상세 시험결과 목록 카운트 요청 */
export type GetDetailTestingResultCountOutput = {
  __typename?: 'getDetailTestingResultCountOutput';
  /** 상세 시험결과 목록 카운트 */
  count?: Maybe<Scalars['Float']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  ok: Scalars['Boolean']['output'];
};

export type AddOrEditTestingManagerItemMutationVariables = Exact<{
  examineName: Scalars['String']['input'];
  mngNumber: Scalars['String']['input'];
  manufacturerCodeName: Scalars['String']['input'];
  propertyNumber: Scalars['String']['input'];
  propertyVersion: Scalars['String']['input'];
  modelCode: Scalars['String']['input'];
  rtuIp: Scalars['String']['input'];
  examinePort: Scalars['String']['input'];
  endpointId?: InputMaybe<Scalars['Float']['input']>;
  rtuMeasurementMinCycle?: InputMaybe<Scalars['String']['input']>;
  cttName?: InputMaybe<Scalars['String']['input']>;
  personInChargeName?: InputMaybe<Scalars['String']['input']>;
  personInChargeTel?: InputMaybe<Scalars['String']['input']>;
  descr?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddOrEditTestingManagerItemMutation = { __typename?: 'Mutation', addOrEditTestingManagerItem: { __typename?: 'AddOrEditTestingManagerItemOutput', ok: boolean, error?: string | null } };

export type DeleteTestingManagerItemMutationVariables = Exact<{
  examineName: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type DeleteTestingManagerItemMutation = { __typename?: 'Mutation', deleteTestingManagerItem: { __typename?: 'DeleteTestingManagerItemOutput', ok: boolean, error?: string | null } };

export type GetCountTestingManagerItemQueryVariables = Exact<{
  searchType: TestingManagerSearchType;
  searchValue?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
}>;


export type GetCountTestingManagerItemQuery = { __typename?: 'Query', getCountTestingManagerItem: { __typename?: 'GetCountTestingManagerItemOutput', ok: boolean, error?: string | null, count?: number | null } };

export type GetTestingManagerItemListQueryVariables = Exact<{
  searchType: TestingManagerSearchType;
  page?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
  fieldSort?: InputMaybe<FieldSort>;
  searchValue?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetTestingManagerItemListQuery = { __typename?: 'Query', getTestingManagerItemList: { __typename?: 'GetTestingManagerItemOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'TestingManagerItemEntity', asNo: number, examineName: string, mngNumber: string, manufacturerCodeName: string, propertyNumber: string, propertyVersion: string, modelCode: string, modelCodeName: string, rtuIp: string, rtuMeasurementMinCycle: string, cttName?: string | null, examinePort: string, personInChargeName: string, personInChargeTel: string, descr: string, cttOnOff: string, rtuConnectStatus: string, endpointId?: number | null }> | null } };

export type GetCountCttItemQueryVariables = Exact<{
  searchType: CttItemSearchType;
  searchValue?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetCountCttItemQuery = { __typename?: 'Query', getCountCTTItem: { __typename?: 'GetCountCTTItemOutput', ok: boolean, error?: string | null, count?: number | null } };

export type GetCttItemListQueryVariables = Exact<{
  searchType: CttItemSearchType;
  searchValue?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
}>;


export type GetCttItemListQuery = { __typename?: 'Query', getCttItemList: { __typename?: 'GetCTTItemOutput', ok: boolean, error?: string | null, list: Array<{ __typename?: 'CttItemEntity', asNo: number, endpointId: number, cttonoff: string, cttName?: string | null, cttVersion: string, cttIp: string, cttComPort: string, cttMac: string, cttComName: string, cttmemo: string }> } };

export type GetTestingManagementNameListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTestingManagementNameListQuery = { __typename?: 'Query', getTestingManagementNameList: { __typename?: 'GetTestingManagementNameListOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'TestingManagementNameEntity', examineName: string }> | null } };

export type GetTestingFreeSetListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTestingFreeSetListQuery = { __typename?: 'Query', getTestingFreeSetList: { __typename?: 'GetTestingFreeSetListOutput', ok: boolean, error?: string | null, list: Array<{ __typename?: 'TestingFreeSetNameEntity', examineFreeSetName: string, examineTarget?: Examine_Target | null, examineMethod?: Examine_Method | null, examineSetting?: string | null }> } };

export type GetFreeSetItemQueryVariables = Exact<{
  examineFreeSetName: Scalars['String']['input'];
}>;


export type GetFreeSetItemQuery = { __typename?: 'Query', getFreeSetItem: { __typename?: 'GetTestingFreeSetItemOutput', ok: boolean, error?: string | null, item?: { __typename?: 'TestingFreeSetEntity', examineTarget?: Examine_Target | null, examineMethod?: Examine_Method | null, examineSetting?: string | null } | null } };

export type SaveFreeSetItemMutationVariables = Exact<{
  examineFreeSetName: Scalars['String']['input'];
  examineTarget: Examine_Target;
  examineMethod: Examine_Method;
  examineSetting: Scalars['String']['input'];
}>;


export type SaveFreeSetItemMutation = { __typename?: 'Mutation', saveFreeSetItem: { __typename?: 'SaveFreeSetItemOutput', ok: boolean, error?: string | null } };

export type DeleteFreeSetItemMutationVariables = Exact<{
  examineFreeSetName: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type DeleteFreeSetItemMutation = { __typename?: 'Mutation', deleteFreeSetItem: { __typename?: 'DeleteFreeSetItemOutput', ok: boolean, error?: string | null } };

export type TestRequestTransmissionMutationVariables = Exact<{
  examineName: Scalars['String']['input'];
  examineFreeSetName: Scalars['String']['input'];
  examineTarget: Examine_Target;
  examineMethod: Examine_Method;
  examineCount?: InputMaybe<Scalars['Float']['input']>;
  endpointId?: InputMaybe<Scalars['Float']['input']>;
  rtuMeasurementMinCycle?: InputMaybe<Scalars['String']['input']>;
}>;


export type TestRequestTransmissionMutation = { __typename?: 'Mutation', testRequestTransmission: { __typename?: 'TestRequestTransmissionOutput', ok: boolean, error?: string | null } };

export type GetTestingResultQueryVariables = Exact<{
  searchType: TestingResultSearchType;
  page?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
  fieldSort?: InputMaybe<FieldSort>;
  searchValue?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetTestingResultQuery = { __typename?: 'Query', getTestingResult: { __typename?: 'GetTestingResultOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'TestingResultEntity', asno?: number | null, examineStartDateTime?: string | null, examineName?: string | null, mngNumber?: string | null, manufacturerCodeName?: string | null, propertyNumber?: string | null, propertyVersion?: string | null, modelCode?: string | null, modelCodeName?: string | null, examineTarget?: Examine_Target | null, examineMethod?: Examine_Method | null, examineTestSession?: string | null, examineStatus?: string | null }> | null } };

export type GetTestingResultCountQueryVariables = Exact<{
  searchType: TestingResultSearchType;
  searchValue?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetTestingResultCountQuery = { __typename?: 'Query', getTestingResultCount: { __typename?: 'GetTestingResultCountOutput', ok: boolean, error?: string | null, count?: number | null } };

export type DeleteResultHistoryMutationVariables = Exact<{
  examineStartDateTimeList: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type DeleteResultHistoryMutation = { __typename?: 'Mutation', deleteResultHistory: { __typename?: 'DeleteResultHistoryOutput', ok: boolean, error?: string | null } };

export type GetTestingInfoQueryVariables = Exact<{
  examineStartDateTime?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetTestingInfoQuery = { __typename?: 'Query', getTestingInfo: { __typename?: 'GetTestingInfoOutput', ok: boolean, error?: string | null, info?: { __typename?: 'TestingInfoEntity', examineStartDateTime?: string | null, examineSetting?: string | null, examineName?: string | null, mngNumber?: string | null, manufacturerCodeName?: string | null, propertyNumber?: number | null, propertyVersion?: number | null, modelCode?: number | null, personInChargeName: string, personInChargeTel: string, descr: string, examineResultJson?: string | null } | null } };

export type GetDetailTestingResultQueryVariables = Exact<{
  searchType: DetailTestingResultSearchType;
  page?: InputMaybe<Scalars['Float']['input']>;
  take?: InputMaybe<Scalars['Float']['input']>;
  fieldSort?: InputMaybe<FieldSort>;
  examineStartDateTime?: InputMaybe<Scalars['String']['input']>;
  searchValue?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetDetailTestingResultQuery = { __typename?: 'Query', getDetailTestingResult: { __typename?: 'GetDetailTestingResultOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'TestingDetailResultEntity', inSDateTime?: string | null, dataType: string, examineNumber: number, resultValuePackets?: Array<string> | null, examineResults?: string | null, inputResults?: string | null, integrityTestResults?: string | null, checksumInspectionResults?: string | null, view: string, none: string }> | null } };

export type GetDetailTestingResultCountQueryVariables = Exact<{
  searchType: DetailTestingResultSearchType;
  examineStartDateTime?: InputMaybe<Scalars['String']['input']>;
  searchValue?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetDetailTestingResultCountQuery = { __typename?: 'Query', getDetailTestingResultCount: { __typename?: 'getDetailTestingResultCountOutput', ok: boolean, error?: string | null, count?: number | null } };

export type GetEachTestingResultQueryVariables = Exact<{
  eachTestingResult: Array<IEachTestingResult> | IEachTestingResult;
}>;


export type GetEachTestingResultQuery = { __typename?: 'Query', getEachTestingResult: { __typename?: 'GetEachTestingResultOutput', ok: boolean, error?: string | null, info?: Array<{ __typename?: 'EachTestingResultEntity', examineNumber: number, resultValueExamineResults?: string | null, examineSetting?: string | null }> | null } };

export type GetTestingProcessInfoQueryVariables = Exact<{
  examineStartDateTime?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetTestingProcessInfoQuery = { __typename?: 'Query', getTestingProcessInfo: { __typename?: 'GetTestingProcessInfoOutput', ok: boolean, error?: string | null, isTesting: Is_Testing, mainInfo?: { __typename?: 'MainTestingProcessEntity', cttServerStatus?: Is_Pass | null, pcTestDeviceInfoQuery?: Is_Pass | null, inspectionComplianceAssessment?: Is_Pass | null, connectivityTest?: Is_Pass | null, statusTestResult?: Is_Pass | null, responseTestResult?: Is_Pass | null, testDeviceInitializationRequest?: Is_Pass | null, testDeviceInitializationResponse?: Is_Pass | null, testConfigurationValidation?: Is_Pass | null, receivedDataVerification?: Is_Pass | null, initializeTestDevice?: Is_Pass | null, testDeviceInitializationStatus?: Is_Pass | null, updateCTTServerInformation?: Is_Pass | null, writeInspectionResults?: Is_Pass | null, updateDatabase?: Is_Pass | null } | null, subInfo?: Array<{ __typename?: 'SubTestingProcessEntity', dataRequestTransmission?: Is_Pass | null, rtuSimulatorDataRequest?: Is_Pass | null, calorimeterDataResponseConfirmation?: Is_Pass | null, dataResponseTransmission?: Is_Pass | null, parsingTest?: Is_Pass | null, frameTypeTest?: Is_Pass | null, controlFieldTest?: Is_Pass | null, addressFieldTest?: Is_Pass | null, controlInfoFieldTest?: Is_Pass | null, checksumTest?: Is_Pass | null, validationTest?: Is_Pass | null, saveResultAndUpdate?: Is_Pass | null }> | null } };

export type LoginQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type LoginQuery = { __typename?: 'Query', login: { __typename?: 'LoginOutput', ok: boolean, error?: string | null, token?: string | null } };


export const AddOrEditTestingManagerItemDocument = gql`
    mutation AddOrEditTestingManagerItem($examineName: String!, $mngNumber: String!, $manufacturerCodeName: String!, $propertyNumber: String!, $propertyVersion: String!, $modelCode: String!, $rtuIp: String!, $examinePort: String!, $endpointId: Float, $rtuMeasurementMinCycle: String, $cttName: String, $personInChargeName: String, $personInChargeTel: String, $descr: String) {
  addOrEditTestingManagerItem(
    examineName: $examineName
    mngNumber: $mngNumber
    manufacturerCodeName: $manufacturerCodeName
    propertyNumber: $propertyNumber
    propertyVersion: $propertyVersion
    modelCode: $modelCode
    rtuIp: $rtuIp
    examinePort: $examinePort
    endpointId: $endpointId
    rtuMeasurementMinCycle: $rtuMeasurementMinCycle
    cttName: $cttName
    personInChargeName: $personInChargeName
    personInChargeTel: $personInChargeTel
    descr: $descr
  ) {
    ok
    error
  }
}
    `;
export type AddOrEditTestingManagerItemMutationFn = Apollo.MutationFunction<AddOrEditTestingManagerItemMutation, AddOrEditTestingManagerItemMutationVariables>;

/**
 * __useAddOrEditTestingManagerItemMutation__
 *
 * To run a mutation, you first call `useAddOrEditTestingManagerItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrEditTestingManagerItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrEditTestingManagerItemMutation, { data, loading, error }] = useAddOrEditTestingManagerItemMutation({
 *   variables: {
 *      examineName: // value for 'examineName'
 *      mngNumber: // value for 'mngNumber'
 *      manufacturerCodeName: // value for 'manufacturerCodeName'
 *      propertyNumber: // value for 'propertyNumber'
 *      propertyVersion: // value for 'propertyVersion'
 *      modelCode: // value for 'modelCode'
 *      rtuIp: // value for 'rtuIp'
 *      examinePort: // value for 'examinePort'
 *      endpointId: // value for 'endpointId'
 *      rtuMeasurementMinCycle: // value for 'rtuMeasurementMinCycle'
 *      cttName: // value for 'cttName'
 *      personInChargeName: // value for 'personInChargeName'
 *      personInChargeTel: // value for 'personInChargeTel'
 *      descr: // value for 'descr'
 *   },
 * });
 */
export function useAddOrEditTestingManagerItemMutation(baseOptions?: Apollo.MutationHookOptions<AddOrEditTestingManagerItemMutation, AddOrEditTestingManagerItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrEditTestingManagerItemMutation, AddOrEditTestingManagerItemMutationVariables>(AddOrEditTestingManagerItemDocument, options);
      }
export type AddOrEditTestingManagerItemMutationHookResult = ReturnType<typeof useAddOrEditTestingManagerItemMutation>;
export type AddOrEditTestingManagerItemMutationResult = Apollo.MutationResult<AddOrEditTestingManagerItemMutation>;
export type AddOrEditTestingManagerItemMutationOptions = Apollo.BaseMutationOptions<AddOrEditTestingManagerItemMutation, AddOrEditTestingManagerItemMutationVariables>;
export const DeleteTestingManagerItemDocument = gql`
    mutation DeleteTestingManagerItem($examineName: [String!]!) {
  deleteTestingManagerItem(examineName: $examineName) {
    ok
    error
  }
}
    `;
export type DeleteTestingManagerItemMutationFn = Apollo.MutationFunction<DeleteTestingManagerItemMutation, DeleteTestingManagerItemMutationVariables>;

/**
 * __useDeleteTestingManagerItemMutation__
 *
 * To run a mutation, you first call `useDeleteTestingManagerItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTestingManagerItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTestingManagerItemMutation, { data, loading, error }] = useDeleteTestingManagerItemMutation({
 *   variables: {
 *      examineName: // value for 'examineName'
 *   },
 * });
 */
export function useDeleteTestingManagerItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTestingManagerItemMutation, DeleteTestingManagerItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTestingManagerItemMutation, DeleteTestingManagerItemMutationVariables>(DeleteTestingManagerItemDocument, options);
      }
export type DeleteTestingManagerItemMutationHookResult = ReturnType<typeof useDeleteTestingManagerItemMutation>;
export type DeleteTestingManagerItemMutationResult = Apollo.MutationResult<DeleteTestingManagerItemMutation>;
export type DeleteTestingManagerItemMutationOptions = Apollo.BaseMutationOptions<DeleteTestingManagerItemMutation, DeleteTestingManagerItemMutationVariables>;
export const GetCountTestingManagerItemDocument = gql`
    query GetCountTestingManagerItem($searchType: TestingManagerSearchType!, $searchValue: String, $page: Float, $take: Float) {
  getCountTestingManagerItem(
    searchType: $searchType
    searchValue: $searchValue
    page: $page
    take: $take
  ) {
    ok
    error
    count
  }
}
    `;

/**
 * __useGetCountTestingManagerItemQuery__
 *
 * To run a query within a React component, call `useGetCountTestingManagerItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountTestingManagerItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountTestingManagerItemQuery({
 *   variables: {
 *      searchType: // value for 'searchType'
 *      searchValue: // value for 'searchValue'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetCountTestingManagerItemQuery(baseOptions: Apollo.QueryHookOptions<GetCountTestingManagerItemQuery, GetCountTestingManagerItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountTestingManagerItemQuery, GetCountTestingManagerItemQueryVariables>(GetCountTestingManagerItemDocument, options);
      }
export function useGetCountTestingManagerItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountTestingManagerItemQuery, GetCountTestingManagerItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountTestingManagerItemQuery, GetCountTestingManagerItemQueryVariables>(GetCountTestingManagerItemDocument, options);
        }
export type GetCountTestingManagerItemQueryHookResult = ReturnType<typeof useGetCountTestingManagerItemQuery>;
export type GetCountTestingManagerItemLazyQueryHookResult = ReturnType<typeof useGetCountTestingManagerItemLazyQuery>;
export type GetCountTestingManagerItemQueryResult = Apollo.QueryResult<GetCountTestingManagerItemQuery, GetCountTestingManagerItemQueryVariables>;
export const GetTestingManagerItemListDocument = gql`
    query GetTestingManagerItemList($searchType: TestingManagerSearchType!, $page: Float, $take: Float, $fieldSort: FieldSort, $searchValue: String) {
  getTestingManagerItemList(
    searchType: $searchType
    page: $page
    take: $take
    fieldSort: $fieldSort
    searchValue: $searchValue
  ) {
    ok
    error
    list {
      asNo
      examineName
      mngNumber
      manufacturerCodeName
      propertyNumber
      propertyVersion
      modelCode
      modelCodeName
      rtuIp
      rtuMeasurementMinCycle
      cttName
      examinePort
      personInChargeName
      personInChargeTel
      descr
      cttOnOff
      rtuConnectStatus
      endpointId
    }
  }
}
    `;

/**
 * __useGetTestingManagerItemListQuery__
 *
 * To run a query within a React component, call `useGetTestingManagerItemListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTestingManagerItemListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTestingManagerItemListQuery({
 *   variables: {
 *      searchType: // value for 'searchType'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      fieldSort: // value for 'fieldSort'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetTestingManagerItemListQuery(baseOptions: Apollo.QueryHookOptions<GetTestingManagerItemListQuery, GetTestingManagerItemListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTestingManagerItemListQuery, GetTestingManagerItemListQueryVariables>(GetTestingManagerItemListDocument, options);
      }
export function useGetTestingManagerItemListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTestingManagerItemListQuery, GetTestingManagerItemListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTestingManagerItemListQuery, GetTestingManagerItemListQueryVariables>(GetTestingManagerItemListDocument, options);
        }
export type GetTestingManagerItemListQueryHookResult = ReturnType<typeof useGetTestingManagerItemListQuery>;
export type GetTestingManagerItemListLazyQueryHookResult = ReturnType<typeof useGetTestingManagerItemListLazyQuery>;
export type GetTestingManagerItemListQueryResult = Apollo.QueryResult<GetTestingManagerItemListQuery, GetTestingManagerItemListQueryVariables>;
export const GetCountCttItemDocument = gql`
    query GetCountCTTItem($searchType: CTTItemSearchType!, $searchValue: String) {
  getCountCTTItem(searchType: $searchType, searchValue: $searchValue) {
    ok
    error
    count
  }
}
    `;

/**
 * __useGetCountCttItemQuery__
 *
 * To run a query within a React component, call `useGetCountCttItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountCttItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountCttItemQuery({
 *   variables: {
 *      searchType: // value for 'searchType'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetCountCttItemQuery(baseOptions: Apollo.QueryHookOptions<GetCountCttItemQuery, GetCountCttItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountCttItemQuery, GetCountCttItemQueryVariables>(GetCountCttItemDocument, options);
      }
export function useGetCountCttItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountCttItemQuery, GetCountCttItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountCttItemQuery, GetCountCttItemQueryVariables>(GetCountCttItemDocument, options);
        }
export type GetCountCttItemQueryHookResult = ReturnType<typeof useGetCountCttItemQuery>;
export type GetCountCttItemLazyQueryHookResult = ReturnType<typeof useGetCountCttItemLazyQuery>;
export type GetCountCttItemQueryResult = Apollo.QueryResult<GetCountCttItemQuery, GetCountCttItemQueryVariables>;
export const GetCttItemListDocument = gql`
    query GetCttItemList($searchType: CTTItemSearchType!, $searchValue: String, $page: Float, $take: Float) {
  getCttItemList(
    searchType: $searchType
    searchValue: $searchValue
    page: $page
    take: $take
  ) {
    ok
    error
    list {
      asNo
      endpointId
      cttonoff
      cttName
      cttVersion
      cttIp
      cttComPort
      cttMac
      cttComName
      cttmemo
    }
  }
}
    `;

/**
 * __useGetCttItemListQuery__
 *
 * To run a query within a React component, call `useGetCttItemListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCttItemListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCttItemListQuery({
 *   variables: {
 *      searchType: // value for 'searchType'
 *      searchValue: // value for 'searchValue'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetCttItemListQuery(baseOptions: Apollo.QueryHookOptions<GetCttItemListQuery, GetCttItemListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCttItemListQuery, GetCttItemListQueryVariables>(GetCttItemListDocument, options);
      }
export function useGetCttItemListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCttItemListQuery, GetCttItemListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCttItemListQuery, GetCttItemListQueryVariables>(GetCttItemListDocument, options);
        }
export type GetCttItemListQueryHookResult = ReturnType<typeof useGetCttItemListQuery>;
export type GetCttItemListLazyQueryHookResult = ReturnType<typeof useGetCttItemListLazyQuery>;
export type GetCttItemListQueryResult = Apollo.QueryResult<GetCttItemListQuery, GetCttItemListQueryVariables>;
export const GetTestingManagementNameListDocument = gql`
    query GetTestingManagementNameList {
  getTestingManagementNameList {
    ok
    error
    list {
      examineName
    }
  }
}
    `;

/**
 * __useGetTestingManagementNameListQuery__
 *
 * To run a query within a React component, call `useGetTestingManagementNameListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTestingManagementNameListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTestingManagementNameListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTestingManagementNameListQuery(baseOptions?: Apollo.QueryHookOptions<GetTestingManagementNameListQuery, GetTestingManagementNameListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTestingManagementNameListQuery, GetTestingManagementNameListQueryVariables>(GetTestingManagementNameListDocument, options);
      }
export function useGetTestingManagementNameListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTestingManagementNameListQuery, GetTestingManagementNameListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTestingManagementNameListQuery, GetTestingManagementNameListQueryVariables>(GetTestingManagementNameListDocument, options);
        }
export type GetTestingManagementNameListQueryHookResult = ReturnType<typeof useGetTestingManagementNameListQuery>;
export type GetTestingManagementNameListLazyQueryHookResult = ReturnType<typeof useGetTestingManagementNameListLazyQuery>;
export type GetTestingManagementNameListQueryResult = Apollo.QueryResult<GetTestingManagementNameListQuery, GetTestingManagementNameListQueryVariables>;
export const GetTestingFreeSetListDocument = gql`
    query GetTestingFreeSetList {
  getTestingFreeSetList {
    ok
    error
    list {
      examineFreeSetName
      examineTarget
      examineMethod
      examineSetting
    }
  }
}
    `;

/**
 * __useGetTestingFreeSetListQuery__
 *
 * To run a query within a React component, call `useGetTestingFreeSetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTestingFreeSetListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTestingFreeSetListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTestingFreeSetListQuery(baseOptions?: Apollo.QueryHookOptions<GetTestingFreeSetListQuery, GetTestingFreeSetListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTestingFreeSetListQuery, GetTestingFreeSetListQueryVariables>(GetTestingFreeSetListDocument, options);
      }
export function useGetTestingFreeSetListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTestingFreeSetListQuery, GetTestingFreeSetListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTestingFreeSetListQuery, GetTestingFreeSetListQueryVariables>(GetTestingFreeSetListDocument, options);
        }
export type GetTestingFreeSetListQueryHookResult = ReturnType<typeof useGetTestingFreeSetListQuery>;
export type GetTestingFreeSetListLazyQueryHookResult = ReturnType<typeof useGetTestingFreeSetListLazyQuery>;
export type GetTestingFreeSetListQueryResult = Apollo.QueryResult<GetTestingFreeSetListQuery, GetTestingFreeSetListQueryVariables>;
export const GetFreeSetItemDocument = gql`
    query GetFreeSetItem($examineFreeSetName: String!) {
  getFreeSetItem(examineFreeSetName: $examineFreeSetName) {
    ok
    error
    item {
      examineTarget
      examineMethod
      examineSetting
    }
  }
}
    `;

/**
 * __useGetFreeSetItemQuery__
 *
 * To run a query within a React component, call `useGetFreeSetItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFreeSetItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFreeSetItemQuery({
 *   variables: {
 *      examineFreeSetName: // value for 'examineFreeSetName'
 *   },
 * });
 */
export function useGetFreeSetItemQuery(baseOptions: Apollo.QueryHookOptions<GetFreeSetItemQuery, GetFreeSetItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFreeSetItemQuery, GetFreeSetItemQueryVariables>(GetFreeSetItemDocument, options);
      }
export function useGetFreeSetItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFreeSetItemQuery, GetFreeSetItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFreeSetItemQuery, GetFreeSetItemQueryVariables>(GetFreeSetItemDocument, options);
        }
export type GetFreeSetItemQueryHookResult = ReturnType<typeof useGetFreeSetItemQuery>;
export type GetFreeSetItemLazyQueryHookResult = ReturnType<typeof useGetFreeSetItemLazyQuery>;
export type GetFreeSetItemQueryResult = Apollo.QueryResult<GetFreeSetItemQuery, GetFreeSetItemQueryVariables>;
export const SaveFreeSetItemDocument = gql`
    mutation SaveFreeSetItem($examineFreeSetName: String!, $examineTarget: EXAMINE_TARGET!, $examineMethod: EXAMINE_METHOD!, $examineSetting: String!) {
  saveFreeSetItem(
    examineFreeSetName: $examineFreeSetName
    examineTarget: $examineTarget
    examineMethod: $examineMethod
    examineSetting: $examineSetting
  ) {
    ok
    error
  }
}
    `;
export type SaveFreeSetItemMutationFn = Apollo.MutationFunction<SaveFreeSetItemMutation, SaveFreeSetItemMutationVariables>;

/**
 * __useSaveFreeSetItemMutation__
 *
 * To run a mutation, you first call `useSaveFreeSetItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveFreeSetItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveFreeSetItemMutation, { data, loading, error }] = useSaveFreeSetItemMutation({
 *   variables: {
 *      examineFreeSetName: // value for 'examineFreeSetName'
 *      examineTarget: // value for 'examineTarget'
 *      examineMethod: // value for 'examineMethod'
 *      examineSetting: // value for 'examineSetting'
 *   },
 * });
 */
export function useSaveFreeSetItemMutation(baseOptions?: Apollo.MutationHookOptions<SaveFreeSetItemMutation, SaveFreeSetItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveFreeSetItemMutation, SaveFreeSetItemMutationVariables>(SaveFreeSetItemDocument, options);
      }
export type SaveFreeSetItemMutationHookResult = ReturnType<typeof useSaveFreeSetItemMutation>;
export type SaveFreeSetItemMutationResult = Apollo.MutationResult<SaveFreeSetItemMutation>;
export type SaveFreeSetItemMutationOptions = Apollo.BaseMutationOptions<SaveFreeSetItemMutation, SaveFreeSetItemMutationVariables>;
export const DeleteFreeSetItemDocument = gql`
    mutation DeleteFreeSetItem($examineFreeSetName: [String!]!) {
  deleteFreeSetItem(examineFreeSetName: $examineFreeSetName) {
    ok
    error
  }
}
    `;
export type DeleteFreeSetItemMutationFn = Apollo.MutationFunction<DeleteFreeSetItemMutation, DeleteFreeSetItemMutationVariables>;

/**
 * __useDeleteFreeSetItemMutation__
 *
 * To run a mutation, you first call `useDeleteFreeSetItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFreeSetItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFreeSetItemMutation, { data, loading, error }] = useDeleteFreeSetItemMutation({
 *   variables: {
 *      examineFreeSetName: // value for 'examineFreeSetName'
 *   },
 * });
 */
export function useDeleteFreeSetItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFreeSetItemMutation, DeleteFreeSetItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFreeSetItemMutation, DeleteFreeSetItemMutationVariables>(DeleteFreeSetItemDocument, options);
      }
export type DeleteFreeSetItemMutationHookResult = ReturnType<typeof useDeleteFreeSetItemMutation>;
export type DeleteFreeSetItemMutationResult = Apollo.MutationResult<DeleteFreeSetItemMutation>;
export type DeleteFreeSetItemMutationOptions = Apollo.BaseMutationOptions<DeleteFreeSetItemMutation, DeleteFreeSetItemMutationVariables>;
export const TestRequestTransmissionDocument = gql`
    mutation TestRequestTransmission($examineName: String!, $examineFreeSetName: String!, $examineTarget: EXAMINE_TARGET!, $examineMethod: EXAMINE_METHOD!, $examineCount: Float, $endpointId: Float, $rtuMeasurementMinCycle: String) {
  testRequestTransmission(
    examineName: $examineName
    examineFreeSetName: $examineFreeSetName
    examineTarget: $examineTarget
    examineMethod: $examineMethod
    examineCount: $examineCount
    endpointId: $endpointId
    rtuMeasurementMinCycle: $rtuMeasurementMinCycle
  ) {
    ok
    error
  }
}
    `;
export type TestRequestTransmissionMutationFn = Apollo.MutationFunction<TestRequestTransmissionMutation, TestRequestTransmissionMutationVariables>;

/**
 * __useTestRequestTransmissionMutation__
 *
 * To run a mutation, you first call `useTestRequestTransmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestRequestTransmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testRequestTransmissionMutation, { data, loading, error }] = useTestRequestTransmissionMutation({
 *   variables: {
 *      examineName: // value for 'examineName'
 *      examineFreeSetName: // value for 'examineFreeSetName'
 *      examineTarget: // value for 'examineTarget'
 *      examineMethod: // value for 'examineMethod'
 *      examineCount: // value for 'examineCount'
 *      endpointId: // value for 'endpointId'
 *      rtuMeasurementMinCycle: // value for 'rtuMeasurementMinCycle'
 *   },
 * });
 */
export function useTestRequestTransmissionMutation(baseOptions?: Apollo.MutationHookOptions<TestRequestTransmissionMutation, TestRequestTransmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TestRequestTransmissionMutation, TestRequestTransmissionMutationVariables>(TestRequestTransmissionDocument, options);
      }
export type TestRequestTransmissionMutationHookResult = ReturnType<typeof useTestRequestTransmissionMutation>;
export type TestRequestTransmissionMutationResult = Apollo.MutationResult<TestRequestTransmissionMutation>;
export type TestRequestTransmissionMutationOptions = Apollo.BaseMutationOptions<TestRequestTransmissionMutation, TestRequestTransmissionMutationVariables>;
export const GetTestingResultDocument = gql`
    query GetTestingResult($searchType: TestingResultSearchType!, $page: Float, $take: Float, $fieldSort: FieldSort, $searchValue: String) {
  getTestingResult(
    searchType: $searchType
    page: $page
    take: $take
    fieldSort: $fieldSort
    searchValue: $searchValue
  ) {
    ok
    error
    list {
      asno
      examineStartDateTime
      examineName
      mngNumber
      manufacturerCodeName
      propertyNumber
      propertyVersion
      modelCode
      modelCodeName
      examineTarget
      examineMethod
      examineTestSession
      examineStatus
    }
  }
}
    `;

/**
 * __useGetTestingResultQuery__
 *
 * To run a query within a React component, call `useGetTestingResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTestingResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTestingResultQuery({
 *   variables: {
 *      searchType: // value for 'searchType'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      fieldSort: // value for 'fieldSort'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetTestingResultQuery(baseOptions: Apollo.QueryHookOptions<GetTestingResultQuery, GetTestingResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTestingResultQuery, GetTestingResultQueryVariables>(GetTestingResultDocument, options);
      }
export function useGetTestingResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTestingResultQuery, GetTestingResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTestingResultQuery, GetTestingResultQueryVariables>(GetTestingResultDocument, options);
        }
export type GetTestingResultQueryHookResult = ReturnType<typeof useGetTestingResultQuery>;
export type GetTestingResultLazyQueryHookResult = ReturnType<typeof useGetTestingResultLazyQuery>;
export type GetTestingResultQueryResult = Apollo.QueryResult<GetTestingResultQuery, GetTestingResultQueryVariables>;
export const GetTestingResultCountDocument = gql`
    query GetTestingResultCount($searchType: TestingResultSearchType!, $searchValue: String) {
  getTestingResultCount(searchType: $searchType, searchValue: $searchValue) {
    ok
    error
    count
  }
}
    `;

/**
 * __useGetTestingResultCountQuery__
 *
 * To run a query within a React component, call `useGetTestingResultCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTestingResultCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTestingResultCountQuery({
 *   variables: {
 *      searchType: // value for 'searchType'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetTestingResultCountQuery(baseOptions: Apollo.QueryHookOptions<GetTestingResultCountQuery, GetTestingResultCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTestingResultCountQuery, GetTestingResultCountQueryVariables>(GetTestingResultCountDocument, options);
      }
export function useGetTestingResultCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTestingResultCountQuery, GetTestingResultCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTestingResultCountQuery, GetTestingResultCountQueryVariables>(GetTestingResultCountDocument, options);
        }
export type GetTestingResultCountQueryHookResult = ReturnType<typeof useGetTestingResultCountQuery>;
export type GetTestingResultCountLazyQueryHookResult = ReturnType<typeof useGetTestingResultCountLazyQuery>;
export type GetTestingResultCountQueryResult = Apollo.QueryResult<GetTestingResultCountQuery, GetTestingResultCountQueryVariables>;
export const DeleteResultHistoryDocument = gql`
    mutation DeleteResultHistory($examineStartDateTimeList: [String!]!) {
  deleteResultHistory(examineStartDateTimeList: $examineStartDateTimeList) {
    ok
    error
  }
}
    `;
export type DeleteResultHistoryMutationFn = Apollo.MutationFunction<DeleteResultHistoryMutation, DeleteResultHistoryMutationVariables>;

/**
 * __useDeleteResultHistoryMutation__
 *
 * To run a mutation, you first call `useDeleteResultHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteResultHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteResultHistoryMutation, { data, loading, error }] = useDeleteResultHistoryMutation({
 *   variables: {
 *      examineStartDateTimeList: // value for 'examineStartDateTimeList'
 *   },
 * });
 */
export function useDeleteResultHistoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteResultHistoryMutation, DeleteResultHistoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteResultHistoryMutation, DeleteResultHistoryMutationVariables>(DeleteResultHistoryDocument, options);
      }
export type DeleteResultHistoryMutationHookResult = ReturnType<typeof useDeleteResultHistoryMutation>;
export type DeleteResultHistoryMutationResult = Apollo.MutationResult<DeleteResultHistoryMutation>;
export type DeleteResultHistoryMutationOptions = Apollo.BaseMutationOptions<DeleteResultHistoryMutation, DeleteResultHistoryMutationVariables>;
export const GetTestingInfoDocument = gql`
    query GetTestingInfo($examineStartDateTime: String) {
  getTestingInfo(examineStartDateTime: $examineStartDateTime) {
    ok
    error
    info {
      examineStartDateTime
      examineSetting
      examineName
      mngNumber
      manufacturerCodeName
      propertyNumber
      propertyVersion
      modelCode
      personInChargeName
      personInChargeTel
      descr
      examineResultJson
    }
  }
}
    `;

/**
 * __useGetTestingInfoQuery__
 *
 * To run a query within a React component, call `useGetTestingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTestingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTestingInfoQuery({
 *   variables: {
 *      examineStartDateTime: // value for 'examineStartDateTime'
 *   },
 * });
 */
export function useGetTestingInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetTestingInfoQuery, GetTestingInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTestingInfoQuery, GetTestingInfoQueryVariables>(GetTestingInfoDocument, options);
      }
export function useGetTestingInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTestingInfoQuery, GetTestingInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTestingInfoQuery, GetTestingInfoQueryVariables>(GetTestingInfoDocument, options);
        }
export type GetTestingInfoQueryHookResult = ReturnType<typeof useGetTestingInfoQuery>;
export type GetTestingInfoLazyQueryHookResult = ReturnType<typeof useGetTestingInfoLazyQuery>;
export type GetTestingInfoQueryResult = Apollo.QueryResult<GetTestingInfoQuery, GetTestingInfoQueryVariables>;
export const GetDetailTestingResultDocument = gql`
    query GetDetailTestingResult($searchType: DetailTestingResultSearchType!, $page: Float, $take: Float, $fieldSort: FieldSort, $examineStartDateTime: String, $searchValue: String) {
  getDetailTestingResult(
    searchType: $searchType
    page: $page
    take: $take
    fieldSort: $fieldSort
    examineStartDateTime: $examineStartDateTime
    searchValue: $searchValue
  ) {
    ok
    error
    list {
      inSDateTime
      dataType
      examineNumber
      resultValuePackets
      examineResults
      inputResults
      integrityTestResults
      checksumInspectionResults
      view
      none
    }
  }
}
    `;

/**
 * __useGetDetailTestingResultQuery__
 *
 * To run a query within a React component, call `useGetDetailTestingResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDetailTestingResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDetailTestingResultQuery({
 *   variables: {
 *      searchType: // value for 'searchType'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      fieldSort: // value for 'fieldSort'
 *      examineStartDateTime: // value for 'examineStartDateTime'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetDetailTestingResultQuery(baseOptions: Apollo.QueryHookOptions<GetDetailTestingResultQuery, GetDetailTestingResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDetailTestingResultQuery, GetDetailTestingResultQueryVariables>(GetDetailTestingResultDocument, options);
      }
export function useGetDetailTestingResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDetailTestingResultQuery, GetDetailTestingResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDetailTestingResultQuery, GetDetailTestingResultQueryVariables>(GetDetailTestingResultDocument, options);
        }
export type GetDetailTestingResultQueryHookResult = ReturnType<typeof useGetDetailTestingResultQuery>;
export type GetDetailTestingResultLazyQueryHookResult = ReturnType<typeof useGetDetailTestingResultLazyQuery>;
export type GetDetailTestingResultQueryResult = Apollo.QueryResult<GetDetailTestingResultQuery, GetDetailTestingResultQueryVariables>;
export const GetDetailTestingResultCountDocument = gql`
    query GetDetailTestingResultCount($searchType: DetailTestingResultSearchType!, $examineStartDateTime: String, $searchValue: String) {
  getDetailTestingResultCount(
    searchType: $searchType
    examineStartDateTime: $examineStartDateTime
    searchValue: $searchValue
  ) {
    ok
    error
    count
  }
}
    `;

/**
 * __useGetDetailTestingResultCountQuery__
 *
 * To run a query within a React component, call `useGetDetailTestingResultCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDetailTestingResultCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDetailTestingResultCountQuery({
 *   variables: {
 *      searchType: // value for 'searchType'
 *      examineStartDateTime: // value for 'examineStartDateTime'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetDetailTestingResultCountQuery(baseOptions: Apollo.QueryHookOptions<GetDetailTestingResultCountQuery, GetDetailTestingResultCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDetailTestingResultCountQuery, GetDetailTestingResultCountQueryVariables>(GetDetailTestingResultCountDocument, options);
      }
export function useGetDetailTestingResultCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDetailTestingResultCountQuery, GetDetailTestingResultCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDetailTestingResultCountQuery, GetDetailTestingResultCountQueryVariables>(GetDetailTestingResultCountDocument, options);
        }
export type GetDetailTestingResultCountQueryHookResult = ReturnType<typeof useGetDetailTestingResultCountQuery>;
export type GetDetailTestingResultCountLazyQueryHookResult = ReturnType<typeof useGetDetailTestingResultCountLazyQuery>;
export type GetDetailTestingResultCountQueryResult = Apollo.QueryResult<GetDetailTestingResultCountQuery, GetDetailTestingResultCountQueryVariables>;
export const GetEachTestingResultDocument = gql`
    query GetEachTestingResult($eachTestingResult: [IEachTestingResult!]!) {
  getEachTestingResult(eachTestingResult: $eachTestingResult) {
    ok
    error
    info {
      examineNumber
      resultValueExamineResults
      examineSetting
    }
  }
}
    `;

/**
 * __useGetEachTestingResultQuery__
 *
 * To run a query within a React component, call `useGetEachTestingResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEachTestingResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEachTestingResultQuery({
 *   variables: {
 *      eachTestingResult: // value for 'eachTestingResult'
 *   },
 * });
 */
export function useGetEachTestingResultQuery(baseOptions: Apollo.QueryHookOptions<GetEachTestingResultQuery, GetEachTestingResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEachTestingResultQuery, GetEachTestingResultQueryVariables>(GetEachTestingResultDocument, options);
      }
export function useGetEachTestingResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEachTestingResultQuery, GetEachTestingResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEachTestingResultQuery, GetEachTestingResultQueryVariables>(GetEachTestingResultDocument, options);
        }
export type GetEachTestingResultQueryHookResult = ReturnType<typeof useGetEachTestingResultQuery>;
export type GetEachTestingResultLazyQueryHookResult = ReturnType<typeof useGetEachTestingResultLazyQuery>;
export type GetEachTestingResultQueryResult = Apollo.QueryResult<GetEachTestingResultQuery, GetEachTestingResultQueryVariables>;
export const GetTestingProcessInfoDocument = gql`
    query GetTestingProcessInfo($examineStartDateTime: String) {
  getTestingProcessInfo(examineStartDateTime: $examineStartDateTime) {
    ok
    error
    mainInfo {
      cttServerStatus
      pcTestDeviceInfoQuery
      inspectionComplianceAssessment
      connectivityTest
      statusTestResult
      responseTestResult
      testDeviceInitializationRequest
      testDeviceInitializationResponse
      testConfigurationValidation
      receivedDataVerification
      initializeTestDevice
      testDeviceInitializationStatus
      updateCTTServerInformation
      writeInspectionResults
      updateDatabase
    }
    subInfo {
      dataRequestTransmission
      rtuSimulatorDataRequest
      calorimeterDataResponseConfirmation
      dataResponseTransmission
      parsingTest
      frameTypeTest
      controlFieldTest
      addressFieldTest
      controlInfoFieldTest
      checksumTest
      validationTest
      saveResultAndUpdate
    }
    isTesting
  }
}
    `;

/**
 * __useGetTestingProcessInfoQuery__
 *
 * To run a query within a React component, call `useGetTestingProcessInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTestingProcessInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTestingProcessInfoQuery({
 *   variables: {
 *      examineStartDateTime: // value for 'examineStartDateTime'
 *   },
 * });
 */
export function useGetTestingProcessInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetTestingProcessInfoQuery, GetTestingProcessInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTestingProcessInfoQuery, GetTestingProcessInfoQueryVariables>(GetTestingProcessInfoDocument, options);
      }
export function useGetTestingProcessInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTestingProcessInfoQuery, GetTestingProcessInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTestingProcessInfoQuery, GetTestingProcessInfoQueryVariables>(GetTestingProcessInfoDocument, options);
        }
export type GetTestingProcessInfoQueryHookResult = ReturnType<typeof useGetTestingProcessInfoQuery>;
export type GetTestingProcessInfoLazyQueryHookResult = ReturnType<typeof useGetTestingProcessInfoLazyQuery>;
export type GetTestingProcessInfoQueryResult = Apollo.QueryResult<GetTestingProcessInfoQuery, GetTestingProcessInfoQueryVariables>;
export const LoginDocument = gql`
    query Login($token: String!) {
  login(token: $token) {
    ok
    error
    token
  }
}
    `;

/**
 * __useLoginQuery__
 *
 * To run a query within a React component, call `useLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLoginQuery(baseOptions: Apollo.QueryHookOptions<LoginQuery, LoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
      }
export function useLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoginQuery, LoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
        }
export type LoginQueryHookResult = ReturnType<typeof useLoginQuery>;
export type LoginLazyQueryHookResult = ReturnType<typeof useLoginLazyQuery>;
export type LoginQueryResult = Apollo.QueryResult<LoginQuery, LoginQueryVariables>;