import styled from "styled-components";
import useCreateTitle from "../../hooks/use-create-title";
import Button from "../shared/button";
import PageTitle from "../shared/page-title";
import PopUpContainer from "../shared/pop_up_container";
import Section from "../shared/section";
import StyledInput from "../shared/styled-input";
import Subject from "../shared/subject";

const Content = styled.section`
  display: flex;
  flex: 1;
  gap: 10px;
  input {
    display: flex;
    flex: 1;
  }
  border-top: 1px solid ${(props) => props.theme.colors.frameGrey};
  padding-top: 15px;
  min-height: 50px;
  max-height: 50px;
`;

function AddManuFacturer() {
  const { title } = useCreateTitle();
  return (
    <PopUpContainer>
      <PageTitle title={title} />
      <Section padding="10px">
        <Subject subject="제조사 추가" />
        <Content>
          <StyledInput placeholder="내용 입력" />
          <Button maxWidth="50px">{`추가`}</Button>
        </Content>
      </Section>
    </PopUpContainer>
  );
}

export default AddManuFacturer;
