import styled from "styled-components";

interface IProps {
  flex?: number;
  padding?: string;
}

const Section = styled.section<IProps>`
  display: flex;
  flex: ${(props) => props.flex ?? 1};
  flex-direction: column;
  gap: 10px;
  padding: ${(props) => props.padding ?? ""};
`;

export default Section;
