import styled from "styled-components";
import Button from "../../shared/button";
import ButtonContainer from "../../shared/button-container";
import Input from "../../shared/input";
import PopUpContainer from "../../shared/pop_up_container";
import Section from "../../shared/section";
import Subject from "../../shared/subject";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { FREE_SET_MESSAGE_DATA } from "../../../recoil/free_set_atoms/free_set_atoms";
import {
  SaveFreeSetItemMutationVariables,
  useSaveFreeSetItemMutation
} from "../../../__generated__/generated";

export const OPEN_SAVE_CHANNEL_MESSAGE = "open save channel";
export const SUCCESS_MESSAGE = "SUCCESS MESSAGE";

const InputContainer = styled.div`
  display: flex;
  flex: 1;
  border-top: 1px solid ${(props) => props.theme.colors.frameGrey};
  max-height: 30px;
  align-items: center;
  padding: 20px 0px;
`;

function SaveTestingPresetDialog() {
  const [freeSetName, setFreeSetName] = useState<string>("");
  const [freeSetItem, setFreeSetItem] = useState<
    FREE_SET_MESSAGE_DATA | undefined
  >();

  const channel = useMemo(() => {
    return new BroadcastChannel("SAVE_FREE_SET");
  }, []);

  const [saveFreeSetItem] = useSaveFreeSetItemMutation({
    fetchPolicy: "network-only",
    onError(error) {
      console.log(error);
    },
    update(_, { data }) {
      if (data?.saveFreeSetItem.ok) {
        channel.postMessage(SUCCESS_MESSAGE);
        channel.close();
        window.close();
      } else if (data?.saveFreeSetItem.error) {
        console.log(data?.saveFreeSetItem.error);
      }
    }
  });

  const handleSaveFreeSet = useCallback(() => {
    if (freeSetItem) {
      const examineSetting: Partial<FREE_SET_MESSAGE_DATA> = {
        ...freeSetItem,
        freeSetItemName: freeSetName
      };
      delete examineSetting.testingTargetType;
      delete examineSetting.testingType;
      const payload: SaveFreeSetItemMutationVariables = {
        examineFreeSetName: freeSetName,
        examineTarget: freeSetItem.testingTargetType,
        examineMethod: freeSetItem.testingType,
        examineSetting: JSON.stringify(examineSetting)
      };

      saveFreeSetItem({ variables: payload });
    }
  }, [saveFreeSetItem, freeSetName, freeSetItem]);

  useLayoutEffect(() => {
    channel.postMessage(OPEN_SAVE_CHANNEL_MESSAGE);
    channel.onmessage = (event) => {
      if (event.data && typeof event.data !== "string") {
        const data: FREE_SET_MESSAGE_DATA = event.data;
        if (data) {
          setFreeSetItem(data);
        }
        setFreeSetName(data.freeSetItemName ?? "");
      }
    };
  }, [channel]);
  return (
    <PopUpContainer>
      <Section padding="10px">
        <Subject subject="검사 프리셋 저장" />
        <InputContainer>
          <Input
            type="text"
            value={freeSetName}
            onChange={(event) => {
              setFreeSetName(event.currentTarget.value);
            }}
          />
        </InputContainer>
        <ButtonContainer>
          <Button
            maxWidth="70px"
            maxHeight="25px"
            disabled={!freeSetName}
            onClick={() => {
              handleSaveFreeSet();
            }}
          >{`저장`}</Button>
        </ButtonContainer>
      </Section>
    </PopUpContainer>
  );
}

export default SaveTestingPresetDialog;
