import { Navigate, Outlet } from "react-router-dom";
import styled from "styled-components";
import Header from "./components/header/header";
import ListOfMenu from "./components/list-of-menu/list-of-menu";
import PageTitle from "./components/shared/page-title";
import useCreateTitle from "./hooks/use-create-title";
import { RecoilRoot } from "recoil";
import routes from "./routes/routes";
import { useReactiveVar } from "@apollo/client";
import listOfApolloVar from "./utils/list_of_apollo_var";

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
`;

const Body = styled.div`
  display: flex;
  flex: 10;
  flex-direction: column;
  min-height: 0px;
  min-width: 0px;
`;

const Content = styled.div`
  display: flex;
  flex: 10;
  min-height: 0px;
  min-width: 0px;
  overflow: auto;
`;

function App() {
  const { title } = useCreateTitle();
  const isLoggedInVar = useReactiveVar(listOfApolloVar.isLoggedInVar);

  return (
    <Container>
      {!isLoggedInVar && <Navigate to={routes.login} replace={true} />}
      <PageTitle title={title} />
      <ListOfMenu />
      <RecoilRoot>
        <Body>
          <Header />
          <Content>
            <Outlet />
          </Content>
        </Body>
      </RecoilRoot>
    </Container>
  );
}

export default App;
