import { useEffect, useState } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";
import CheckIcon from "@iconify/icons-material-symbols/check-circle-sharp";
import ReactLoading from "react-loading";
import { colors } from "../../../styles";

const Container = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
  padding: 10px;
  svg {
    font-size: 30px;
    color: ${(props) => props.theme.colors.green};
  }
`;

const Main = styled.dl`
  display: flex;
  flex: 2;
  flex-direction: column;
  text-align: start;
`;

const StatusSection = styled.section`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;

const Title = styled.dt`
  font-size: 14px;
  font-weight: bold;
`;

const Description = styled.dd`
  white-space: pre;
`;

function CheckStats() {
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("CTT 상태 정보 확인중");
  const [description, setDescription] =
    useState<string>(`상태 정보를 요청하고 있습니다.
잠시만 기다려주세요`);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setTitle("CTT 상태 정보 확인 완료");
      setDescription(`현재 탭에서 확인 할 수 있습니다.`);
      setIsComplete(true);
    }, 2000);
    return () => {
      clearTimeout(timeId);
    };
  }, []);

  return (
    <Container>
      <StatusSection>
        {isComplete ? (
          <Icon icon={CheckIcon} />
        ) : (
          <ReactLoading
            type="spokes"
            color={colors.darkGrey}
            height={20}
            width={20}
          />
        )}
      </StatusSection>
      <Main>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Main>
    </Container>
  );
}

export default CheckStats;
