import { useCallback, useEffect, useRef, useState } from "react";
import * as ReactTable from "react-table";

export interface TColumn<P extends object>
  extends ReactTable.HeaderGroup<P>,
    Partial<ReactTable.UseGroupByColumnProps<P>> {}

interface IProps<P extends object> {
  columns: TColumn<P>[];
  toggleHideColumn: (columnId: string, value?: boolean | undefined) => void;
  title: string;
}

function useHideColumns<P extends object>({
  columns,
  toggleHideColumn,
  title
}: IProps<P>) {
  const isMount = useRef<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [countItem, setCountItem] = useState<number>(0);

  const handleIsOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleCountItem = useCallback((value: number) => {
    setCountItem(value);
  }, []);

  const disableIsOpen = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (!isMount.current && columns.length > 0) {
      isMount.current = true;
      const storedColumn = localStorage.getItem(title);
      let count = columns.length;
      if (storedColumn) {
        const parsedColumn: TColumn<P>[] = JSON.parse(storedColumn);
        parsedColumn.forEach((item) => {
          if (!item.isVisible) {
            count = count - 1;
            toggleHideColumn(item.id, true);
          }
        });
      }
      setCountItem(count);
    }
    return () => {
      if (isMount.current) {
        isMount.current = false;
      }
    };
  }, [toggleHideColumn, title, columns]);

  return { countItem, handleCountItem, isOpen, handleIsOpen, disableIsOpen };
}

export default useHideColumns;
