import { makeVar } from "@apollo/client";
import { verifyToken } from "./jwt";

export const TOKEN = "KTC_SMART_METER_TOKEN";

const isLoggedInVar = makeVar(Boolean(localStorage.getItem(TOKEN)));
const userInfoVar = makeVar(
  localStorage.getItem(TOKEN)
    ? verifyToken(localStorage.getItem(TOKEN) as string)
    : undefined
);

const userTokenVar = makeVar<string>("");

const listOfApolloVar = { userInfoVar, userTokenVar, isLoggedInVar };

export default listOfApolloVar;
