import styled from "styled-components";

const SearchContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  svg {
    font-size: 23px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.green};
    cursor: pointer;
    :hover {
      color: ${(props) => props.theme.colors.lightGreen};
    }
    :active {
      transform: scale(0.95);
    }
  }
`;

export default SearchContainer;
