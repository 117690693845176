import { useCallback, useState } from "react";

export interface ISearchForm<P> {
  searchType: P;
  searchValue: string;
}

interface IProps<P> {
  defaultSearchType: P;
}

function useSearchInTable<P>({ defaultSearchType }: IProps<P>) {
  const [searchTypeData, setSearchTypeData] = useState<P>(defaultSearchType);
  const [searchValueData, setSearchValueData] = useState<string>("");
  const [isSearch, setIsSearch] = useState<boolean>(true);

  const handleSearchTypeData = useCallback((value: P) => {
    setSearchTypeData(value);
  }, []);

  const handleSearchValueData = useCallback((value: string) => {
    setSearchValueData(value);
  }, []);

  const handleIsSearch = useCallback((value: boolean) => {
    setIsSearch(value);
  }, []);

  return {
    searchTypeData,
    searchValueData,
    isSearch,
    handleSearchTypeData,
    handleSearchValueData,
    handleIsSearch
  };
}

export default useSearchInTable;
