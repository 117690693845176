import styled from "styled-components";

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 5px;
`;

export default ButtonContainer;
