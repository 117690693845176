import { forwardRef } from "react";
import styled from "styled-components";

interface IProps {
  title: string;
  children?: React.ReactNode;
  isRed?: boolean;
}

const Container = styled.dialog`
  border: 1px solid ${(props) => props.theme.colors.frameGrey};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  min-height: fit-content;
  min-width: fit-content;
`;

const Content = styled.section`
  display: flex;
  flex: 1;
  min-width: 100px;
  flex-direction: column;
`;

const TitleBar = styled.div<{ isRed?: boolean }>`
  display: flex;
  flex: 1;
  justify-content: space-between;
  background-color: ${(props) =>
    props.isRed ? props.theme.colors.errorRed : props.theme.colors.green};
  color: ${(props) => props.theme.colors.white};
  padding: 5px;
  user-select: none;
`;

const XButton = styled.button`
  cursor: pointer;
  :hover {
    color: ${(props) => props.theme.colors.darkRed};
  }
  :active {
    transform: scale(0.98);
  }
  font-weight: bold;
`;

function Dialog(
  { title, isRed, children }: IProps,
  ref: React.ForwardedRef<HTMLDialogElement | undefined>
) {
  return (
    <Container id={title} ref={ref as React.RefObject<HTMLDialogElement>}>
      <TitleBar isRed={isRed}>
        <span>{title}</span>
        <menu>
          <XButton
            onClick={() => {
              const dialogEl = ref as React.RefObject<HTMLDialogElement>;
              dialogEl.current?.close();
            }}
          >
            X
          </XButton>
        </menu>
      </TitleBar>
      <Content>{children}</Content>
    </Container>
  );
}

export default forwardRef<HTMLDialogElement | undefined, IProps>(Dialog);
