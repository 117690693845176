import styled from "styled-components";
import { Icon } from "@iconify/react";
import InfoIcon from "@iconify/icons-material-symbols/info";

const Container = styled.div`
  display: flex;
  flex: 10;
  gap: 10px;
  padding: 10px;
  svg {
    font-size: 30px;
    color: ${(props) => props.theme.colors.errorRed};
  }
`;

const Main = styled.dl`
  display: flex;
  flex: 2;
  flex-direction: column;
  text-align: start;
  justify-content: center;
`;

const StatusSection = styled.section`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Description = styled.dd`
  font-weight: bold;
  white-space: pre;
`;

function DeleteTestingPreset() {
  return (
    <Container>
      <StatusSection>
        <Icon icon={InfoIcon} />
      </StatusSection>
      <Main>
        <Description>{`선택한 시험프리셋을 삭제 하시겠습니까?`}</Description>
      </Main>
    </Container>
  );
}

export default DeleteTestingPreset;
