import { TestingInfoEntity } from "../../../__generated__/generated";
import Editor from "../../editor/editor";
import EditorContainer from "../../shared/editor-container";
import FormContainer from "../../shared/form_container/form_container";
import SSpan from "../../shared/s-span";
import Section from "../../shared/section";
import Subject from "../../shared/subject";
import { FREE_SET_MESSAGE_DATA } from "../../../recoil/free_set_atoms/free_set_atoms";

export interface IExamineResult {
  errorCode: string;
  receiveResults: string;
  receptionDatetime: string;
  terminationClassification: string;
}

interface IProps {
  testingTargetType: string;
  testingType: string;
  info?: TestingInfoEntity;
  freeSetInfo?: FREE_SET_MESSAGE_DATA;
  examineResult?: IExamineResult;
}

function TestingInfo({
  testingTargetType,
  testingType,
  info,
  freeSetInfo,
  examineResult
}: IProps) {
  return (
    <Section>
      <FormContainer>
        <Subject subject="시험 정보" />
        <EditorContainer>
          <Editor filedName="검사일시">
            <SSpan>{info?.examineStartDateTime ?? ""}</SSpan>
          </Editor>
          <Editor filedName="시험 대상">
            <SSpan>{testingTargetType}</SSpan>
          </Editor>
          <Editor filedName="시험주기">
            <SSpan>{""}</SSpan>
          </Editor>
          <Editor filedName="시험회차">
            <SSpan>{freeSetInfo?.examineCount ?? ""}</SSpan>
          </Editor>
          <Editor filedName="시험방식">
            <SSpan>{testingType}</SSpan>
          </Editor>
          <Editor filedName="타임아웃 설정">
            <SSpan>{freeSetInfo?.timeOutSetting ?? ""}</SSpan>
          </Editor>
          <Editor filedName="에러검출시 동작">
            <SSpan>{``}</SSpan>
          </Editor>
          <Editor filedName="">
            <SSpan></SSpan>
          </Editor>
        </EditorContainer>
      </FormContainer>
      <FormContainer>
        <Subject subject="장치 정보" />
        <EditorContainer>
          <Editor filedName="시험관리명">
            <SSpan>{info?.examineName ?? ""}</SSpan>
          </Editor>
          <Editor filedName="제조사">
            <SSpan>{info?.manufacturerCodeName ?? ""}</SSpan>
          </Editor>
          <Editor filedName="관리번호">
            <SSpan>{info?.mngNumber ?? ""}</SSpan>
          </Editor>
          <Editor filedName="구분코드">
            <SSpan>{info?.modelCode ?? ""}</SSpan>
          </Editor>
          <Editor filedName="담당자">
            <SSpan>{info?.personInChargeName ?? ""}</SSpan>
          </Editor>
          <Editor filedName="담당자 연락처">
            <SSpan>{info?.personInChargeTel ?? ""}</SSpan>
          </Editor>
          <Editor filedName="비고" gridNumber={1}>
            <SSpan>{info?.descr ?? ""}</SSpan>
          </Editor>
        </EditorContainer>
      </FormContainer>
      <FormContainer>
        <Subject subject="시험 결과" />
        <EditorContainer>
          <Editor filedName="수신일시">
            <SSpan>{examineResult?.receptionDatetime ?? ""}</SSpan>
          </Editor>
          <Editor filedName="결과수신">
            <SSpan>{examineResult?.receiveResults ?? ""}</SSpan>
          </Editor>
          <Editor filedName="종료구분">
            <SSpan>{examineResult?.terminationClassification ?? ""}</SSpan>
          </Editor>
          <Editor filedName="에러코드">
            <SSpan>{examineResult?.errorCode ?? ""}</SSpan>
          </Editor>
        </EditorContainer>
      </FormContainer>
      {/* <FormContainer>
        <Subject subject="피시험 열량계 사전 설정" />
        <EditorContainer>
          <Editor filedName="적산값 생성방식">
            <SSpan>{`회차마다 고정값 증가`}</SSpan>
          </Editor>
          <Editor filedName="적산 설정값">
            <SSpan>{`100 Gcal`}</SSpan>
          </Editor>
          <Editor filedName="온도증가값 생성방식">
            <SSpan>{`사용안함`}</SSpan>
          </Editor>
          <Editor filedName="설정 온도증가값">
            <SSpan>{`해당없음`}</SSpan>
          </Editor>
        </EditorContainer>
      </FormContainer> */}
    </Section>
  );
}

export default TestingInfo;
