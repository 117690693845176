import styled from "styled-components";

const PopUpContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  gap: 10px;
`;

export default PopUpContainer;
