import EditorContainer from "../../shared/editor-container";
import Editor from "../../editor/editor";
import Subject from "../../shared/subject";
import { Icon } from "@iconify/react";
import OffLineIcon from "@iconify-icons/tabler/plug-connected-x";
import OnLineIcon from "@iconify-icons/fluent/plug-connected-checkmark-20-filled";
import ReloadButton from "../../shared/reload_button";
import ReloadIcon from "@iconify-icons/uiw/reload";
import SSpan from "../../shared/s-span";
import Section from "../../shared/section";
import styled from "styled-components";
import { useEffect, useMemo, useRef, useState } from "react";
import ContentContainer from "../../shared/content_container";
import {
  TestingManagementNameEntity,
  TestingManagerItemEntity,
  TestingManagerSearchType,
  useGetTestingManagementNameListLazyQuery,
  useGetTestingManagerItemListLazyQuery
} from "../../../__generated__/generated";
import { useRecoilState } from "recoil";
import { testDeviceAtoms } from "../../../recoil/test_device_atoms/test_device_atoms";
import { colors } from "../../../styles";

const OnlineContainer = styled.div`
  display: flex;
  flex: 1;
  color: ${(props) => props.theme.colors.darkBlue};
  svg {
    font-size: 20px;
  }
  align-items: center;
  font-weight: bold;
  gap: 5px;
`;

const OffLineContainer = styled(OnlineContainer)`
  color: ${(props) => props.theme.colors.darkRed};
`;

function DeviceInfo() {
  const dialogOnlineEl = useRef<HTMLDialogElement | undefined>();
  const [testDeviceState, setTestDeviceState] = useRecoilState(testDeviceAtoms);
  const [selectedTestingManagement, setSelectedTestingManagement] = useState<
    TestingManagerItemEntity | undefined
  >();

  const [getTestingManagementNameList, { data }] =
    useGetTestingManagementNameListLazyQuery({
      onError(error) {
        console.log(error);
      },
      onCompleted(data) {
        if (
          data.getTestingManagementNameList.ok &&
          data.getTestingManagementNameList.list
        ) {
          setTestDeviceState((pre) => {
            let examineName = "";
            if (
              data?.getTestingManagementNameList.list &&
              data?.getTestingManagementNameList.list[0].examineName
            ) {
              examineName =
                data?.getTestingManagementNameList?.list[0].examineName;
            }
            return {
              ...pre,
              examineName: examineName
            };
          });
        }
      }
    });

  const [getTestingManagerItemList] = useGetTestingManagerItemListLazyQuery({
    fetchPolicy: "no-cache",
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      if (
        data.getTestingManagerItemList.ok &&
        data.getTestingManagerItemList.list
      ) {
        setSelectedTestingManagement(data.getTestingManagerItemList.list[0]);
        const newIsOnOff =
          data.getTestingManagerItemList.list[0].cttOnOff === "오프라인"
            ? false
            : true;
        setTestDeviceState((pre) => {
          return { ...pre, isOnOff: newIsOnOff };
        });
        setTestDeviceState((pre) => {
          if (
            data.getTestingManagerItemList.list &&
            data.getTestingManagerItemList.list[0].endpointId
          ) {
            return {
              ...pre,
              endpointId: data.getTestingManagerItemList.list[0].endpointId,
              rtuMeasurementMinCycle:
                data.getTestingManagerItemList.list[0].rtuMeasurementMinCycle
            };
          }
          return pre;
        });
      } else if (data.getTestingManagerItemList.error) {
        console.log(data.getTestingManagerItemList.error);
      }
    }
  });

  const testingManagementNameList: TestingManagementNameEntity[] =
    useMemo(() => {
      return data?.getTestingManagementNameList.list ?? [];
    }, [data?.getTestingManagementNameList.list]);

  useEffect(() => {
    getTestingManagementNameList();
  }, [getTestingManagementNameList]);

  useEffect(() => {
    if (testDeviceState?.examineName) {
      getTestingManagerItemList({
        variables: {
          searchType: TestingManagerSearchType.ExamineName,
          searchValue: testDeviceState.examineName,
          page: 1,
          take: 1
        }
      });
    }
  }, [getTestingManagerItemList, testDeviceState?.examineName]);

  return (
    <Section flex={1}>
      <Subject subject="시험 장치 정보" />
      <EditorContainer>
        <Editor filedName="시험 관리명" required>
          <ContentContainer>
            <select
              name="testing-product-name"
              id="testing-product-name"
              value={testDeviceState.examineName}
              onChange={(event) => {
                setTestDeviceState((pre) => {
                  return {
                    ...pre,
                    examineName: event.currentTarget.value ?? ""
                  };
                });
              }}
            >
              {testingManagementNameList.map((item) => (
                <option value={item.examineName} key={item.examineName}>
                  {item.examineName}
                </option>
              ))}
            </select>
          </ContentContainer>
        </Editor>
        <Editor filedName="" isTitleDisable>
          <span></span>
        </Editor>
        <Editor filedName="관리번호">
          <SSpan>{selectedTestingManagement?.mngNumber}</SSpan>
        </Editor>
        <Editor filedName="">
          <SSpan></SSpan>
        </Editor>
        <Editor filedName="기물번호">
          <SSpan>{selectedTestingManagement?.propertyNumber}</SSpan>
        </Editor>
        <Editor filedName="제조사코드">
          <SSpan>{selectedTestingManagement?.manufacturerCodeName}</SSpan>
        </Editor>
        <Editor filedName="버전">
          <SSpan>{selectedTestingManagement?.propertyVersion}</SSpan>
        </Editor>
        <Editor filedName="장치타입">
          <SSpan>{selectedTestingManagement?.modelCode}</SSpan>
        </Editor>
        <Editor filedName="연결 CTT명">
          <SSpan>{selectedTestingManagement?.cttName}</SSpan>
        </Editor>
        <Editor filedName="CTT 접속상태">
          <OnlineContainer>
            <Icon
              icon={
                selectedTestingManagement?.cttOnOff === "온라인"
                  ? OnLineIcon
                  : `tabler:plug-connected-x`
              }
              color={
                selectedTestingManagement?.cttOnOff === "온라인"
                  ? colors.darkBlue
                  : colors.darkRed
              }
            />
            <SSpan
              color={
                selectedTestingManagement?.cttOnOff === "온라인"
                  ? colors.darkBlue
                  : colors.darkRed
              }
            >
              {selectedTestingManagement?.cttOnOff}
            </SSpan>
            <ReloadButton
              onClick={() => {
                dialogOnlineEl.current?.showModal();
              }}
            >
              <Icon icon={ReloadIcon} />
            </ReloadButton>
          </OnlineContainer>
        </Editor>
        <Editor filedName="RTU 아이피">
          <SSpan>{selectedTestingManagement?.rtuIp}</SSpan>
        </Editor>
        <Editor filedName="RTU 연결상태">
          <OffLineContainer>
            <Icon icon={OffLineIcon} />
            {selectedTestingManagement?.rtuConnectStatus}
          </OffLineContainer>
        </Editor>
        <Editor filedName="시험주기">
          <SSpan>{selectedTestingManagement?.rtuMeasurementMinCycle}</SSpan>
          {selectedTestingManagement?.rtuMeasurementMinCycle && (
            <SSpan>{`분`}</SSpan>
          )}
        </Editor>
        <Editor filedName="시험장치 연결포트">
          <SSpan> {selectedTestingManagement?.examinePort}</SSpan>
        </Editor>
        <Editor filedName="담당자">
          <SSpan>{selectedTestingManagement?.personInChargeName}</SSpan>
        </Editor>
        <Editor filedName="담당자 연락처">
          <SSpan>{selectedTestingManagement?.personInChargeTel}</SSpan>
        </Editor>
        <Editor filedName="비고">
          <SSpan>{selectedTestingManagement?.descr}</SSpan>
        </Editor>
        <Editor filedName="" isTitleDisable>
          <SSpan></SSpan>
        </Editor>
      </EditorContainer>
    </Section>
  );
}

export default DeviceInfo;
