import styled from "styled-components";
import StyledInput from "../shared/styled-input";
import { Icon } from "@iconify/react";
import SearchIcon from "@iconify/icons-mdi/magnify";
import { ControllerRenderProps } from "react-hook-form";

interface ISearchForm {
  searchType: any;
  searchValue: string;
}

interface IList {
  name: string;
  value: any;
}
interface IProps {
  list: IList[];
  searchType?: ControllerRenderProps<ISearchForm, "searchType">;
  searchValue?: ControllerRenderProps<ISearchForm, "searchValue">;
  handleSearch?: (payload: boolean) => void;
}

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SearchTitle = styled.span`
  font-weight: bold;
`;

const IconContainer = styled.div`
  font-size: 25px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.green};
  :hover {
    color: ${(props) => props.theme.colors.lightGreen};
  }
  cursor: pointer;
  :active {
    transform: scale(0.98);
  }
`;

function SearchWithCategories({
  list,
  searchType,
  searchValue,
  handleSearch
}: IProps) {
  return (
    <SearchContainer>
      <SearchTitle>검색</SearchTitle>
      <select {...searchType}>
        {list.map((item) => (
          <option value={item.value} key={`${item.name}-${item.value}`}>
            {item.name}
          </option>
        ))}
      </select>
      <StyledInput placeholder="내용 입력" {...searchValue} />
      <IconContainer
        onClick={() => {
          if (handleSearch) {
            handleSearch(true);
          }
        }}
      >
        <Icon icon={SearchIcon} />
      </IconContainer>
    </SearchContainer>
  );
}

export default SearchWithCategories;
