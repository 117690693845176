import PageTitle from "../../shared/page-title";
import PopUpContainer from "../../shared/pop_up_container";
import useCreateTitle from "../../../hooks/use-create-title";
import TestingInfo, { IExamineResult } from "./testing_info";
import Tabs, { ITab } from "../../tabs/tabs";
import useTabs from "../../../hooks/use-tabs";
import { Suspense, lazy, useLayoutEffect, useMemo, useState } from "react";
import Section from "../../shared/section";
import TestingStatusInfo from "./testing_status_info/testing_status_info";
import { RecoilRoot } from "recoil";
import {
  TestingInfoEntity,
  TestingManagerSearchType,
  useGetTestingInfoLazyQuery,
  useGetTestingManagerItemListLazyQuery
} from "../../../__generated__/generated";
import { FREE_SET_MESSAGE_DATA } from "../../../recoil/free_set_atoms/free_set_atoms";

const TestingResultInfo = lazy(
  () => import("./testing_result_info/testing_result_info")
);

export const TESTING_HISTORY_RESULT = "TESTING_HISTORY_RESULT";
export const OPEN_TESTING_HISTORY_RESULT_MESSAGE =
  "OPEN_TESTING_HISTORY_RESULT_MESSAGE";

interface PAYLOAD {
  examineStartDateTime: string;
  testingTargetType: string;
  testingType: string;
  freeSetItemName?: string;
  examineName: string;
}

function DetailResult() {
  const { title } = useCreateTitle();
  const [examineStartDateTime, setExamineStartDateTime] = useState<string>("");
  const [testingTargetType, setTestingTargetType] = useState<string>("");
  const [testingType, setTestingType] = useState<string>("");
  const [freeSetItemName, setFreeSetItemName] = useState<string>("");
  const [examineName, setExamineName] = useState<string>("");
  const [isOnOff, setIsOnOff] = useState<boolean>(false);

  const [info, setInfo] = useState<TestingInfoEntity | undefined>(undefined);
  const [freeSetInfo, setFreeSetInfo] = useState<
    FREE_SET_MESSAGE_DATA | undefined
  >(undefined);
  const [examineResult, setExamineResult] = useState<
    IExamineResult | undefined
  >();
  const tabs: ITab[] = useMemo(() => {
    if (testingType === "환경테스트") {
      return [{ title: "시험 진행" }, { title: "시험 정보" }];
    } else {
      return [
        { title: "시험 진행" },
        { title: "시험 정보" },
        { title: "시험 결과" }
      ];
    }
  }, [testingType]);

  const { selectedTab, handleTabSelected } = useTabs(tabs[0].title);
  const [getTestingInfo] = useGetTestingInfoLazyQuery({
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      if (data.getTestingInfo.ok && data.getTestingInfo.info) {
        if (data.getTestingInfo.info.examineSetting) {
          const newExamineSetting = JSON.parse(
            data.getTestingInfo.info.examineSetting
          );
          setFreeSetInfo(newExamineSetting);
        }
        if (data.getTestingInfo.info.examineResultJson) {
          const newExamineResult = JSON.parse(
            data.getTestingInfo.info.examineResultJson
          );
          setExamineResult({
            errorCode: newExamineResult.error_code ?? "",
            receiveResults: newExamineResult.receive_results ?? "",
            receptionDatetime: newExamineResult.reception_datetime ?? "",
            terminationClassification:
              newExamineResult.termination_classification ?? ""
          });
        }
        setInfo(data.getTestingInfo.info);
      } else if (data.getTestingInfo.error) {
        console.log(data.getTestingInfo.error);
      }
    }
  });

  const [getTestingManagerItemList] = useGetTestingManagerItemListLazyQuery({
    fetchPolicy: "no-cache",
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      if (
        data.getTestingManagerItemList.ok &&
        data.getTestingManagerItemList.list
      ) {
        const newIsOnOff =
          data.getTestingManagerItemList.list[0].cttOnOff === "오프라인"
            ? false
            : true;
        setIsOnOff(newIsOnOff);
      } else if (data.getTestingManagerItemList.error) {
        console.log(data.getTestingManagerItemList.error);
      }
    }
  });

  const channel = useMemo(() => {
    return new BroadcastChannel(TESTING_HISTORY_RESULT);
  }, []);

  useLayoutEffect(() => {
    channel.postMessage(OPEN_TESTING_HISTORY_RESULT_MESSAGE);
    channel.onmessage = (event) => {
      if (event.data && typeof event.data !== "string") {
        const data: PAYLOAD = event.data;
        setExamineStartDateTime(data.examineStartDateTime);
        setTestingTargetType(data.testingTargetType);
        setTestingType(data.testingType);
        setFreeSetItemName(data?.freeSetItemName ?? "");
        setExamineName(data.examineName);
      }
    };
  }, [channel]);

  useLayoutEffect(() => {
    getTestingInfo({
      variables: {
        examineStartDateTime
      }
    });
  }, [examineStartDateTime, getTestingInfo]);

  useLayoutEffect(() => {
    if (examineName) {
      getTestingManagerItemList({
        variables: {
          searchType: TestingManagerSearchType.ExamineName,
          searchValue: examineName,
          page: 1,
          take: 1
        }
      });
    }
  }, [getTestingManagerItemList, examineName]);

  return (
    <RecoilRoot>
      <Suspense fallback="...loading">
        <PopUpContainer>
          <PageTitle title={title} />
          <Tabs
            list={tabs}
            selectedTab={selectedTab}
            handleTabSelected={handleTabSelected}
          />
          <Section flex={10} padding="10px">
            {selectedTab === tabs[0].title && (
              <TestingStatusInfo
                examineStartDateTime={examineStartDateTime}
                testingType={testingType}
                isDevice={!!info}
                isOnOff={isOnOff}
              />
            )}
            {selectedTab === tabs[1].title && (
              <TestingInfo
                testingTargetType={testingTargetType}
                testingType={testingType}
                info={info}
                freeSetInfo={freeSetInfo}
                examineResult={examineResult}
              />
            )}
            {selectedTab === tabs[2]?.title && (
              <TestingResultInfo
                examineStartDateTime={examineStartDateTime}
                freeSetItemName={freeSetItemName}
              />
            )}
          </Section>
        </PopUpContainer>
      </Suspense>
    </RecoilRoot>
  );
}

export default DetailResult;
