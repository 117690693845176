import { atom, selector } from "recoil";

interface IIS_LOGIC_STATE {
  isEditMode: boolean;
  isSave: boolean;
}

export const isLogicStateAtoms = atom<IIS_LOGIC_STATE>({
  key: "isLogicState", // unique ID (with respect to other atoms/selectors)
  default: {
    isEditMode: false,
    isSave: false
  } // default value (aka initial value)
});

export const isActiveSelector = selector({
  key: "isActive",
  get: ({ get }) => {
    const { isEditMode, isSave } = get(isLogicStateAtoms);
    return !!isEditMode || !!isSave;
  }
});
