import { Link } from "react-router-dom";
import styled from "styled-components";
import routes from "../../routes/routes";
import CogIcon from "@iconify/icons-clarity/cog-solid";
import LeftDoubleArrowIcon from "@iconify/icons-material-symbols/keyboard-double-arrow-left";
import RightDoubleArrowIcon from "@iconify/icons-material-symbols/keyboard-double-arrow-right";
import SearchIcon from "@iconify/icons-teenyicons/search-property-outline";
import SignIcon from "@iconify/icons-fa-solid/file-signature";
import { Icon } from "@iconify/react";
import { useState } from "react";
import MenuProfile from "../menu-profile/menu-profile";
import logo from "../../images/logo.jpg";

const Title = styled.span<{ isClose?: boolean }>`
  transition: width 0.3s ease-in-out;
  opacity: ${(props) => (props.isClose ? "0" : "1")};
  width: ${(props) => (props.isClose ? "0px" : "")};
  height: ${(props) => (props.isClose ? "0px" : "")};
  font-size: 12px;
  font-weight: bold;
`;

const Container = styled.div<{ isClose?: boolean }>`
  transition: div 0.5s ease-in-out;
  display: flex;
  flex: ${(props) => (props.isClose ? "0" : "1")};
  background-color: ${(props) => props.theme.colors.navy};
  color: ${(props) => props.theme.colors.white};
  flex-direction: column;
  max-width: ${(props) => (props.isClose ? "80px" : "auto")};
  min-width: ${(props) => (props.isClose ? "" : "180px")};
`;

const Header = styled.div<{ isClose?: boolean }>`
  display: flex;
  flex: 1;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.navy};
  font-weight: bold;
  font-size: 14px;
  align-items: center;
  color: ${(props) => props.theme.colors.navy};
  :hover {
    color: ${(props) => props.theme.colors.green};
    cursor: pointer;
  }
  :active {
    span {
      transform: scale(0.99);
    }
  }
  padding: 5px 8px 5px ${(props) => (props.isClose ? "5px" : "0px")};
`;

const TitleBar = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  gap: 5px;
`;

const Emphasis = styled.div<{ isClose?: boolean }>`
  font-family: Goldman;
  font-size: 13px;
  transition: width 0.3s ease-in-out;
  opacity: ${(props) => (props.isClose ? "0" : "1")};
  width: ${(props) => (props.isClose ? "0px" : "")};
  height: ${(props) => (props.isClose ? "0px" : "")};
  min-width: ${(props) => (props.isClose ? "0px" : "max-content")};
`;

const List = styled.div`
  display: flex;
  flex: 10;
  flex-direction: column;
`;

const Image = styled.img<{ isClose?: boolean }>`
  display: flex;
  height: 30px;
  width: 40px;
  margin-left: ${(props) => (props.isClose ? "10%" : "10px")};
`;

const Menu = styled.div<{ isClose?: boolean }>`
  transition: all 0.2s ease-in-out;
  :last-child {
    margin-top: auto;
    border-top: 1px solid ${(props) => props.theme.colors.black};
  }
  :not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.colors.black};
  }
  display: flex;
  padding: 10px;
  cursor: pointer;
  :hover {
    background-color: ${(props) => props.theme.colors.lightGreen};
  }
  gap: ${(props) => (props.isClose ? "0px" : "40px")};
  font-weight: bold;
  align-items: center;
  font-size: 14px;
  justify-content: ${(props) => (props.isClose ? "center" : "flex-start")};
  svg {
    font-size: 20px;
    width: 20px;
  }
`;

function ListOfMenu() {
  const [isClose, setIsClose] = useState<boolean>(false);
  return (
    <Container isClose={isClose}>
      <Link to={routes.performTesting}>
        <Header isClose={isClose}>
          <TitleBar>
            <Image isClose={isClose} src={logo} alt="logo-image" />
            <Title isClose={isClose}>
              <Emphasis isClose={isClose}>{`적산열량계`}</Emphasis>
              <span>{`상호운용성 시험장치`}</span>
            </Title>
          </TitleBar>
        </Header>
        <MenuProfile isClose={isClose} />
      </Link>
      <List>
        <Link to={routes.performTesting}>
          <Menu isClose={isClose}>
            <Icon icon={SignIcon} />
            <Title isClose={isClose}>{`시험수행`}</Title>
          </Menu>
        </Link>
        <Link to={routes.mangeTesting}>
          <Menu isClose={isClose}>
            <Icon icon={SearchIcon} />
            <Title isClose={isClose}>{`시험관리`}</Title>
          </Menu>
        </Link>
        <Link to={routes.settings}>
          <Menu isClose={isClose}>
            <Icon icon={CogIcon} />
            <Title isClose={isClose}>{`환경설정`}</Title>
          </Menu>
        </Link>
        <Menu
          isClose={isClose}
          onClick={() => {
            setIsClose((isClose) => {
              if (isClose) {
                return false;
              } else {
                return true;
              }
            });
          }}
        >
          <Icon icon={isClose ? RightDoubleArrowIcon : LeftDoubleArrowIcon} />
          <Title isClose={isClose}>{`메뉴닫기`}</Title>
        </Menu>
      </List>
    </Container>
  );
}

export default ListOfMenu;
