import { useCallback } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";
import CheckCircle from "@iconify/icons-fa-solid/check-circle";
import ExclamationCircle from "@iconify/icons-fa-solid/exclamation-circle";
import ExclamationTriangle from "@iconify/icons-fa-solid/exclamation-triangle";
import InfoCircle from "@iconify/icons-fa-solid/info-circle";

interface IProps {
  messagetypes: MessageTypes;
  message?: string;
  isOpen: boolean;
  handleIsOpen: (value: boolean) => void;
  widget?: boolean;
}

export enum MessageTypes {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  WARNING = "WARNING",
  INFO = "INFO"
}

export const SuccessType = {
  title: "성공",
  iconColor: "#20bf6b",
  backgroundColor: "#b8e994",
  icon: CheckCircle
};

export const ErrorType = {
  title: "에러",
  iconColor: "#ee5253",
  backgroundColor: "#ffcccc",
  icon: ExclamationCircle
};

export const WarningType = {
  title: "경고",
  iconColor: "#fd9644",
  backgroundColor: "#fad390",
  icon: ExclamationTriangle
};
export const InfoType = {
  title: "정보",
  iconColor: "#45aaf2",
  backgroundColor: "#acdbfd",
  icon: InfoCircle
};

const Container = styled.div<{
  widget?: boolean;
  backgroundColor: string;
  isOpen: boolean;
}>`
  position: fixed;
  display: flex;
  justify-content: center;
  transition: all 0.3s ease-in-out, visibility 0.2s ease-in-out;
  flex: 1;
  left: ${props => (props.widget ? "10vw" : "40vw")};
  bottom: ${props => (props.isOpen ? "2vw" : "0vw")};
  background-color: ${props => props.backgroundColor};
  flex-direction: column;
  border-radius: 7px;
  width: ${props => (props.widget ? "80vw" : "20vw")};
  box-shadow: ${props => `30px 0px 0px -20px ${props.color} inset`};
  -webkit-box-shadow: ${props => `30px 0px 0px -20px ${props.color} inset`};
  -moz-box-shadow: ${props => `30px 0px 0px -20px ${props.color} inset`};
  visibility: ${props => (props.isOpen ? "visible" : "hidden")};
  z-index: 10;
`;

const TitleContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  padding: 5px;
`;

const TitleBar = styled.div`
  display: flex;
  align-items: center;
  flex: 6;
`;

const Title = styled.h3`
  margin-left: 2%;
`;

const SIcon = styled(Icon)<{ messagetypes: MessageTypes }>`
  margin-left: 5%;
  font-size: 20px;
  background-color: white;
  border-radius: 50%;
  clip-path: ${props =>
    props.messagetypes === MessageTypes.WARNING
      ? "polygon(50% 0%, 0% 100%, 100% 100%)"
      : "none"};
`;

const XButton = styled.button`
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  :hover {
    color: ${props => props.theme.colors.tomato};
  }
  :active {
    transform: scale(0.9);
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 0% 3% 3% 3%;
`;

const Content = styled.span`
  font-size: 13px;
`;

const ToastMessage = <P extends IProps>({
  messagetypes,
  message,
  isOpen,
  handleIsOpen,
  widget
}: P) => {
  const makeTypes = useCallback(() => {
    switch (messagetypes) {
      case MessageTypes.SUCCESS:
        return SuccessType;
      case MessageTypes.ERROR:
        return ErrorType;
      case MessageTypes.WARNING:
        return WarningType;
      case MessageTypes.INFO:
        return InfoType;
    }
  }, [messagetypes]);
  return (
    <Container
      backgroundColor={makeTypes().backgroundColor}
      color={makeTypes().iconColor}
      isOpen={isOpen}
      widget={widget}
    >
      <TitleContainer>
        <TitleBar>
          <SIcon
            icon={makeTypes().icon}
            color={makeTypes().iconColor}
            messagetypes={messagetypes}
          />
          <Title>{makeTypes().title}</Title>
        </TitleBar>
        <XButton
          onClick={() => {
            handleIsOpen(false);
          }}
        >
          X
        </XButton>
      </TitleContainer>
      <ContentContainer>
        <Content>{message}</Content>
      </ContentContainer>
    </Container>
  );
};

export default ToastMessage;
