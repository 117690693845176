import styled from "styled-components";

const PageContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  select {
    display: flex;
    flex: 1;
  }
`;

export default PageContainer;
