import * as React from "react";
import Collapsible from "react-collapsible";
import { Icon } from "@iconify/react";
import LockIcon from "@iconify/icons-mdi/lock";
import ArrowDownIcon from "@iconify/icons-material-symbols/keyboard-arrow-down-rounded";
import styled from "styled-components";
interface IIcon {
  open?: boolean;
}

export interface IProps {
  title?: string;
  children?: React.ReactNode;
}

const Container = styled.div`
  margin: 20px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  .Collapsible {
    width: 100%;
  }
  .Collapsible__trigger {
    display: block;
    cursor: pointer;
    padding: 12px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .Collapsible__contentInner {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 16px;
  }
`;

const ArrowIcon = styled(Icon)`
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.54);
  transition: all 200ms ease-out;
  transform: ${(props: IIcon) => (props.open ? "rotate(0.5turn)" : "")};
`;

const Trigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TriggerTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const Details = (props: IProps) => {
  const { title } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpen = React.useCallback((value: boolean) => {
    setIsOpen(value);
  }, []);

  return (
    <Container>
      <Collapsible
        lazyRender={true}
        onTriggerOpening={() => {
          handleOpen(true);
        }}
        onTriggerClosing={() => {
          handleOpen(false);
        }}
        trigger={
          <Trigger>
            <TriggerTitle>
              <Icon icon={LockIcon} />
              {title ?? "Start here"}
            </TriggerTitle>
            <ArrowIcon icon={ArrowDownIcon} open={isOpen} />
          </Trigger>
        }
        transitionTime={200}
        easing="ease-in-out"
      >
        {props.children}
      </Collapsible>
    </Container>
  );
};

export default Details;
