import Editor from "../../editor/editor";
import EditorContainer from "../../shared/editor-container";
import SSpan from "../../shared/s-span";
import Section from "../../shared/section";
import Subject from "../../shared/subject";
import Input from "../../shared/input";
import { useRecoilState, useRecoilValue } from "recoil";
import { freeSetForSaveAtoms } from "../../../recoil/free_set_for_save_atoms/free_set_for_save_atoms";
import { isActiveSelector } from "../../../recoil/is_logic_state_atoms/is_logic_state_atoms";

function TestingSetting() {
  const [freeSetStateForSave, setFreeSetStateForSave] =
    useRecoilState(freeSetForSaveAtoms);
  const isActive = useRecoilValue(isActiveSelector);

  return (
    <Section flex={1}>
      <Subject subject="시험 설정" />
      <EditorContainer flex={0}>
        <Editor filedName="타임아웃 설정">
          {isActive ? (
            <Input
              type="number"
              min={0}
              step={1}
              value={freeSetStateForSave.timeOutSetting}
              onChange={(event) => {
                if (event.currentTarget.value) {
                  setFreeSetStateForSave((pre) => {
                    return {
                      ...pre,
                      timeOutSetting: parseInt(event.currentTarget.value)
                    };
                  });
                }
              }}
            />
          ) : (
            <SSpan>{freeSetStateForSave.timeOutSetting}</SSpan>
          )}
          <SSpan>{`초`}</SSpan>
        </Editor>
        <Editor filedName="타임아웃 버퍼">
          <SSpan>{`± 최대`}</SSpan>
          {isActive ? (
            <Input
              type="number"
              min={0}
              step={1}
              value={freeSetStateForSave.timeOutBuffer}
              onChange={(event) => {
                if (event.currentTarget.value) {
                  setFreeSetStateForSave((pre) => {
                    return {
                      ...pre,
                      timeOutBuffer: parseInt(event.currentTarget.value)
                    };
                  });
                }
              }}
            />
          ) : (
            <SSpan>{freeSetStateForSave.timeOutBuffer}</SSpan>
          )}
          <SSpan>{`초`}</SSpan>
        </Editor>
      </EditorContainer>
    </Section>
  );
}

export default TestingSetting;
