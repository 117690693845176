import { ReactNode } from "react";
import styled from "styled-components";

interface IProps {
  filedName: string;
  children: ReactNode;
  required?: boolean;
  maxWidthForTitle?: string;
  minWidthForTitle?: string;
  minHeight?: string;
  gridNumber?: number;
  isTitleDisable?: boolean;
}

const Container = styled.div<{
  isWidth: boolean;
  minHeight?: string;
  gridNumber?: number;
}>`
  display: grid;
  min-height: ${(props) => (props.minHeight ? props.minHeight : "40px")};
  max-height: ${(props) => (props.minHeight ? props.minHeight : "40px")};
  grid-template-columns: 1fr ${(props) =>
      props.isWidth || props.gridNumber ? "10fr" : "4.5fr"};
  border-bottom: 1px solid ${(props) => props.theme.colors.frameGrey};
  grid-column: ${(props) =>
    props.gridNumber ? `${props.gridNumber} / span 2` : ""};
`;

const FieldName = styled.div<{
  maxWidthForTitle?: string;
  minWidthForTitle?: string;
  isTitleDisable?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  background-color: ${(props) =>
    props.isTitleDisable
      ? props.theme.colors.white
      : props.theme.colors.littleLightGrey};
  min-width: ${(props) => props.minWidthForTitle ?? "150px"};
  max-width: ${(props) => props.maxWidthForTitle ?? "150px"};
  white-space: pre-wrap;
`;

const Content = styled.div`
  display: flex;
  padding: 4px;
  align-items: center;
`;

const Point = styled.span`
  text-align: center;
  color: ${(props) => props.theme.colors.tomato};
`;

function Editor({
  filedName,
  maxWidthForTitle,
  minWidthForTitle,
  minHeight,
  gridNumber,
  isTitleDisable,
  children,
  required = false
}: IProps) {
  return (
    <Container
      minHeight={minHeight}
      isWidth={Boolean(maxWidthForTitle || minWidthForTitle)}
      gridNumber={gridNumber}
    >
      <FieldName
        maxWidthForTitle={maxWidthForTitle}
        minWidthForTitle={minWidthForTitle}
        isTitleDisable={isTitleDisable}
      >
        {required && <Point>*</Point>}
        <span>{filedName}</span>
      </FieldName>
      <Content>{children}</Content>
    </Container>
  );
}

export default Editor;
