import styled from "styled-components";
// import basicProfile from "../../images/basic-profile.jpg";
import { useReactiveVar } from "@apollo/client";
import listOfApolloVar from "../../utils/list_of_apollo_var";
import { IUserInfo } from "../../utils/jwt";

interface IProps {
  isClose: boolean;
}

const Container = styled.div`
  display: flex;
  flex: 1;
`;

// const PictureContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 10px;
// `;

// const Image = styled.img`
//   display: flex;
//   height: 60px;
//   width: 60px;
//   border-radius: 50%;
// `;

const Information = styled.div<{ isClose: boolean }>`
  transition: width 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  align-items: flex-start;
  justify-content: center;
  opacity: ${props => (props.isClose ? "0" : "1")};
  width: ${props => (props.isClose ? "0px" : "100%")};
`;

const Name = styled.div`
  color: ${props => props.theme.colors.green};
  font-weight: bold;
  font-size: 1.2rem;
  margin-left: 30px;
  text-align: left;
  width: 100%;
  justify-content: center;
`;

const Company = styled.div`
  text-align: right;
  padding-right: 30px;
  width: 100%;
`;

function MenuProfile({ isClose }: IProps) {
  const userInfo = useReactiveVar(listOfApolloVar.userInfoVar);

  function isIUserInfo(user: any): user is IUserInfo {
    return user && typeof user === "object" && "personName" in user;
  }

  return (
    <Container>
      {/* <PictureContainer>
        <Image src={basicProfile} alt="user-image" />
      </PictureContainer> */}
      <Information isClose={isClose}>
        <Name>
          {isIUserInfo(userInfo) && userInfo.personName ? (
            <span>{`${userInfo.personName} 님`}</span>
          ) : (
            <span>{`사용자 님`}</span>
          )}
        </Name>
        <Company>
          {isIUserInfo(userInfo) && userInfo.personDepartment ? (
            <span>{`${userInfo.personDepartment}`}</span>
          ) : (
            <div></div>
          )}
        </Company>
      </Information>
    </Container>
  );
}

export default MenuProfile;
