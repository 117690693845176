import Editor from "../../editor/editor";
import EditorContainer from "../../shared/editor-container";
import SSpan from "../../shared/s-span";
import Section from "../../shared/section";
import Subject from "../../shared/subject";
import Input from "../../shared/input";
import RadioBox from "../../shared/radio_box";
import { useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  DATA_METHOD_TYPE,
  ACTION_ON_ERROR_DETECTION_TYPE,
  DATA_GENERATOR_METHOD
} from "../../../recoil/free_set_atoms/free_set_atoms";
import LimitedBox from "../../shared/limited_box";
import { freeSetForSaveAtoms } from "../../../recoil/free_set_for_save_atoms/free_set_for_save_atoms";
import { isActiveSelector } from "../../../recoil/is_logic_state_atoms/is_logic_state_atoms";

function RtuLongTermExamination() {
  const [freeSetStateForSave, setFreeSetStateForSave] =
    useRecoilState(freeSetForSaveAtoms);
  const isActive = useRecoilValue(isActiveSelector);
  const [dataMethod, setDataMethod] = useState<DATA_METHOD_TYPE>(
    DATA_METHOD_TYPE.EXTERNAL_INPUT
  );
  const methodDetectedError = useMemo(() => {
    switch (freeSetStateForSave.actionOnErrorDetection) {
      case ACTION_ON_ERROR_DETECTION_TYPE.STOP:
        return "즉시 중단";
      case ACTION_ON_ERROR_DETECTION_TYPE.CONTINUE:
        return "검사 계속";
    }
  }, [freeSetStateForSave.actionOnErrorDetection]);

  const dataGeneratorMethodInCalorimeter = useMemo(() => {
    switch (freeSetStateForSave.dataGeneratorMethodInCalorimeter) {
      case DATA_GENERATOR_METHOD.AUTO_GENERATION:
        return "자동생성";
      case DATA_GENERATOR_METHOD.INSPECTOR_ASSIGNMENT:
        return "검사자 지정";
    }
  }, [freeSetStateForSave.dataGeneratorMethodInCalorimeter]);

  const dataGeneratorMethodInSensorSimulationSetting = useMemo((): string => {
    if (
      freeSetStateForSave.dataGeneratorMethodInSensorSimulation ===
      DATA_GENERATOR_METHOD.AUTO_GENERATION
    ) {
      return "자동생성";
    } else if (
      freeSetStateForSave.dataGeneratorMethodInSensorSimulation ===
      DATA_GENERATOR_METHOD.INSPECTOR_ASSIGNMENT
    ) {
      return "검사자 지정";
    }
    return "";
  }, [freeSetStateForSave.dataGeneratorMethodInSensorSimulation]);
  return (
    <Section flex={2}>
      <Subject subject="시험 설정" />
      <EditorContainer>
        <Editor filedName="타임아웃 설정">
          {isActive ? (
            <Input
              type="number"
              min={0}
              step={1}
              value={freeSetStateForSave.timeOutSetting}
              onChange={(event) => {
                if (event.currentTarget.value) {
                  setFreeSetStateForSave((pre) => {
                    return {
                      ...pre,
                      timeOutSetting: parseInt(event.currentTarget.value)
                    };
                  });
                }
              }}
            />
          ) : (
            <SSpan>{freeSetStateForSave.timeOutSetting}</SSpan>
          )}
          <SSpan>{`초`}</SSpan>
        </Editor>
        <Editor filedName="타임아웃 버퍼">
          <SSpan>{`± 최대`}</SSpan>
          {isActive ? (
            <Input
              type="number"
              min={0}
              step={1}
              value={freeSetStateForSave.timeOutBuffer}
              onChange={(event) => {
                if (event.currentTarget.value) {
                  setFreeSetStateForSave((pre) => {
                    return {
                      ...pre,
                      timeOutBuffer: parseInt(event.currentTarget.value)
                    };
                  });
                }
              }}
            />
          ) : (
            <SSpan>{freeSetStateForSave.timeOutBuffer}</SSpan>
          )}
          <SSpan>{`초`}</SSpan>
        </Editor>
        <Editor filedName="시험회차">
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.examineCount}
              min={1}
              step={1}
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  examineCount: parseInt(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.examineCount}</SSpan>
          )}
          <SSpan>{`회`}</SSpan>
        </Editor>
        <Editor filedName="에러 검출시 동작">
          {isActive ? (
            <RadioBox>
              <Input
                type="radio"
                id="stop"
                name="testing"
                value={ACTION_ON_ERROR_DETECTION_TYPE.STOP}
                checked={
                  freeSetStateForSave.actionOnErrorDetection ===
                  ACTION_ON_ERROR_DETECTION_TYPE.STOP
                }
                onChange={() =>
                  setFreeSetStateForSave((pre) => ({
                    ...pre,
                    actionOnErrorDetection: ACTION_ON_ERROR_DETECTION_TYPE.STOP
                  }))
                }
              />
              <label htmlFor="stop">{`즉시 중단`}</label>
              <Input
                type="radio"
                id="continue"
                name="testing"
                value={ACTION_ON_ERROR_DETECTION_TYPE.CONTINUE}
                checked={
                  freeSetStateForSave.actionOnErrorDetection ===
                  ACTION_ON_ERROR_DETECTION_TYPE.CONTINUE
                }
                onChange={() =>
                  setFreeSetStateForSave((pre) => ({
                    ...pre,
                    actionOnErrorDetection:
                      ACTION_ON_ERROR_DETECTION_TYPE.CONTINUE
                  }))
                }
              />
              <label htmlFor="continue">{`검사 계속`}</label>
            </RadioBox>
          ) : (
            <SSpan>{methodDetectedError}</SSpan>
          )}
        </Editor>
      </EditorContainer>
      <Subject subject="열량계 시뮬레이터 설정" />
      <EditorContainer>
        <Editor filedName="데이터 생성방식" gridNumber={1}>
          {isActive ? (
            <RadioBox>
              <Input
                type="radio"
                id="isAutoCreativeInCalorimeter"
                name="dataGeneratorMethodInCalorimeter"
                value={freeSetStateForSave.dataGeneratorMethodInCalorimeter}
                checked={
                  freeSetStateForSave.dataGeneratorMethodInCalorimeter ===
                  DATA_GENERATOR_METHOD.AUTO_GENERATION
                }
                onChange={() =>
                  setFreeSetStateForSave((pre) => ({
                    ...pre,
                    dataGeneratorMethodInCalorimeter:
                      DATA_GENERATOR_METHOD.AUTO_GENERATION
                  }))
                }
              />
              <label htmlFor="isAutoCreativeInCalorimeter">{`자동생성`}</label>
              <Input
                type="radio"
                id="isSetTesterInCalorimeter"
                name="dataGeneratorMethodInCalorimeter"
                value={freeSetStateForSave.dataGeneratorMethodInCalorimeter}
                checked={
                  freeSetStateForSave.dataGeneratorMethodInCalorimeter ===
                  DATA_GENERATOR_METHOD.INSPECTOR_ASSIGNMENT
                }
                onChange={() =>
                  setFreeSetStateForSave((pre) => ({
                    ...pre,
                    dataGeneratorMethodInCalorimeter:
                      DATA_GENERATOR_METHOD.INSPECTOR_ASSIGNMENT
                  }))
                }
              />
              <label htmlFor="isSetTesterInCalorimeter">{`검사자 지정`}</label>
            </RadioBox>
          ) : (
            <SSpan>{dataGeneratorMethodInCalorimeter}</SSpan>
          )}
        </Editor>
        <Editor filedName="누적열량">
          <SSpan>{`최소`}</SSpan>
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.minCumulativeHeat}
              step="any"
              min="0"
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  minCumulativeHeat: parseFloat(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.minCumulativeHeat}</SSpan>
          )}
          <LimitedBox $minWidth={"84px"}>
            <SSpan>{`Gcal ~`}</SSpan>
            <SSpan>{`최대`}</SSpan>
          </LimitedBox>
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.maxCumulativeHeat}
              step="any"
              min={freeSetStateForSave.minCumulativeHeat}
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  maxCumulativeHeat: parseFloat(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.maxCumulativeHeat}</SSpan>
          )}
          <SSpan $minWidth="40px">{`Gcal`}</SSpan>
        </Editor>
        <Editor filedName="순간열량">
          <SSpan>{`최소`}</SSpan>
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.minInstantaneousHeat}
              step="any"
              min={0}
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  minInstantaneousHeat: parseFloat(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.minInstantaneousHeat}</SSpan>
          )}
          <LimitedBox $minWidth={"97px"}>
            <SSpan>{`Gcal/h ~`}</SSpan>
            <SSpan>{`최대`}</SSpan>
          </LimitedBox>
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.maxInstantaneousHeat}
              step="any"
              min={freeSetStateForSave.minInstantaneousHeat}
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  maxInstantaneousHeat: parseFloat(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.maxInstantaneousHeat}</SSpan>
          )}
          <SSpan $minWidth="40px">{`Gcal/h`}</SSpan>
        </Editor>
        <Editor filedName="누적유량">
          <SSpan>{`최소`}</SSpan>
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.minCumulativeFlowRate}
              step="any"
              min={0}
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  minCumulativeFlowRate: parseFloat(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.minCumulativeFlowRate}</SSpan>
          )}
          <LimitedBox $minWidth={"84px"}>
            <SSpan>{`m³ ~`}</SSpan>
            <SSpan>{`최대`}</SSpan>
          </LimitedBox>
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.maxCumulativeFlowRate}
              step="any"
              min={freeSetStateForSave.minCumulativeFlowRate}
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  maxCumulativeFlowRate: parseFloat(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.maxCumulativeFlowRate}</SSpan>
          )}
          <SSpan $minWidth="40px">{`m³`}</SSpan>
        </Editor>
        <Editor filedName="순간유량">
          <SSpan>{`최소`}</SSpan>
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.minInstantaneousFlowRate}
              step="any"
              min={0}
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  minInstantaneousFlowRate: parseFloat(
                    event.currentTarget.value
                  )
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.minInstantaneousFlowRate}</SSpan>
          )}
          <LimitedBox $minWidth={"97px"}>
            <SSpan>{`m³/h ~`}</SSpan>
            <SSpan>{`최대`}</SSpan>
          </LimitedBox>
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.maxInstantaneousFlowRate}
              step="any"
              min={freeSetStateForSave.minInstantaneousFlowRate}
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  maxInstantaneousFlowRate: parseFloat(
                    event.currentTarget.value
                  )
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.maxInstantaneousFlowRate}</SSpan>
          )}
          <SSpan $minWidth="40px">{`m³/h`}</SSpan>
        </Editor>
        <Editor filedName="송류온도">
          <SSpan>{`최소`}</SSpan>
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.minOutletTemperature}
              step="any"
              min={0}
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  minOutletTemperature: parseFloat(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.minOutletTemperature}</SSpan>
          )}
          <LimitedBox $minWidth="84px">
            <SSpan>{`℃ ~`}</SSpan>
            <SSpan>{`최대`}</SSpan>
          </LimitedBox>
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.maxOutletTemperature}
              step="any"
              min={freeSetStateForSave.minOutletTemperature}
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  maxOutletTemperature: parseFloat(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.maxOutletTemperature}</SSpan>
          )}
          <SSpan $minWidth="40px">{`℃`}</SSpan>
        </Editor>
        <Editor filedName="환류온도">
          <SSpan>{`최소`}</SSpan>
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.minReturnTemperature}
              step="any"
              min={0}
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  minReturnTemperature: parseFloat(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.minReturnTemperature}</SSpan>
          )}
          <LimitedBox $minWidth="97px">
            <SSpan>{`℃ ~`}</SSpan>
            <SSpan>{`최대`}</SSpan>
          </LimitedBox>
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.maxReturnTemperature}
              step="any"
              min={freeSetStateForSave.minReturnTemperature}
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  maxReturnTemperature: parseFloat(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.maxReturnTemperature}</SSpan>
          )}
          <SSpan $minWidth="40px">{`℃`}</SSpan>
        </Editor>
        <Editor filedName="송류압력">
          <SSpan>{`최소`}</SSpan>
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.minOutletPressure}
              step="any"
              min={0}
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  minOutletPressure: parseFloat(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.minOutletPressure}</SSpan>
          )}

          <LimitedBox $minWidth="84px">
            <SSpan>{`bar ~`}</SSpan>
            <SSpan>{`최대`}</SSpan>
          </LimitedBox>
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.maxOutletPressure}
              step="any"
              min={freeSetStateForSave.minOutletPressure}
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  maxOutletPressure: parseFloat(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.maxOutletPressure}</SSpan>
          )}
          <SSpan $minWidth="40px">{`bar`}</SSpan>
        </Editor>
        <Editor filedName="환류압력">
          <SSpan>{`최소`}</SSpan>
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.minReturnPressure}
              step="any"
              min={0}
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  minReturnPressure: parseFloat(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.minReturnPressure}</SSpan>
          )}
          <LimitedBox $minWidth="97px">
            <SSpan>{`bar ~`}</SSpan>
            <SSpan>{`최대`}</SSpan>
          </LimitedBox>
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.maxReturnPressure}
              step="any"
              min={0}
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  maxReturnPressure: parseFloat(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.maxReturnPressure}</SSpan>
          )}
          <SSpan $minWidth="40px">{`bar`}</SSpan>
        </Editor>
      </EditorContainer>
      <Subject subject="센서 시뮬레이터 설정" />
      <EditorContainer>
        <Editor filedName="데이터 생성방식">
          {isActive ? (
            <RadioBox>
              <Input
                type="radio"
                id="isAutoCreative"
                name="dataGeneratorMethodInSensorSimulationSetting"
                value={DATA_GENERATOR_METHOD.AUTO_GENERATION}
                checked={
                  freeSetStateForSave.dataGeneratorMethodInSensorSimulation ===
                  DATA_GENERATOR_METHOD.AUTO_GENERATION
                }
                onChange={() =>
                  setFreeSetStateForSave((pre) => ({
                    ...pre,
                    dataGeneratorMethodInSensorSimulation:
                      DATA_GENERATOR_METHOD.AUTO_GENERATION
                  }))
                }
              />
              <label htmlFor="isAutoCreative">{`자동생성`}</label>
              <Input
                type="radio"
                id="isSetTester"
                name="dataGeneratorMethodInSensorSimulationSetting"
                value={DATA_GENERATOR_METHOD.INSPECTOR_ASSIGNMENT}
                checked={
                  freeSetStateForSave.dataGeneratorMethodInSensorSimulation ===
                  DATA_GENERATOR_METHOD.INSPECTOR_ASSIGNMENT
                }
                onChange={() =>
                  setFreeSetStateForSave((pre) => ({
                    ...pre,
                    dataGeneratorMethodInSensorSimulation:
                      DATA_GENERATOR_METHOD.INSPECTOR_ASSIGNMENT
                  }))
                }
              />
              <label htmlFor="isSetTester">{`검사자 지정`}</label>
            </RadioBox>
          ) : (
            <SSpan>{dataGeneratorMethodInSensorSimulationSetting}</SSpan>
          )}
        </Editor>
        <Editor filedName="2차센서 수량">
          {isActive ? (
            <select
              name="secondarySensorCount"
              value={freeSetStateForSave.secondarySensorQuantity}
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  secondarySensorQuantity: parseInt(event.currentTarget.value)
                }))
              }
            >
              <option value="1">{`1`}</option>
              <option value="2">{`2`}</option>
              <option value="3">{`3`}</option>
              <option value="4">{`4`}</option>
              <option value="5">{`5`}</option>
            </select>
          ) : (
            <SSpan>{freeSetStateForSave.secondarySensorQuantity}</SSpan>
          )}
        </Editor>
        <Editor filedName="2차측 송류온도 #1">
          <SSpan>{`최소`}</SSpan>
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.minSecondaryOutletTemperature}
              step="any"
              min="0"
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  minSecondaryOutletTemperature: parseFloat(
                    event.currentTarget.value
                  )
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.minSecondaryOutletTemperature}</SSpan>
          )}
          <SSpan $minWidth="40px">{`℃`}</SSpan>
          <SSpan>{`최대`}</SSpan>
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.maxSecondaryOutletTemperature}
              step="any"
              min="0"
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  maxSecondaryOutletTemperature: parseFloat(
                    event.currentTarget.value
                  )
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.maxSecondaryOutletTemperature}</SSpan>
          )}
          <SSpan $minWidth="40px">{`℃`}</SSpan>
        </Editor>
        <Editor filedName="2차측 환류온도 #1">
          <SSpan>{`최소`}</SSpan>
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.minSecondaryReturnTemperature}
              step="any"
              min="0"
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  minSecondaryReturnTemperature: parseFloat(
                    event.currentTarget.value
                  )
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.minSecondaryReturnTemperature}</SSpan>
          )}
          <SSpan $minWidth="40px">{`℃`}</SSpan>
          <SSpan>{`최대`}</SSpan>
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.maxSecondaryReturnTemperature}
              step="any"
              min="0"
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  maxSecondaryReturnTemperature: parseFloat(
                    event.currentTarget.value
                  )
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.maxSecondaryReturnTemperature}</SSpan>
          )}
          <SSpan $minWidth="40px">{`℃`}</SSpan>
        </Editor>
        <Editor filedName="2차측 송류압력 #1">
          <SSpan>{`최소`}</SSpan>
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.minSecondaryOutletPressure}
              step="any"
              min="0"
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  minSecondaryOutletPressure: parseFloat(
                    event.currentTarget.value
                  )
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.minSecondaryOutletPressure}</SSpan>
          )}
          <SSpan $minWidth="40px">{`bar`}</SSpan>
          <SSpan>{`최대`}</SSpan>
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.maxSecondaryOutletPressure}
              step="any"
              min="0"
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  maxSecondaryOutletPressure: parseFloat(
                    event.currentTarget.value
                  )
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.maxSecondaryOutletPressure}</SSpan>
          )}
          <SSpan $minWidth="40px">{`bar`}</SSpan>
        </Editor>
        <Editor filedName="2차측 환류압력 #1">
          <SSpan>{`최소`}</SSpan>
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.minSecondaryReturnPressure}
              step="any"
              min="0"
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  minSecondaryReturnPressure: parseFloat(
                    event.currentTarget.value
                  )
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.minSecondaryReturnPressure}</SSpan>
          )}
          <SSpan $minWidth="40px">{`bar`}</SSpan>
          <SSpan>{`최대`}</SSpan>
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.maxSecondaryReturnPressure}
              step="any"
              min="0"
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  maxSecondaryReturnPressure: parseFloat(
                    event.currentTarget.value
                  )
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.maxSecondaryReturnPressure}</SSpan>
          )}
          <SSpan $minWidth="40px">{`bar`}</SSpan>
        </Editor>
        {dataMethod !== DATA_METHOD_TYPE.EXTERNAL_INPUT && (
          <>
            <Editor filedName="2차측 송류온도 #2">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 환류온도 #2">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류압력 #2">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #2">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
          </>
        )}
        {dataMethod === DATA_METHOD_TYPE.UN_USED && (
          <>
            <Editor filedName="2차측 송류온도 #3">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 환류온도 #3">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류압력 #3">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #3">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #4">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 환류온도 #4">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류압력 #4">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #4">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #5">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 환류온도 #5">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류압력 #5">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #5">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #6">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 환류온도 #6">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류압력 #6">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #6">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #7">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 환류온도 #7">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류압력 #7">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #7">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #8">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 환류온도 #8">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류압력 #8">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #8">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #9">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 환류온도 #9">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류압력 #9">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #9">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #10">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 환류온도 #10">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류압력 #10">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #10">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
          </>
        )}
        <Editor filedName="사용자시설 온도">
          <SSpan>{`최소`}</SSpan>
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.minUserFacilityTemperature}
              step="any"
              min="0"
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  minUserFacilityTemperature: parseFloat(
                    event.currentTarget.value
                  )
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.minUserFacilityTemperature}</SSpan>
          )}
          <SSpan $minWidth="40px">{`℃`}</SSpan>
          <SSpan>{`최대`}</SSpan>
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.maxUserFacilityTemperature}
              step="any"
              min="0"
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  maxUserFacilityTemperature: parseFloat(
                    event.currentTarget.value
                  )
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.maxUserFacilityTemperature}</SSpan>
          )}
          <SSpan $minWidth="40px">{`℃`}</SSpan>
        </Editor>
        <Editor filedName="사용자시설 습도">
          <SSpan>{`최소`}</SSpan>
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.minUserFacilityHumidity}
              step="any"
              min="0"
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  minUserFacilityHumidity: parseFloat(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.minUserFacilityHumidity}</SSpan>
          )}
          <SSpan $minWidth="40px">{`%`}</SSpan>
          <SSpan>{`최대`}</SSpan>
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.maxUserFacilityHumidity}
              step="any"
              min="0"
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  maxUserFacilityHumidity: parseFloat(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.maxUserFacilityHumidity}</SSpan>
          )}
          <SSpan $minWidth="40px">{`%`}</SSpan>
        </Editor>
      </EditorContainer>
    </Section>
  );
}

export default RtuLongTermExamination;
