import { memo, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { TestingFreeSetNameEntity } from "../../../../__generated__/generated";
import routes from "../../../../routes/routes";
import Editor from "../../../editor/editor";
import Button from "../../../shared/button";
import ContentContainer from "../../../shared/content_container";
import {
  OPEN_SAVE_CHANNEL_MESSAGE,
  SUCCESS_MESSAGE
} from "../../save_testing_preset_dialog/save_testing_preset_dialog";
import {
  FREE_SET_MESSAGE_DATA,
  freeSetAtoms
} from "../../../../recoil/free_set_atoms/free_set_atoms";
import { useRecoilState, useSetRecoilState } from "recoil";
import { isLogicStateAtoms } from "../../../../recoil/is_logic_state_atoms/is_logic_state_atoms";
import { freeSetForSaveAtoms } from "../../../../recoil/free_set_for_save_atoms/free_set_for_save_atoms";

interface IProps {
  list: TestingFreeSetNameEntity[];
}

function FreeSetController({ list }: IProps) {
  const setFreesetState = useSetRecoilState(freeSetAtoms);
  const [freeSetStateForSaveState, setFreeSetStateForSaveState] =
    useRecoilState(freeSetForSaveAtoms);
  const [isOpenSaveChannel, setIsOpenSaveChannel] = useState<boolean>(false);
  const [isLogicState, setIsLogicState] = useRecoilState(isLogicStateAtoms);
  const channel = useMemo(() => {
    return new BroadcastChannel("SAVE_FREE_SET");
  }, []);

  useEffect(() => {
    if (isOpenSaveChannel && channel) {
      const data: FREE_SET_MESSAGE_DATA = {
        testingTargetType: freeSetStateForSaveState.testingTargetType,
        testingType: freeSetStateForSaveState.testingType,
        freeSetItemName: freeSetStateForSaveState.freeSetItemName,
        timeOutSetting: freeSetStateForSaveState.timeOutSetting,
        timeOutBuffer: freeSetStateForSaveState.timeOutBuffer,
        startDateOfInquiry: freeSetStateForSaveState.startDateOfInquiry,
        startHourOfInquiry: freeSetStateForSaveState.startHourOfInquiry,
        startMinOfInquiry: freeSetStateForSaveState.startMinOfInquiry,
        calorieValueGenerationMethod:
          freeSetStateForSaveState.calorieValueGenerationMethod,
        calorificValueSetting: freeSetStateForSaveState.calorificValueSetting,
        calorificValueIncrement:
          freeSetStateForSaveState.calorificValueIncrement,
        temperatureValueGenerationMethod:
          freeSetStateForSaveState.temperatureValueGenerationMethod,
        temperatureSetting: freeSetStateForSaveState.temperatureSetting,
        temperatureIncrement: freeSetStateForSaveState.temperatureIncrement,
        examineCount: freeSetStateForSaveState.examineCount,
        actionOnErrorDetection: freeSetStateForSaveState.actionOnErrorDetection,
        dataGeneratorMethodInCalorimeter:
          freeSetStateForSaveState.dataGeneratorMethodInCalorimeter,
        cumulativeHeat: freeSetStateForSaveState.cumulativeHeat,
        cumulativeFlowRate: freeSetStateForSaveState.cumulativeFlowRate,
        instantaneousHeat: freeSetStateForSaveState.instantaneousHeat,
        instantaneousFlowRate: freeSetStateForSaveState.instantaneousFlowRate,
        outletTemperature: freeSetStateForSaveState.outletTemperature,
        returnTemperature: freeSetStateForSaveState.returnTemperature,
        outletPressure: freeSetStateForSaveState.outletPressure,
        returnPressure: freeSetStateForSaveState.returnPressure,
        dataGeneratorMethodInSensorSimulation:
          freeSetStateForSaveState.dataGeneratorMethodInSensorSimulation,
        secondarySensorQuantity:
          freeSetStateForSaveState.secondarySensorQuantity,
        secondaryOutletTemperature:
          freeSetStateForSaveState.secondaryOutletTemperature,
        minSecondaryOutletTemperature:
          freeSetStateForSaveState.minSecondaryOutletTemperature,
        maxSecondaryOutletTemperature:
          freeSetStateForSaveState.maxSecondaryOutletTemperature,
        secondaryReturnTemperature:
          freeSetStateForSaveState.secondaryReturnTemperature,
        minSecondaryReturnTemperature:
          freeSetStateForSaveState.minSecondaryReturnTemperature,
        maxSecondaryReturnTemperature:
          freeSetStateForSaveState.maxSecondaryReturnTemperature,
        secondaryOutletPressure:
          freeSetStateForSaveState.secondaryOutletPressure,
        minSecondaryOutletPressure:
          freeSetStateForSaveState.minSecondaryOutletPressure,
        maxSecondaryOutletPressure:
          freeSetStateForSaveState.maxSecondaryOutletPressure,
        secondaryReturnPressure:
          freeSetStateForSaveState.secondaryReturnPressure,
        minSecondaryReturnPressure:
          freeSetStateForSaveState.minSecondaryReturnPressure,
        maxSecondaryReturnPressure:
          freeSetStateForSaveState.maxSecondaryReturnPressure,
        userFacilityTemperature:
          freeSetStateForSaveState.userFacilityTemperature,
        minUserFacilityTemperature:
          freeSetStateForSaveState.minUserFacilityTemperature,
        maxUserFacilityTemperature:
          freeSetStateForSaveState.maxUserFacilityTemperature,
        userFacilityHumidity: freeSetStateForSaveState.userFacilityHumidity,
        minUserFacilityHumidity:
          freeSetStateForSaveState.minUserFacilityHumidity,
        maxUserFacilityHumidity:
          freeSetStateForSaveState.maxUserFacilityHumidity,
        minCumulativeHeat: freeSetStateForSaveState.minCumulativeHeat,
        maxCumulativeHeat: freeSetStateForSaveState.maxCumulativeHeat,
        minInstantaneousHeat: freeSetStateForSaveState.minInstantaneousHeat,
        maxInstantaneousHeat: freeSetStateForSaveState.maxInstantaneousHeat,
        minCumulativeFlowRate: freeSetStateForSaveState.minCumulativeFlowRate,
        maxCumulativeFlowRate: freeSetStateForSaveState.maxCumulativeFlowRate,
        minInstantaneousFlowRate:
          freeSetStateForSaveState.minInstantaneousFlowRate,
        maxInstantaneousFlowRate:
          freeSetStateForSaveState.maxInstantaneousFlowRate,
        minOutletTemperature: freeSetStateForSaveState.minOutletTemperature,
        maxOutletTemperature: freeSetStateForSaveState.maxOutletTemperature,
        minReturnTemperature: freeSetStateForSaveState.minReturnTemperature,
        maxReturnTemperature: freeSetStateForSaveState.maxReturnTemperature,
        minOutletPressure: freeSetStateForSaveState.minOutletPressure,
        maxOutletPressure: freeSetStateForSaveState.maxOutletPressure,
        minReturnPressure: freeSetStateForSaveState.minReturnPressure,
        maxReturnPressure: freeSetStateForSaveState.maxReturnPressure
      };
      channel.postMessage(data);
      setIsOpenSaveChannel(false);
    }
  }, [isOpenSaveChannel, channel, freeSetStateForSaveState]);

  useLayoutEffect(() => {
    if (channel) {
      channel.onmessage = (event) => {
        if (event.data === OPEN_SAVE_CHANNEL_MESSAGE) {
          setIsOpenSaveChannel(true);
        }
        if (event.data === SUCCESS_MESSAGE) {
          setFreesetState(freeSetStateForSaveState);
          setIsLogicState((pre) => ({ ...pre, isSave: false }));
        }
      };
    }
  }, [channel, setIsLogicState, setFreesetState, freeSetStateForSaveState]);

  return (
    <Editor filedName="시험 프리셋">
      <ContentContainer>
        <select
          name="testing_preset"
          id="testing_preset"
          value={freeSetStateForSaveState.freeSetItemName}
          disabled={isLogicState.isEditMode}
          onChange={(event) => {
            setFreesetState((pre) => {
              return { ...pre, freeSetItemName: event.currentTarget.value };
            });
            setFreeSetStateForSaveState((pre) => {
              return { ...pre, freeSetItemName: event.currentTarget.value };
            });
          }}
        >
          {list.map((item) => (
            <option
              value={item.examineFreeSetName}
              key={item.examineFreeSetName}
            >
              {item.examineFreeSetName}
            </option>
          ))}
        </select>
        <Button
          maxWidth="40px"
          onClick={() => {
            if (isLogicState.isEditMode) {
              const w = 600;
              const h = 150;
              const y = window.outerHeight / 2 + window.screenY - h / 2;
              const x = window.outerWidth / 2 + window.screenX - w / 2;
              window.open(
                `${routes.mangeTesting}/${routes.saveTestingPreset}`,
                "",
                `width=${w}, height=${h}, scrollbars=yes, top=${y}, left=${x}`
              );
              setIsLogicState((pre) => {
                return { ...pre, isEditMode: false, isSave: true };
              });
            } else {
              setIsLogicState((pre) => {
                return { ...pre, isEditMode: true };
              });
            }
          }}
        >
          {isLogicState.isEditMode ? "저장" : `수정`}
        </Button>
        <Button
          maxWidth="40px"
          onClick={() => {
            if (isLogicState.isEditMode) {
              setIsLogicState((pre) => {
                return { ...pre, isEditMode: false };
              });
            } else {
              window.open(
                `${routes.mangeTesting}/${routes.loadTestingPreset}`,
                "",
                "width=600, height=400, scrollbars=yes"
              );
            }
          }}
        >
          {isLogicState.isEditMode ? "취소" : `삭제`}
        </Button>
      </ContentContainer>
    </Editor>
  );
}

export default memo(FreeSetController);
