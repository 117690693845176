/* eslint-disable @typescript-eslint/no-redeclare */
import { atom } from "recoil";

export interface TEST_DEVICE_ATOMS_TYPE {
  examineName: string;
  endpointId?: number;
  rtuMeasurementMinCycle?: string;
  isOnOff: boolean;
}

export const testDeviceAtoms = atom<TEST_DEVICE_ATOMS_TYPE>({
  key: "testDeviceState", // unique ID (with respect to other atoms/selectors)
  default: {
    examineName: "",
    rtuMeasurementMinCycle: "",
    isOnOff: false
  } // default value (aka initial value)
});
