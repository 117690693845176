import styled from "styled-components";
import Excel, { IExcelProps } from "../shared/excel";
import Subject from "../shared/subject";
import HideColumn, { IHideColumnProps } from "./hide-column";
import PageSet, { IPageSetProps } from "./page-set";

export interface ITableTitleBarProps<P extends object>
  extends Partial<IHideColumnProps<P>>,
    Partial<IPageSetProps>,
    Partial<IExcelProps> {
  title: string;
  total?: number;
  isPeople?: boolean;
  children?: JSX.Element;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  span {
    width: max-content;
  }
  gap: 2%;
  padding: 10px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 10px;
`;

const RightMenuContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: 10px;
  user-select: none;
  align-items: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
`;

function TableTitleBar<P extends object>({
  title,
  total,
  isPeople,
  columns,
  toggleHideColumn,
  take,
  handleTake,
  downloadExcel,
  children
}: ITableTitleBarProps<P>) {
  return (
    <Container>
      <TitleContainer>
        <Subject subject={title} />
        {total !== undefined && (
          <span>{`(전체 ${total}${isPeople ? "명" : "개"})`}</span>
        )}
      </TitleContainer>
      <RightMenuContainer>
        {columns && toggleHideColumn && (
          <HideColumn
            columns={columns}
            toggleHideColumn={toggleHideColumn}
            title={title}
          />
        )}
        {children}
        {take && handleTake && <PageSet take={take} handleTake={handleTake} />}
        {downloadExcel && <Excel downloadExcel={downloadExcel} />}
      </RightMenuContainer>
    </Container>
  );
}

export default TableTitleBar;
