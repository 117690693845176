import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SToastContainer = styled(ToastContainer)`
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  &&&.Toastify__toast-container {
  }
  .Toastify__toast {
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
    background-color: ${(props) => props.theme.colors.lemon};
  }
  .Toastify__toast-theme--colored.Toastify__toast--default {
  }
  .Toastify__toast-theme--colored.Toastify__toast--info {
  }
  .Toastify__toast-theme--colored.Toastify__toast--success {
    color: black;
    svg {
      color: #20bf6b;
    }
    background-color: #b8e994;
    ::before {
      top: 0px;
      left: 0px;
      position: absolute;
      display: flex;
      flex: 1;
      background-color: #20bf6b;
      height: 100%;
      width: 3%;
      content: "";
    }
  }
  .Toastify__toast-theme--colored.Toastify__toast--warning {
  }
  .Toastify__toast-theme--colored.Toastify__toast--error {
    color: black;
    svg {
      color: ${(props) => props.theme.colors.darkRed};
    }
    background-color: #ffcccc;
    ::before {
      top: 0px;
      left: 0px;
      position: absolute;
      display: flex;
      flex: 1;
      background-color: ${(props) => props.theme.colors.darkRed};
      height: 100%;
      width: 3%;
      content: "";
    }
  }
`;

export default SToastContainer;
