import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { verifyToken } from "../utils/jwt";
import apolloTypePolicies from "./apollo-type-policies";
import listOfApolloVar, { TOKEN } from "../utils/list_of_apollo_var";

const DEV_PORT = 4000;

const PRODUCTION = "production";

const URI = () => {
  if (process.env.NODE_ENV === PRODUCTION) {
    const { hostname, port } = window.location;
    if (window.location.protocol !== "http:") {
      return `https://${hostname}/graphql`;
    }
    return `http://${hostname}:${port}/graphql`;
  }
  return `http://localhost:${DEV_PORT}/graphql`;
};

export const logUserIn = (token: string) => {
  localStorage.setItem(TOKEN, token);
  listOfApolloVar.userInfoVar(verifyToken(token));
  listOfApolloVar.isLoggedInVar(true);
};

export const logUserOut = () => {
  localStorage.removeItem(TOKEN);
  window.location.reload();
  listOfApolloVar.isLoggedInVar(false);
};

const httpLink = createHttpLink({
  uri: URI()
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: localStorage.getItem(TOKEN)
    }
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: apolloTypePolicies
  })
});
