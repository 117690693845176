import Editor from "../../editor/editor";
import EditorContainer from "../../shared/editor-container";
import SSpan from "../../shared/s-span";
import Section from "../../shared/section";
import Subject from "../../shared/subject";
import Input from "../../shared/input";
import RadioBox from "../../shared/radio_box";
import { useMemo, useState } from "react";
import {
  DATA_METHOD_TYPE,
  DATA_GENERATOR_METHOD
} from "../../../recoil/free_set_atoms/free_set_atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import { isActiveSelector } from "../../../recoil/is_logic_state_atoms/is_logic_state_atoms";
import { freeSetForSaveAtoms } from "../../../recoil/free_set_for_save_atoms/free_set_for_save_atoms";

function RtuRealTimeRead() {
  const [freeSetStateForSave, setFreeSetStateForSave] =
    useRecoilState(freeSetForSaveAtoms);
  const [dataMethod, _] = useState<DATA_METHOD_TYPE>(
    DATA_METHOD_TYPE.EXTERNAL_INPUT
  );
  const isActive = useRecoilValue(isActiveSelector);

  const dataGeneratorMethodInCalorimeter = useMemo(() => {
    switch (freeSetStateForSave.dataGeneratorMethodInCalorimeter) {
      case DATA_GENERATOR_METHOD.AUTO_GENERATION:
        return "자동생성";
      case DATA_GENERATOR_METHOD.INSPECTOR_ASSIGNMENT:
        return "검사자 지정";
    }
  }, [freeSetStateForSave.dataGeneratorMethodInCalorimeter]);

  const dataGeneratorMethodInSensorSimulationSetting = useMemo((): string => {
    if (
      freeSetStateForSave.dataGeneratorMethodInSensorSimulation ===
      DATA_GENERATOR_METHOD.AUTO_GENERATION
    ) {
      return "자동생성";
    } else if (
      freeSetStateForSave.dataGeneratorMethodInSensorSimulation ===
      DATA_GENERATOR_METHOD.INSPECTOR_ASSIGNMENT
    ) {
      return "검사자 지정";
    }
    return "";
  }, [freeSetStateForSave.dataGeneratorMethodInSensorSimulation]);
  return (
    <Section flex={2}>
      <Subject subject="시험 설정" />
      <EditorContainer>
        <Editor filedName="타임아웃 설정">
          {isActive ? (
            <Input
              type="number"
              min={0}
              step={1}
              value={freeSetStateForSave.timeOutSetting}
              onChange={(event) => {
                if (event.currentTarget.value) {
                  setFreeSetStateForSave((pre) => {
                    return {
                      ...pre,
                      timeOutSetting: parseInt(event.currentTarget.value)
                    };
                  });
                }
              }}
            />
          ) : (
            <SSpan>{freeSetStateForSave.timeOutSetting}</SSpan>
          )}
          <SSpan>{`초`}</SSpan>
        </Editor>
        <Editor filedName="타임아웃 버퍼">
          <SSpan>{`± 최대`}</SSpan>
          {isActive ? (
            <Input
              type="number"
              min={0}
              step={1}
              value={freeSetStateForSave.timeOutBuffer}
              onChange={(event) => {
                if (event.currentTarget.value) {
                  setFreeSetStateForSave((pre) => {
                    return {
                      ...pre,
                      timeOutBuffer: parseInt(event.currentTarget.value)
                    };
                  });
                }
              }}
            />
          ) : (
            <SSpan>{freeSetStateForSave.timeOutBuffer}</SSpan>
          )}
          <SSpan>{`초`}</SSpan>
        </Editor>
      </EditorContainer>
      <Subject subject="열량계 시뮬레이터 설정" />
      <EditorContainer>
        <Editor filedName="데이터 생성방식" gridNumber={1}>
          {isActive ? (
            <RadioBox>
              <Input
                type="radio"
                id="isAutoCreativeInCalorimeter"
                name="dataGeneratorMethodInCalorimeter"
                value={freeSetStateForSave.dataGeneratorMethodInCalorimeter}
                checked={
                  freeSetStateForSave.dataGeneratorMethodInCalorimeter ===
                  DATA_GENERATOR_METHOD.AUTO_GENERATION
                }
                onChange={() =>
                  setFreeSetStateForSave((pre) => ({
                    ...pre,
                    dataGeneratorMethodInCalorimeter:
                      DATA_GENERATOR_METHOD.AUTO_GENERATION
                  }))
                }
              />
              <label htmlFor="isAutoCreativeInCalorimeter">{`자동생성`}</label>
              <Input
                type="radio"
                id="isSetTesterInCalorimeter"
                name="dataGeneratorMethodInCalorimeter"
                value={freeSetStateForSave.dataGeneratorMethodInCalorimeter}
                checked={
                  freeSetStateForSave.dataGeneratorMethodInCalorimeter ===
                  DATA_GENERATOR_METHOD.INSPECTOR_ASSIGNMENT
                }
                onChange={() =>
                  setFreeSetStateForSave((pre) => ({
                    ...pre,
                    dataGeneratorMethodInCalorimeter:
                      DATA_GENERATOR_METHOD.INSPECTOR_ASSIGNMENT
                  }))
                }
              />
              <label htmlFor="isSetTesterInCalorimeter">{`검사자 지정`}</label>
            </RadioBox>
          ) : (
            <SSpan>{dataGeneratorMethodInCalorimeter}</SSpan>
          )}
        </Editor>
        <Editor filedName="누적열량">
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.cumulativeHeat}
              step="any"
              min="0"
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  cumulativeHeat: parseFloat(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.cumulativeHeat}</SSpan>
          )}
          <SSpan $minWidth="40px">{`Gcal`}</SSpan>
        </Editor>
        <Editor filedName="순간열량">
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.instantaneousHeat}
              step="any"
              min="0"
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  instantaneousHeat: parseFloat(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.instantaneousHeat}</SSpan>
          )}
          <SSpan $minWidth="40px">{`Gcal/h`}</SSpan>
        </Editor>
        <Editor filedName="누적유량">
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.cumulativeFlowRate}
              step="any"
              min="0"
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  cumulativeFlowRate: parseFloat(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.cumulativeFlowRate}</SSpan>
          )}
          <SSpan $minWidth="40px">{`m³`}</SSpan>
        </Editor>
        <Editor filedName="순간유량">
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.instantaneousFlowRate}
              step="any"
              min="0"
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  instantaneousFlowRate: parseFloat(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.instantaneousFlowRate}</SSpan>
          )}
          <SSpan $minWidth="40px">{`m³/h`}</SSpan>
        </Editor>
        <Editor filedName="송류온도">
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.outletTemperature}
              step="any"
              min="0"
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  outletTemperature: parseFloat(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.outletTemperature}</SSpan>
          )}
          <SSpan $minWidth="40px">{`℃`}</SSpan>
        </Editor>
        <Editor filedName="환류온도">
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.returnTemperature}
              step="any"
              min="0"
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  returnTemperature: parseFloat(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.returnTemperature}</SSpan>
          )}
          <SSpan $minWidth="40px">{`℃`}</SSpan>
        </Editor>
        <Editor filedName="송류압력">
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.outletPressure}
              step="any"
              min="0"
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  outletPressure: parseFloat(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.outletPressure}</SSpan>
          )}
          <SSpan $minWidth="40px">{`bar`}</SSpan>
        </Editor>
        <Editor filedName="환류압력">
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.returnPressure}
              step="any"
              min="0"
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  returnPressure: parseFloat(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.returnPressure}</SSpan>
          )}
          <SSpan $minWidth="40px">{`bar`}</SSpan>
        </Editor>
      </EditorContainer>
      <Subject subject="센서 시뮬레이터 설정" />
      <EditorContainer>
        <Editor filedName="데이터 생성방식">
          {isActive ? (
            <RadioBox>
              <Input
                type="radio"
                id="isAutoCreative"
                name="dataGeneratorMethodInSensorSimulationSetting"
                value={DATA_GENERATOR_METHOD.AUTO_GENERATION}
                checked={
                  freeSetStateForSave.dataGeneratorMethodInSensorSimulation ===
                  DATA_GENERATOR_METHOD.AUTO_GENERATION
                }
                onChange={() =>
                  setFreeSetStateForSave((pre) => ({
                    ...pre,
                    dataGeneratorMethodInSensorSimulation:
                      DATA_GENERATOR_METHOD.AUTO_GENERATION
                  }))
                }
              />
              <label htmlFor="isAutoCreative">{`자동생성`}</label>
              <Input
                type="radio"
                id="isSetTester"
                name="dataGeneratorMethodInSensorSimulationSetting"
                value={DATA_GENERATOR_METHOD.INSPECTOR_ASSIGNMENT}
                checked={
                  freeSetStateForSave.dataGeneratorMethodInSensorSimulation ===
                  DATA_GENERATOR_METHOD.INSPECTOR_ASSIGNMENT
                }
                onChange={() =>
                  setFreeSetStateForSave((pre) => ({
                    ...pre,
                    dataGeneratorMethodInSensorSimulation:
                      DATA_GENERATOR_METHOD.INSPECTOR_ASSIGNMENT
                  }))
                }
              />
              <label htmlFor="isSetTester">{`검사자 지정`}</label>
            </RadioBox>
          ) : (
            <SSpan>{dataGeneratorMethodInSensorSimulationSetting}</SSpan>
          )}
        </Editor>
        <Editor filedName="2차센서 수량">
          {isActive ? (
            <select
              name="secondarySensorCount"
              value={freeSetStateForSave.secondarySensorQuantity}
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  secondarySensorQuantity: parseInt(event.currentTarget.value)
                }))
              }
            >
              <option value="1">{`1`}</option>
              <option value="2">{`2`}</option>
              <option value="3">{`3`}</option>
              <option value="4">{`4`}</option>
              <option value="5">{`5`}</option>
            </select>
          ) : (
            <SSpan>{freeSetStateForSave.secondarySensorQuantity}</SSpan>
          )}
        </Editor>
        <Editor filedName="2차측 송류온도 #1">
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.secondaryOutletTemperature}
              step="any"
              min="0"
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  secondaryOutletTemperature: parseFloat(
                    event.currentTarget.value
                  )
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.secondaryOutletTemperature}</SSpan>
          )}
          <SSpan $minWidth="40px">{`℃`}</SSpan>
        </Editor>
        <Editor filedName="2차측 환류온도 #1">
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.secondaryReturnTemperature}
              step="any"
              min="0"
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  secondaryReturnTemperature: parseFloat(
                    event.currentTarget.value
                  )
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.secondaryReturnTemperature}</SSpan>
          )}
          <SSpan $minWidth="40px">{`℃`}</SSpan>
        </Editor>
        <Editor filedName="2차측 송류압력 #1">
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.secondaryOutletPressure}
              step="any"
              min="0"
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  secondaryOutletPressure: parseFloat(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.secondaryOutletPressure}</SSpan>
          )}
          <SSpan $minWidth="40px">{`bar`}</SSpan>
        </Editor>
        <Editor filedName="2차측 환류압력 #1">
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.secondaryReturnPressure}
              step="any"
              min="0"
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  secondaryReturnPressure: parseFloat(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.secondaryReturnPressure}</SSpan>
          )}
          <SSpan $minWidth="40px">{`bar`}</SSpan>
        </Editor>
        {dataMethod !== DATA_METHOD_TYPE.EXTERNAL_INPUT && (
          <>
            <Editor filedName="2차측 송류온도 #2">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 환류온도 #2">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류압력 #2">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #2">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
          </>
        )}
        {dataMethod === DATA_METHOD_TYPE.UN_USED && (
          <>
            <Editor filedName="2차측 송류온도 #3">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 환류온도 #3">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류압력 #3">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #3">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #4">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 환류온도 #4">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류압력 #4">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #4">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #5">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 환류온도 #5">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류압력 #5">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #5">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #6">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 환류온도 #6">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류압력 #6">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #6">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #7">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 환류온도 #7">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류압력 #7">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #7">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #8">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 환류온도 #8">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류압력 #8">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #8">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #9">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 환류온도 #9">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류압력 #9">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #9">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #10">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 환류온도 #10">
              <Input type="number" />
              <SSpan>{`℃`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류압력 #10">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
            <Editor filedName="2차측 송류온도 #10">
              <Input type="number" />
              <SSpan>{`bar`}</SSpan>
            </Editor>
          </>
        )}
        <Editor filedName="사용자시설 온도">
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.userFacilityTemperature}
              step="any"
              min="0"
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  userFacilityTemperature: parseFloat(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.userFacilityTemperature}</SSpan>
          )}
          <SSpan $minWidth="40px">{`℃`}</SSpan>
        </Editor>
        <Editor filedName="사용자시설 습도">
          {isActive ? (
            <Input
              type="number"
              value={freeSetStateForSave.userFacilityHumidity}
              step="any"
              min="0"
              onChange={(event) =>
                setFreeSetStateForSave((pre) => ({
                  ...pre,
                  userFacilityHumidity: parseFloat(event.currentTarget.value)
                }))
              }
            />
          ) : (
            <SSpan>{freeSetStateForSave.userFacilityHumidity}</SSpan>
          )}
          <SSpan $minWidth="40px">{`%`}</SSpan>
        </Editor>
      </EditorContainer>
    </Section>
  );
}

export default RtuRealTimeRead;
