import * as ReactTable from "react-table";
import { colors } from "../../styles";
import TableData from "./table-data";
import TableRow from "./table-row";

export interface IRow<P extends {}> extends ReactTable.Row<P> {
  isSelected?: boolean;
  toggleRowSelected?: (set: boolean) => void;
}

export interface IRenderRowProps<P extends {}> {
  prepareRow: (row: IRow<P>) => void;
  handleSelectRow?: (row?: IRow<P>) => void;
  handleContextMenu?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  selectedRow?: IRow<P>;
  flexIndex?: number;
  listOfFlexForHeader?: string[];
  isLastFlex?: boolean;
  visibleColumns?: ReactTable.ColumnInstance<P>[];
  isSameRowSelect?: boolean;
}
export interface IRenderRow<P extends {}> {
  data: IRow<P>[];
  index: number;
  style: object;
}

function RenderRow<P extends {}>({
  prepareRow,
  handleSelectRow,
  handleContextMenu,
  selectedRow,
  flexIndex,
  listOfFlexForHeader,
  isSameRowSelect,
  isLastFlex = true
}: IRenderRowProps<P>) {
  return ({ index, data, style }: IRenderRow<P>) => {
    const row: IRow<P> = data[index];
    prepareRow(row);
    const backgroundColor = row.isSelected ? colors.green : "none";
    const color = row.isSelected ? colors.white : colors.black;
    const initStyle = { ...style, backgroundColor, color };
    const multiSelect = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      const contextButton = 2;
      if (event.type === "contextmenu" || event.button === contextButton) {
        event.preventDefault();
      }
      if (row.toggleRowSelected) {
        if (
          row.isSelected &&
          (event.type !== "contextmenu" || event.button !== contextButton)
        ) {
          data.forEach((item) => {
            if (item.isSelected && item.toggleRowSelected) {
              if (!isSameRowSelect && item.id !== row.id) {
                item.toggleRowSelected(false);
              }
            }
          });
          if (!isSameRowSelect) {
            row.toggleRowSelected(false);
          }
        } else if (selectedRow && (event.metaKey || event.ctrlKey)) {
          row.toggleRowSelected(true);
          if (
            (event.type === "contextmenu" || event.button === contextButton) &&
            handleContextMenu
          ) {
            handleContextMenu(event);
          }
        } else if (selectedRow && event.shiftKey) {
          data.forEach((item) => {
            if (
              parseInt(selectedRow.id) < parseInt(item.id) &&
              parseInt(item.id) <= parseInt(row.id) &&
              item.toggleRowSelected
            ) {
              item.toggleRowSelected(true);
              if (
                (event.type === "contextmenu" ||
                  event.buttons === contextButton) &&
                handleContextMenu
              ) {
                handleContextMenu(event);
              } else if (handleSelectRow) {
                handleSelectRow(row);
              }
            }
            if (
              parseInt(item.id) < parseInt(selectedRow.id) &&
              parseInt(row.id) <= parseInt(item.id) &&
              item.toggleRowSelected
            ) {
              item.toggleRowSelected(true);
              if (
                (event.type === "contextmenu" ||
                  event.buttons === contextButton) &&
                handleContextMenu
              ) {
                handleContextMenu(event);
              }
            }
            if (
              (event.type !== "contextmenu" ||
                event.buttons !== contextButton) &&
              handleSelectRow
            ) {
              handleSelectRow(row);
            }
          });
        } else {
          if (selectedRow) {
            data.forEach((item) => {
              if (item.isSelected && item.toggleRowSelected) {
                item.toggleRowSelected(false);
              }
            });
          }
          if (
            (event.type !== "contextmenu" || event.buttons !== contextButton) &&
            handleSelectRow
          ) {
            row.toggleRowSelected(true);
            handleSelectRow(row);
          }
          if (
            (event.type === "contextmenu" || event.buttons === contextButton) &&
            handleContextMenu
          ) {
            handleContextMenu(event);
            row.toggleRowSelected(true);
          }
        }
      }
    };

    return (
      <TableRow {...row.getRowProps({ style: initStyle })}>
        {row.cells.map((cell, rowIndex) => {
          let isFlex = flexIndex === rowIndex;
          if (listOfFlexForHeader && cell.column.Header) {
            isFlex = listOfFlexForHeader.includes(cell.column.Header as string);
          }
          return (
            <TableData
              {...cell.getCellProps()}
              key={`${index} ${Math.random() * 10}`}
              id={`${data[index].id}-context-menu`}
              onContextMenu={multiSelect}
              onClick={multiSelect}
              isFlex={isFlex}
              isLastFlex={isLastFlex}
            >
              {cell.render("Cell")}
            </TableData>
          );
        })}
      </TableRow>
    );
  };
}

export default RenderRow;
