import moment from "moment";
import { useMemo } from "react";

function useTime() {
  const minutesList: string[] = useMemo(() => {
    const newMinutesList = [];
    for (let i = 0; i <= 59; i++) {
      newMinutesList.push(moment(i, "m").format("mm"));
    }
    return newMinutesList;
  }, []);

  const hourList: string[] = useMemo(() => {
    const newHours = [];
    for (let i = 0; i <= 23; i++) {
      const hour = moment(i, "H").format("H");
      newHours.push(hour.toString().padStart(2, "0"));
    }
    return newHours;
  }, []);

  const time24List = useMemo(() => {
    let times = [];
    for (let hour = 0; hour <= 23; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        let time =
          hour.toString().padStart(2, "0") +
          ":" +
          minute.toString().padStart(2, "0");
        times.push(time);
      }
    }
    return times;
  }, []);
  return { hourList, minutesList, time24List };
}

export default useTime;
