import styled from "styled-components";

const TableHead = styled.div`
  white-space: nowrap;
  font-weight: bold;
  background-color: ${(props) => props.theme.colors.lightGrey};
  border-top: 2px solid ${(props) => props.theme.colors.darkGrey};
  font-weight: bold;
  svg {
    color: ${(props) => props.theme.colors.green};
  }
  font-family: sans-serif;
`;

export default TableHead;
