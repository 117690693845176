import styled from "styled-components";
import TableHeader from "./table-header";

const TableData = styled(TableHeader)`
  display: flex !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export default TableData;
