import styled from "styled-components";
import { Icon } from "@iconify/react";
import ExcelIcon from "@iconify/icons-fa-solid/file-excel";

export interface IExcelProps {
  downloadExcel?: () => void;
  isDisable?: boolean;
}

const Container = styled.div<{ isDisable?: boolean }>`
  display: flex;
  border: 2px solid
    ${(props) => {
      if (props.isDisable) {
        return props.theme.colors.grey;
      }
      return props.theme.colors.green;
    }};
  border-radius: 4px;
  padding: 4px;
  cursor: ${(props) => (props.isDisable ? "not-allowed" : "pointer")};
  color: ${(props) => {
    if (props.isDisable) {
      return props.theme.colors.grey;
    }
    return props.theme.colors.green;
  }};
  align-items: center;
  :hover {
    border: 2px solid
      ${(props) => {
        if (props.isDisable) {
          return props.theme.colors.grey;
        }
        return props.theme.colors.lightGreen;
      }};
    color: ${(props) => {
      if (props.isDisable) {
        return props.theme.colors.grey;
      }
      return props.theme.colors.lightGreen;
    }};
  }
  :active {
    transform: ${(props) => {
      if (!props.isDisable) {
        return "scale(0.94)";
      }
    }};
    border: 2px solid
      ${(props) => {
        if (!props.isDisable) {
          return props.theme.colors.navy;
        }
      }};
    color: ${(props) => {
      if (!props.isDisable) {
        return props.theme.colors.navy;
      }
    }};
  }
`;

function Excel({ downloadExcel, isDisable }: IExcelProps) {
  return (
    <Container
      onClick={() => {
        if (downloadExcel && !isDisable) {
          downloadExcel();
        }
      }}
      isDisable={isDisable}
    >
      <Icon icon={ExcelIcon} />
    </Container>
  );
}

export default Excel;
