import Main from "../shared/main";
import Table from "../table/table";
import * as ReactTable from "react-table";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { IRow } from "../table/render-row";
import usePageControl from "../../hooks/use-page.control";
import { TColumn } from "../../hooks/use-hide-columns";
import SearchWithCategories from "../search-with-categories/search-with-categories";
import { useController, useForm } from "react-hook-form";
import useSearchInTable, { ISearchForm } from "../../hooks/use-search-in-table";
import useSortBy from "../../hooks/use-sort-by";
import ButtonContainer from "../shared/button-container";
import Button from "../shared/button";
import styled from "styled-components";
import { colors } from "../../styles";
import { Icon } from "@iconify/react";
import FileDownloadIcon from "@iconify/icons-mdi/file-download";
import Dialog from "../dialog/dialog";
import DeleteTestingResult from "./delete-testing-result/delete-testing-result";
import routes from "../../routes/routes";
import TableFooter from "../table/table_footer";
import TableTitleBar from "../table/table-title-bar";
import {
  Examine_Method,
  TestingResultEntity,
  TestingResultSearchType,
  useDeleteResultHistoryMutation,
  useGetTestingResultCountLazyQuery,
  useGetTestingResultLazyQuery
} from "../../__generated__/generated";
import SToastContainer from "../shared/s_toast_container";
import { toast } from "react-toastify";
import {
  OPEN_TESTING_HISTORY_RESULT_MESSAGE,
  TESTING_HISTORY_RESULT
} from "./detail_result/detail_result";
import { freeSetAtoms } from "../../recoil/free_set_atoms/free_set_atoms";
import { useRecoilValue } from "recoil";

interface TestingResultEntityType extends TestingResultEntity {
  result?: any;
  resultDoc?: any;
}

const IColumns = {
  AS_NO: "번호",
  EXAMINE_STARTED_DATE_TIME: "수행일자",
  EXAMINE_NAME: "시험 관리명",
  MNG_NUMBER: "제조사코드",
  MANUFACTURER_CODE_NAME: "관리번호",
  PROPERTY_NUMBER: "기물번호",
  PROPERTY_VERSION: "버전",
  MODEL_CODE: "구분코드",
  MODEL_CODE_NAME: "모델구분",
  EXAMINE_METHOD: "시험방식",
  EXAMINE_TEST_SESSION: "시험회차/조회기간",
  EXAMINE_STATUS: "상태"
} as const;

const TableContainer = styled.div`
  display: flex;
  flex: 25;
  flex-direction: column;
`;

const IconContainer = styled.div`
  svg {
    font-size: 20px;
    color: ${props => props.theme.colors.darkBlue};
    :hover {
      color: ${props => props.theme.colors.skyBlue};
    }
  }
`;

const ShowResult = styled.span`
  text-decoration: underline;
`;

function TestingResult() {
  const dialogEl = useRef<HTMLDialogElement | undefined>();
  const [isOpenTestingHistoryChannel, setIsOpenTestingHistoryChannel] =
    useState<boolean>(false);
  const {
    fieldSort,
    handleFieldSort,
    listOfColumnDisabled,
    handleListOfColumnDisabled
  } = useSortBy();
  const freeSetState = useRecoilValue(freeSetAtoms);

  const channel = useMemo(() => {
    return new BroadcastChannel(TESTING_HISTORY_RESULT);
  }, []);

  const [getTestingResult, { data }] = useGetTestingResultLazyQuery({
    fetchPolicy: "no-cache",
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      if (!data.getTestingResult.ok && data.getTestingResult.error) {
        console.log(data.getTestingResult.error);
      }
    }
  });

  const [getTestingResultCount, { data: count }] =
    useGetTestingResultCountLazyQuery();

  const [deleteResultHistory, { client }] = useDeleteResultHistoryMutation({
    onError(error) {
      toast("선택된 시험이력을 삭제할 수 없습니다.", {
        type: toast.TYPE.ERROR,
        theme: "colored"
      });
      console.log(error);
    },
    update(_, { data }) {
      if (data?.deleteResultHistory.ok) {
        toast("선택된 시험이력을 성공적으로 삭제하였습니다.", {
          type: toast.TYPE.SUCCESS,
          theme: "colored"
        });
        client.resetStore();
      } else if (data?.deleteResultHistory.error) {
        toast(data?.deleteResultHistory.error, {
          type: toast.TYPE.ERROR,
          theme: "colored"
        });
      }
    }
  });

  const total: number = useMemo(
    () => count?.getTestingResultCount.count ?? 0,
    [count?.getTestingResultCount.count]
  );

  const {
    searchTypeData,
    searchValueData,
    isSearch,
    handleSearchTypeData,
    handleSearchValueData,
    handleIsSearch
  } = useSearchInTable<TestingResultSearchType>({
    defaultSearchType: TestingResultSearchType.AsNo
  });

  const { control, getValues } = useForm<ISearchForm<TestingResultSearchType>>({
    defaultValues: {
      searchType: TestingResultSearchType.AsNo,
      searchValue: ""
    }
  });

  const { field: searchType } = useController({
    name: "searchType",
    control
  });

  const { field: searchValue } = useController({
    name: "searchValue",
    control
  });

  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();
  const columns: ReactTable.Column<TestingResultEntityType>[] = useMemo(() => {
    const smallWidth = 60;
    const width = 120;
    return [
      { Header: "번호", accessor: "asno", width: smallWidth },
      { Header: "수행 일자", accessor: "examineStartDateTime" },
      { Header: "시험 관리명", accessor: "examineName" },
      { Header: "관리번호", accessor: "mngNumber", width },
      { Header: "제조사코드", accessor: "manufacturerCodeName" },
      { Header: "기물번호", accessor: "propertyNumber", width },
      { Header: "버전", accessor: "propertyVersion", width },
      { Header: "구분코드", accessor: "modelCode", width },
      { Header: "모델구분", accessor: "modelCodeName", width },
      {
        Header: "시험방식",
        accessor: "examineMethod",
        Cell(cell) {
          let method = "환경테스트";
          switch (cell.value) {
            case Examine_Method.RealTimeInspection:
              method = "실시간검침";
              break;
            case Examine_Method.DurabilityTest:
              method = "내구성 시험";
              break;
            case Examine_Method.StorageInspection:
              method = "저장검침";
              break;
          }
          return <span>{method}</span>;
        }
      },
      { Header: "시험회차/조회기간", accessor: "examineTestSession" },
      { Header: "상태", accessor: "examineStatus" },
      {
        Header: "결과",
        accessor: "result",
        width: smallWidth,
        Cell(cell) {
          const id = cell.row.id;
          switch (cell.row.original.examineStatus) {
            case "검사중":
              return (
                <ShowResult
                  onClick={event => {
                    window.open(
                      `${routes.performTesting}/${routes.detailResult}/${id}`,
                      "",
                      "width=1200, height=820, scrollbars=yes"
                    );
                  }}
                >
                  {`상태보기`}
                </ShowResult>
              );
            case "시험완료":
              return (
                <ShowResult
                  onClick={event => {
                    window.open(
                      `${routes.performTesting}/${routes.detailResult}/${id}`,
                      "",
                      "width=1200, height=820, scrollbars=yes"
                    );
                  }}
                >
                  {`결과보기`}
                </ShowResult>
              );
            case "검사완료":
              return (
                <ShowResult
                  onClick={event => {
                    window.open(
                      `${routes.performTesting}/${routes.detailResult}/${id}`,
                      "",
                      "width=1200, height=820, scrollbars=yes"
                    );
                  }}
                >
                  결과보기
                </ShowResult>
              );
            case "오류중단":
              return (
                <ShowResult
                  onClick={event => {
                    window.open(
                      `${routes.performTesting}/${routes.detailResult}/${id}`,
                      "",
                      "width=1200, height=820, scrollbars=yes"
                    );
                  }}
                >
                  {`이력보기`}
                </ShowResult>
              );
            default:
              return <div>{cell.row.original.examineStatus}</div>;
          }
        }
      }
      //   {
      //     Header: "결과지",
      //     accessor: "resultDoc",
      //     width,
      //     Cell(cell) {
      //       return (
      //         <IconContainer>
      //           <Icon icon={FileDownloadIcon} />
      //         </IconContainer>
      //       );
      //     }
      //   }
    ];
  }, []);

  const list: TestingResultEntityType[] = useMemo(() => {
    return data?.getTestingResult.list ?? [];
  }, [data?.getTestingResult.list]);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    columns: columnsOfTestingResult,
    toggleHideColumn,
    selectedFlatRows
  } = ReactTable.useTable<TestingResultEntityType>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  const handleSelectRow = useCallback(
    (row?: IRow<TestingResultEntityType>) => {},
    []
  );

  const selectedRow: ReactTable.Row<TestingResultEntityType> | undefined =
    useMemo(() => {
      return selectedFlatRows.at(-1);
    }, [selectedFlatRows]);

  useEffect(() => {
    handleListOfColumnDisabled([
      "examineTestSession",
      "examineMethod",
      "result",
      "resultDoc"
    ]);
  }, [handleListOfColumnDisabled]);

  useEffect(() => {
    if (isSearch) {
      const { searchType, searchValue } = getValues();
      handleSearchTypeData(searchType);
      handleSearchValueData(searchValue);
      handleCurrentPage(1);
      handleIsSearch(false);
    }
  }, [
    isSearch,
    getValues,
    handleCurrentPage,
    handleSearchTypeData,
    handleSearchValueData,
    handleIsSearch
  ]);

  useEffect(() => {
    getTestingResultCount({
      variables: {
        searchType: searchTypeData,
        searchValue: searchValueData
      }
    });
  }, [getTestingResultCount, searchTypeData, searchValueData]);

  useEffect(() => {
    getTestingResult({
      variables: {
        searchType: searchTypeData,
        page: currentPage,
        take,
        fieldSort,
        searchValue: searchValueData
      }
    });
  }, [
    getTestingResult,
    currentPage,
    take,
    fieldSort,
    searchTypeData,
    searchValueData
  ]);

  useLayoutEffect(() => {
    if (channel) {
      channel.onmessage = event => {
        if (event.data === OPEN_TESTING_HISTORY_RESULT_MESSAGE) {
          setIsOpenTestingHistoryChannel(true);
        }
      };
    }
  }, [channel]);

  useEffect(() => {
    if (isOpenTestingHistoryChannel && channel && selectedRow) {
      let method = "환경테스트";
      switch (selectedRow.original.examineMethod) {
        case Examine_Method.RealTimeInspection:
          method = "실시간검침";
          break;
        case Examine_Method.DurabilityTest:
          method = "내구성 시험";
          break;
        case Examine_Method.StorageInspection:
          method = "저장검침";
          break;
      }
      const payload = {
        examineStartDateTime: selectedRow?.original.examineStartDateTime,
        testingTargetType: selectedRow?.original.modelCodeName,
        freeSetItemName: freeSetState?.freeSetItemName ?? "",
        testingType: method,
        examineName: selectedRow?.original.examineName ?? ""
      };
      channel.postMessage(payload);
      setIsOpenTestingHistoryChannel(false);
    }
  }, [isOpenTestingHistoryChannel, channel, selectedRow, freeSetState]);

  return (
    <Main>
      <TableContainer>
        <TableTitleBar
          title="시험이력"
          total={total}
          columns={columnsOfTestingResult as TColumn<TestingResultEntityType>[]}
          toggleHideColumn={toggleHideColumn}
          take={take}
          handleTake={handleTake}
        >
          <SearchWithCategories
            list={Object.keys(IColumns).map(item => ({
              name: IColumns[item as keyof typeof IColumns],
              value: item
            }))}
            searchType={searchType}
            searchValue={searchValue}
            handleSearch={handleIsSearch}
          />
        </TableTitleBar>
        <Table<TestingResultEntityType>
          title="시험이력"
          prepareRow={prepareRow}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          getTableBodyProps={getTableBodyProps}
          selectedRow={selectedRow}
          handleSelectRow={handleSelectRow}
          rows={rows}
          listOfFlexForHeader={["수행 일자", "시험관리명", "시험회차/조회기간"]}
          isLastFlex={false}
          fieldSort={fieldSort}
          handleFieldSort={handleFieldSort}
          listOfColumnDisabled={listOfColumnDisabled}
          isSameRowSelect
        />
        <TableFooter
          totalPage={Math.ceil(total / take)}
          currentPage={currentPage}
          handleCurrentPage={handleCurrentPage}
          handleTake={handleTake}
        />
      </TableContainer>
      <ButtonContainer>
        <Button
          maxHeight="25px"
          maxWidth="110px"
          backgroundColor={colors.darkBlue}
          hoverBackgroundColor={colors.skyBlue}
          disabled={!selectedRow}
          onClick={() => {
            dialogEl?.current?.showModal();
          }}
        >{`선택 삭제`}</Button>
      </ButtonContainer>
      <Dialog ref={dialogEl} title="삭제" isRed>
        <DeleteTestingResult />
        <ButtonContainer>
          <Button
            value="confirm"
            maxWidth="50px"
            backgroundColor={colors.errorRed}
            hoverBackgroundColor={colors.lightRed}
            onClick={() => {
              dialogEl.current?.close();
              const list = selectedFlatRows.map(
                item => item.original.examineStartDateTime
              );
              deleteResultHistory({
                variables: {
                  examineStartDateTimeList: list
                }
              });
            }}
          >{`예`}</Button>
          <Button
            value="close"
            maxWidth="50px"
            backgroundColor={colors.black}
            hoverBackgroundColor={colors.darkGrey}
            onClick={() => {
              dialogEl.current?.close();
            }}
          >{`아니오`}</Button>
        </ButtonContainer>
      </Dialog>
      <SToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Main>
  );
}

export default TestingResult;
