import { createContext } from "react";
import { MessageTypes } from "./toast-message";

const ToastMessageContext = createContext<{
  isOpen: boolean;
  handleIsOpen: (value: boolean) => void;
  message: string;
  handleMessage: (value: string) => void;
  toastMessageType: MessageTypes;
  handleToastMessageType: (value: MessageTypes) => void;
  handleToast: (message: string, type: MessageTypes) => void;
} | null>(null);

export default ToastMessageContext;
