import * as jwt from "jsonwebtoken";

export const Role = {
  ADMIN: 1,
  USER: 2
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Role = (typeof Role)[keyof typeof Role];

export interface IUserInfo {
  readonly id: string;
  readonly permissionFlag: Role;
  readonly personName: string;
  readonly personDepartment?: string;
  readonly personTel?: string;
  readonly personPhone?: string;
  readonly personEmail?: string;
}

export const makeToken = (payload: object): string => {
  const token = jwt.sign(
    payload,
    process.env.REACT_APP_USER_SECRET_KEY as string
  );
  return token;
};

export const verifyToken = (token: string): string => {
  const verifiedToken = jwt.verify(
    token,
    process.env.REACT_APP_USER_SECRET_KEY as string
  );
  return verifiedToken as string;
};
