import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import DndContainer from "./components/shared/dnd-container";
import helmetContext from "./helmet/helmet.context";
import reportWebVitals from "./reportWebVitals";
import Router from "./router";
import { GlobalStyles, lightTheme } from "./styles";
import { ApolloProvider } from "@apollo/client";
import { client } from "./apollo/apollo";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <HelmetProvider context={helmetContext}>
        <ThemeProvider theme={lightTheme}>
          <DndContainer>
            <RouterProvider router={Router} />
          </DndContainer>
          <GlobalStyles />
        </ThemeProvider>
      </HelmetProvider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
