import { useCallback, useState } from "react";

function useTabs(initTab: string) {
  const [selectedTab, setSelectedTab] = useState(initTab);

  const handleTabSelected = useCallback((tab: string) => {
    setSelectedTab(tab);
  }, []);
  return { selectedTab, handleTabSelected };
}

export default useTabs;
