import styled from "styled-components";

const Main = styled.main`
  display: flex;
  flex-basis: 0;
  flex-grow: 16;
  border-top: 1px solid ${(props) => props.theme.colors.frameGrey};
  flex-direction: column;
  padding: 10px;
  gap: 10px;
`;

export default Main;
