/* eslint-disable @typescript-eslint/no-redeclare */
import moment from "moment";
import { atom } from "recoil";
import { Examine_Method, Examine_Target } from "../../__generated__/generated";
import {
  ACTION_ON_ERROR_DETECTION_TYPE,
  DATA_GENERATOR_METHOD,
  DATA_METHOD_TYPE,
  FREE_SET_MESSAGE_DATA
} from "../free_set_atoms/free_set_atoms";

export const freeSetForSaveAtoms = atom<FREE_SET_MESSAGE_DATA>({
  key: "freeSetStateForSave", // unique ID (with respect to other atoms/selectors)
  default: {
    testingTargetType: Examine_Target.Calorimeter,
    testingType: Examine_Method.EnvironmentTest,
    timeOutSetting: 0,
    timeOutBuffer: 0,
    startDateOfInquiry: moment().format("YYYY-MM-DD"),
    startHourOfInquiry: "00",
    startMinOfInquiry: "00",
    calorieValueGenerationMethod: DATA_METHOD_TYPE.EXTERNAL_INPUT,
    temperatureValueGenerationMethod: DATA_METHOD_TYPE.EXTERNAL_INPUT,
    calorificValueSetting: "",
    calorificValueIncrement: "",
    temperatureSetting: "",
    temperatureIncrement: "",
    examineCount: 1,
    actionOnErrorDetection: ACTION_ON_ERROR_DETECTION_TYPE.STOP,
    dataGeneratorMethodInCalorimeter: DATA_GENERATOR_METHOD.AUTO_GENERATION,
    cumulativeHeat: 0,
    cumulativeFlowRate: 0,
    instantaneousHeat: 0,
    instantaneousFlowRate: 0,
    outletTemperature: 0,
    returnTemperature: 0,
    outletPressure: 0,
    returnPressure: 0,
    dataGeneratorMethodInSensorSimulation:
      DATA_GENERATOR_METHOD.AUTO_GENERATION,
    secondarySensorQuantity: 1,
    secondaryOutletTemperature: 0,
    minSecondaryOutletTemperature: 0,
    maxSecondaryOutletTemperature: 0,
    secondaryReturnTemperature: 0,
    minSecondaryReturnTemperature: 0,
    maxSecondaryReturnTemperature: 0,
    secondaryOutletPressure: 0,
    minSecondaryOutletPressure: 0,
    maxSecondaryOutletPressure: 0,
    secondaryReturnPressure: 0,
    minSecondaryReturnPressure: 0,
    maxSecondaryReturnPressure: 0,
    userFacilityTemperature: 0,
    minUserFacilityTemperature: 0,
    maxUserFacilityTemperature: 0,
    userFacilityHumidity: 0,
    minUserFacilityHumidity: 0,
    maxUserFacilityHumidity: 0,
    minCumulativeHeat: 0,
    maxCumulativeHeat: 0,
    minInstantaneousHeat: 0,
    maxInstantaneousHeat: 0,
    minCumulativeFlowRate: 0,
    maxCumulativeFlowRate: 0,
    minInstantaneousFlowRate: 0,
    maxInstantaneousFlowRate: 0,
    minOutletTemperature: 0,
    maxOutletTemperature: 0,
    minReturnTemperature: 0,
    maxReturnTemperature: 0,
    minOutletPressure: 0,
    maxOutletPressure: 0,
    minReturnPressure: 0,
    maxReturnPressure: 0
  } // default value (aka initial value)
});
